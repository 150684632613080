import React, { useState, useEffect } from 'react';
// import { useTranslation } from 'react-i18next';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { isAuthenticated } from '../hooks/AppContext';

import MainHeader from '../components/dashboard/MainHeader';

import { useLocation, Link } from 'react-router-dom';

import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    '.line-break': {
      'white-space': 'pre-wrap'
    }
  },
  container: {
    marginTop: '10em',
    top: '5em',
    width: '23vw',
    minWidth: '350px',
  },
  h1: {
    fontSize: '26px',
    letterSpacing: '1.56px',
  },
  headerBlue: {
    opacity: 1,
  },
  link: {
    color: theme.palette.primary.light,
    fontSize: '0.8em',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  selected: {
    fontWeight: 600,
  }
}));

export default function NotFoundPage() {
  // const { t } = useTranslation('userlist');

  const classes = useStyles();

  const [needsReload] = useState(false);
  // const [item, setItem] = useState();
  const [loggedIn, setLoggedIn] = useState();

  const location = useLocation();

  // const { setLoading, loading, setError, error } = useComponentState();

  const fetch = () => {
    const auth = isAuthenticated();
    setLoggedIn(auth);
  };

  useEffect(fetch, [needsReload]);
  return (
    <>
      {
        loggedIn ? (
          <>
            <MainHeader/>
            <Container classes={{ root: classes.container }}>
              <Grid container spacing={6} justify="center">
                <div style={{ display:'flex', flexFlow: 'column', alignItems: 'center' }}>
                  <p>Ups.. Something went wrong!</p>
                  <img src={'/images/robotBroken.svg'} width="300px" alt="broken app"/>
                  <Link
                    to={'products'}
                    className={clsx(
                      classes.link,
                      location.pathname === 'products' ? classes.selected : {},
                    )}>
                    <br></br>
                    {'Back to application'}
                  </Link>
                </div>
              </Grid>
            </Container>
            
          </>
        ):(
          <Container classes={{ root: classes.container }}>
            <Grid container spacing={6} justify="center">
              <div style={{ display:'flex', flexFlow: 'column', alignItems: 'center' }}>
                <p>Ups.. Something went wrong!</p>
                <img src={'/images/robotBroken.svg'} width="300px" alt="broken app"/>
                <Link
                  to={'login'}
                  className={clsx(
                    classes.link,
                    location.pathname === 'login' ? classes.selected : {},
                  )}>
                  <br></br>
                  {'Go back to application'}
                </Link>
              </div>
            </Grid>
          </Container>
        )
      }
    </>
  );
}
