import React from 'react';
import Login from '../components/login/Login';
import { useHistory } from 'react-router-dom';
import { useRoutes } from '../hooks/useRoutes';

export default function _login() {
  const { PATH } = useRoutes();
  const history = useHistory();
  // var userInfo = { ...props };

  const onLoginSuccess = (payload) => {
    // console.log('onLoginSuccess', payload);
    // save token, save user object and redirect to root
    const token = payload.token;
    const user = {
      name: payload.name,
      id: payload.id,
      email: payload.email,
      company: payload.company,
      role: payload.role,
    };
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    history.push(PATH.root);
  };
  return (
    <>
      <Login onLoginSuccess={onLoginSuccess} />
    </>
  );
}
