import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/fi';
import options from './options.json';
moment.locale('fi');

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: false,
    resources: {
      en: {
        common: {
          sel_list: 'Select from list',
          back: 'Back',
          delete: 'Delete',
          cancel: 'Cancel',
          close: 'Close',
          number: 'Number',
          ok: 'OK',
          yes: 'Yes',
          no: 'No',
          dont_know: "Don't know",
          create_new: 'Create new',
          items: 'Items',
          products: 'Products',
          assemblies: 'Assemblies',
          materials: 'Materials',
          components: 'Components',
          companies: 'Companies',
          gdpr: 'Gdpr',
          manual: 'Manual',
          fibers: 'Fibers',
          step: 'Step',
          users: 'Users',
          text: 'Text',
          search: 'Search',
          one_item: 'Item',
          save: 'Save',
          save_changes: 'Save Changes',
          no_item: 'No items',
          required: 'Required',
          image_not_found: 'Image not found',
          select_from_list: 'Select from list',
          save_success: 'Save success!',
          save_error: 'Error in saving changes',
          validation_error: 'Validation error',
        },
        validators: {
          required: 'Required field',
          minimum_value_zero: 'Value must be greater than 0',
        },
        assembly: {
          name: 'Name',
          lcimId: 'LCIM ID',
          step: 'Step',
          company: 'Company Name',
          function: 'Open',
          assembly: 'Assembly',
          new_assembly: 'New Assembly',
          edit_assembly: 'Edit Assembly',
          create_assembly: 'Create a new Assembly',
          assemblies: 'Assemblies',
        },
        select_season: {
          summer: 'Summer/Spring',
          autumn: 'Autumn/Winter',
          spring: 'Pre-Fall',
          winter: 'Resort',
        },
        company: {
          company_list: 'Company list',
          main_company: 'Main Company',
          img: '',
          name: 'Company Name',
          location_name: 'Location Name',
          locations: 'Locations',
          add_location: 'Add new location',
          id: 'Id',
          street: 'Street',
          new_company: 'New Company',
          create_company: 'Create a new Company',
          edit_company: 'Edit Company',
          city: 'City',
          function: 'Open',
          country: 'Country',
          business_id: 'Business Id',
          invites: 'Invites',
          invite: 'Invite',
          invite_sent: 'Invite Sent',
          invite_failed: 'Invite Failed',
          user_exists: 'User exists',
          invite_exists: 'Invitation exists',
          invalid_data: 'Invalid data',
          www: 'www',
        },
        step: {
          type: 'Step type',
          company: 'Company',
          location: 'Location',
          date: 'Date',
          meansOfProducedEnergy: 'Means of produced energy',
          energyConsumption: 'Energy consumption',
          waterConsumption: 'Water consumption',
          wasteWaterTreatment: 'Water waste treament',
          co2Emissions: 'CO2 emissions',
          date_placeholder: 'DD.MM.YYYY',
          co2Unit: 't/CO2 eq.',
          waterUnit: 'l',
          energyUnit: 'kW/h',
          ttipPwrConsumption: 'Average power consumption of the process',
          ttipWaterConsumption: 'Average water consumption of the process'
        },
        userlist: {
          user_list: 'User list',
          users: 'Users',
          add_user: 'Add User',
          edit_user: 'Edit User',
          create_user: 'Invite user',
          img: '',
          email: 'E-Mail',
          name: 'Name',
          user_name: 'Username',
          company: 'Organization',
          _company: 'Company',
          function: 'Edit User',
          role: 'Role',
          err_email_req: 'User email address is required',
          err_email_invalid: 'Email is not in correct format',
          err_name_req: 'User name is required',
          err_name_invalid: 'User name invalid',
          err_role_req: 'Role is required',
          remove_user: 'Remove user',
          invites: 'Invites',
          invite: 'Invite',
          invite_sent: 'Invite Sent',
          invite_failed: 'Invite Failed',
          user_exists: 'User exists',
          invite_exists: 'Invitation exists',
          invalid_data: 'Invalid data',
        },
        login: {
          login: 'Login now',
          signin: 'Signin',
          email: 'E-mail address',
          pwd: 'Password',
          pwd_repeat: 'Password again',
          remember_me: 'Remember me',
          no_account: "Don't have an account?",
          sign_up: 'Sign up',
          register: 'Register',
          reset_password: 'Reset password',
          forgot_password: 'Forgot password?',
          err_email_req: 'User email address is required',
          err_email_invalid: 'Email is not in correct format',
          err_pwd_invalid: 'Email or password is not valid',
          err_pwd_min_length: 'Password must have at least 8 characters',
          err_pwd_repeat_invalid: 'The passwords do not match',
          register_success: 'User register success',
          required: 'Required',
          invalid_credentials: 'Email or password is not valid',
          see: 'See',
          gdpr: 'GDPR',
          no_invitation: 'Invalid invitation',
          user_no_role: 'Failed to set user role',
          failed_creating_user: 'Failed to create such user',
          failed_rm_cr: 'Invitation was not removed'
        },
        resetPassword: {
          reset_password: 'Reset password',
          pwd: 'Password',
          pwd_repeat: 'Password again',
          err_pwd_invalid: 'Email or password is not valid',
          err_pwd_min_length: 'Password must have at least 8 characters',
          err_pwd_repeat_invalid: 'The passwords do not match',
          required: 'Required'
        },
        forgotPassword: {
          forgot_password: 'Forgot password',
          send: 'Send',
          email: 'Email'
        },
        component: {
          component: 'Component',
          edit_component: 'Edit Component',
          new_component: 'New Component',
          create_component: 'Create a new Component',
          components: 'Components',
          name: 'Name',
          lcimId: 'LCIM ID',
          img: 'Image',
          text: 'Text',
          type: 'Type',
          fiber: 'Fiber',
          company: 'Company',
          location: 'Location',
          function: 'Open',
          percentage: 'Percentage',
          isRecycled: 'Is recycled',
          recycled: 'Recycled',
          sourceOfRecycledInput: 'Source of recycled input',
          sourceOfFiber: 'Source of fiber',
          required: 'Required',
          certification: 'Certification',
          step: 'Step',
        },
        fiber: {
          fiber: 'Fiber',
          fibers: 'Fibers',
          edit_fiber: 'Edit Fiber',
          create_fiber: 'Create a new Fiber',
          new_fiber: 'New Fiber',
        },
        product: {
          products: 'Products',
          product: 'Product',
          edit_assembly: 'Edit Assembly',
          edit_product: 'Edit Product',
          new_product: 'New Product',
          name: 'Name',
          lcimId: 'LCIM ID',
          img: '',
          function: 'Open',
          text: 'Text',
          token: 'Token',
          required: 'Required',
          sel_list: 'Select from list',
          company: 'Company',
          season: 'Season',
          size: 'Size',
          brand: 'Brand',
          sku: 'SKU',
          sgtin: 'SGTIN',
          productCategory: 'Product category',
          productType: 'Product type',
          productWeight: 'Product weight',
          finishing: 'Finishing',
          recyclable: 'Recyclable',
          assemblies: 'Assemblies',
          gtin: 'GTIN',
          call_to_action: 'Call to action',
          create_product: 'Create a new Product',
          product_list: 'Product list',
        },
        material: {
          materials: 'Materials',
          material: 'Material',
          certification: 'Certification',
          tradename: 'Trade name',
          create_material: 'Create a new Material',
          new_material: 'New Material',
          edit_material: 'Edit Material',
          description: 'Description',
          color: 'Colour',
          colorCode: 'Colour code',
          pattern: 'Pattern',
          name: 'Name',
          lcimId: 'LCIM ID',
          img: '',
          text: 'Text',
          weight: 'Weight',
          weight_unit: 'g/m²',
          token: 'Token',
          required: 'Required',
          sel_list: 'Select from list',
          company: 'Company',
          season: 'Season',
          size: 'Size',
          brand: 'Brand',
          sku: 'SKU',
          sgtin: 'SGTIN',
          mat_cat: 'Material category',
          mat_type: 'Material type',
          mat_wgh: 'Material weight',
          finish: 'Finishing',
          recyclable: 'Recyclable',
          assemblies: 'Assemblies',
          gtin: 'GTIN',
          call_to_action: 'Call to action',
          create_product: 'Create a new product',
          material_list: 'Material list',
          construction: 'Structure',
          dyed: 'Dyed',
          dyeingMethod: 'Dyeing method',
          dyestuff: 'Dye stuff',
          printed: 'Printed',
          function: 'Open',
          printingMethod: 'Printing method',
          printDyestuff: 'Printing dyestuff',
          finishing: 'Finishing',
        },
        options: options,
        manuals: {
          update_success: 'Updated successfully',
          update_failed: 'Update failed'
        },
        create_manual: {
          create_manual: 'Create Manual',
          save_failed: 'Saving failed',
          save_success: 'Successfully saved'
        },
        view_manual: {
          view_manual: 'View manual'
        },
        gdprs: {
          update_success: 'Updated successfully',
          update_failed: 'Update failed'
        },
        create_gdpr: {
          create_gdpr: 'Create GDPR',
          save_failed: 'Saving failed',
          save_success: 'Successfully saved'
        },
        view_gdpr: {
          view_gdpr: 'View gdpr'
        },
        lcimview: {
          id: "ID",
          name: "Name",
          lcimId: "LCIM ID",
          companyId: "Company ID",
          published: "Published",
          ownerCompany: "Owner company",
          subcontractorCompany: "Subcontractor company",
          locations: "Locations",
          
          location: "Location",
          finishing: "Finishing",
          season: "Season",
          instructions: "Instructions",
          steps: "Steps",
          createdAt: "Created",
          updatedAt: "Updated",

          company: "Company",
          
          // product view
          productId: "Product ID",
          sku: "SKU",
          sgtin: "SGTIN",
          gtin: "GTIN",
          brand: "Brand",
          productCategory: "Product category",
          productType: "Product type",
          productWeight: "Product weight",
          productsAssemblies: "Assembly Links",
          productsAssemblie: "Assembly",
          assemblies: "Assemblies",

          // assembly view
          assemblyId: "Assembly ID",
          assemblyMaterials: "Material Links",
          assemblyMaterial: "Material",
          materials: "Materials",

          // material view
          materialId: "Material ID",
          dyed: "Dyed",
          dyeingMethod: "Dyeing method",
          dyestuff: "Dye stuff",
          color: "Color",
          printed: "Printed",
          printingMethod: "Printing method",
          printDyestuff: "Printing dyestuff",
          weight: "Weight",
          description: "Description",
          colorCode: "Color code",
          pattern: "Pattern",
          construction: "Construction",
          tradename: "Tradename",
          materialComponents: "Component Links",
          materialComponent: "Component",
          components: "Components",

          // component view
          componentId: "Component ID",
          fiber: "Fiber",
          isRecycled: "Recycling Methods",
          sourceOfFiber: "Source of fiber",
          certification: "Certification",
          sourceOfRecycledInput: "Source of recycled input",
          componentFibers: "Fiber links",
          componentFiber: "Fiber",
          fiberId: "Fiber ID",
          fibers: "Fibers",
          locationId: "Location ID",
          step: "Step",

          // step view
          stepId: "Step ID",
          type: "Type",
          date: "Date",
          meansOfProducedEnergy: "Means of produced energy",
          energyConsumption: "Energy consumption",
          waterConsumption: "Water consumption",
          co2Emissions: "CO2 Emissions",
          wasteWaterTreatment: "Waste water treatment",
          
          percentage: "Percentage",
          recyclable: "Recyclable"
        }
      },
    },
    lng: 'en',
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
    },
  });

export default () => {
  return false;
};
