import { createMuiTheme } from '@material-ui/core/styles';
import 'fontsource-open-sans/400.css';
import 'fontsource-open-sans/600.css';
import 'fontsource-dosis/400.css';

const COLOR = {
  PRIMARY: '#032b66',
};

const FONT = {
  Trebuchet: '"Trebuchet MS", Helvetica, sans-serif',
  OpenSans: '"Open Sans", Arial, Helvetica, sans-serif',
  OpenSansBold: '"Open Sans", "Arial", Helvetica, sans-serif',
  Dosis: 'Dosis, "Trebuchet MS", Helvetica, sans-serif',
};

const theme = createMuiTheme({
  typography: {
    fontFamily: FONT.OpenSans,
    h1: {
      fontSize: 19,
      fontWeight: 70,
      color: '#032b66',
      transform: 'uppercase',
      fontFamily: FONT.Dosis,
      letterSpacing: 0.6,
    },
    h2: {
      color: '#1e2d44',
      fontSize: 40,
      fontWeight: 100,
      letterSpacing: '-0.8px',
    },
    h4: {
      color: '#1e2d44',
      fontSize: 12,
      fontWeight: 100,
      textAlign: 'center',
    },
    subtitle1: {
      fontWeight: 110,
      color: '#1e2d44',
      letterSpacing: '-0.24px',
      fontSize: 12,
      opacity: 1.0,
    },
    subtitle2: {
      fontWeight: 110,
      color: '#1e2d44',
      letterSpacing: '-0.24px',
      fontSize: 12,
      opacity: 0.25,
    },
    body1: {
      fontWeight: 300,
      color: '#1E2D44',
      letterSpacing: '-0.24px',
      fontSize: 12,
    },
    body2: {
      fontWeight: 400,
      fontSize: 16,
      color: '#1E2D44',
      letterSpacing: '-0.32px',
    },
    button: {
      textTransform: 'inherit',
    },
  },
  palette: {
    primary: {
      light: '#042e6e',
      main: '#032b66',
      dark: '#0000EE',
      contrastText: '#fff',
    },
    secondary: {
      light: '#032b66',
      main: '#fff',
      dark: '#032b66',
      contrastText: '#1e2d44',
    },
    tonalOffset: 0.2,
    contrastThreshold: 3,
  },
  overrides: {
    body: {
      backgroundColor: '#f8f9fb',
    },
    MuiCssBaseline: {
      '@global': {
        html: {
          WebkitFontSmoothing: 'auto',
          '@global': {
            '@font-face': FONT.OpenSans,
          },
        },
        // root: {
        //   margin: theme.spacing(1),
        // },
      },
    },
    MuiAppBar: {
      root: {
        // paddingLeft: '5em',
        paddingTop: '0.4em',
        paddingBottom: '0.4em',
        // width: '100%',
        boxShadow: 'none',
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: '#1e2d44',
          opacity: 1.0,
        },
        opacity: 0.25,
        lineHeight: 0,
      },
    },
    // MuiTable: {
    //   root: {
    //     borderSpacing: '0em 16px',
    //     borderCollapse: 'separate',
    //     paddingRight: '8px',
    //     paddingLeft: '24px',
    //   },
    // },
    MuiTableCell: {
      root: {
        borderTop: '2px solid #F8F9FB',
        borderBottom: 'none',
        // padding: '6x 16px 6px 16px',
        // '&:first-child': {
        //   borderTop: 'none',
        // },
      },
    },
    // MuiIconButton: {
    //   root: {
    //     padding: '0px',
    //   },
    // },
    MuiButton: {
      root: {
        height: '3.4em',
        borderRadius: '2px',
      },
      label: {
        fontSize: '12px',
        letterSpacing: '-0.24px',
      },
      contained: {
        boxShadow: 'none',
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: 'rgba(12,44,98,0.9)',
        },
        boxShadow: 'none',
      },
      containedSizeLarge: {
        minWidth: '80px',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '2px',
        backgroundColor: '#f4f5f6',
        '&:hover': {
          border: '0px solid #1e2d44 !important',
        },
      },
    },
    MuiLink: {
      root: {
        userSelect: 'none !important',
      },
    },
    MuiSelect: {
      icon: {
        position: 'absolute',
      },
    },
    MuiAccordion: {
      root: {
        '&:before': {
          backgroundColor: 'unset',
          display: 'inline',
          color: 'black',
          content: 'asdasd',
        },
      },
    },
    MuiBreadcrumbs: {
      root: {
        // paddingLeft: '5em',
        color: '#1e2d44',
        fontSize: 10,
        marginTop: '0.5em',
      },
    },
  },
  props: {
    MuiTypography: {
      variantMapping: {
        subtitle1: 'span',
        subtitle2: 'span',
      },
    },
  },
});

const useAppTheme = () => {
  return {
    theme,
    COLOR,
    FONT,
  };
};

export { theme, COLOR, FONT, useAppTheme };
