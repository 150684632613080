/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { Box, Typography, TextareaAutosize, Grid, Chip, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import MainHeader from '../../components/dashboard/MainHeader';
import useComponentState from '../../hooks/useComponentState';
import useMarkdown from '../../hooks/useMarkdown';
import { useHistory } from 'react-router-dom';
import { useRoutes } from '../../hooks/useRoutes';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    maxWidth: '90%',
    '.line-break': {
      'white-space': 'pre-wrap'
    }
  },
  container: {
    marginTop: '10em',
    top: '5em',
    width: '23vw',
    minWidth: '350px',
  },
  page: {
    width: '100%',
    marginTop: '2.5em',
  },
  cont: {
    paddingLeft: '4em',
    paddingTop: '0.55em',
    paddingBottom: '0.55em',
    marginRight: '4em',
  },
}));

const ViewGdprAdminPage = (props) => {
  const { getMarkdown, updateMarkdownAvailability } = useMarkdown();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('view_gdpr');
  const classes = useStyles();
  const [needsReload, setReload] = useState(false);
  const [item, setItem ] = useState();
  const [preview, setPreview] = useState();

  const { match } = props;
  const gdpr = props.location.state;
  const gdprId = match.params.gdprId;

  const { setLoading, loading, setError, error } = useComponentState();

  const setGdprState = (payload, availability) => {
    setLoading(true);
    updateMarkdownAvailability(payload.id, availability)
      .then(() => {
        enqueueSnackbar(t('update_success'), { variant: 'success' });
        if (availability === 'deleted') {
          setItem(item.filter((it) => it.id !== payload.id));
        } else {
          setItem(item.map((it) => it.id === payload.id ? { ...it, availability } : it));
        }
      }).catch(() => {
        enqueueSnackbar(t('update_failed'), { variant: 'error' });
      }).finally(() => setLoading(false))
  }

  const fetch = () => {
    setLoading(true);
    if (!gdpr) {
      setError(null);
      getMarkdown("gdpr")
      .then((res) => {
        console.log({res});
        setItem(res.find((one) => one.id === gdprId));
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
        setReload(false);
      });
    } else { // Updates to GUI.
      if (item) {
        setPreview(item.content);
      } else {
        setItem(gdpr);
        setPreview(gdpr.content);
      }
      setLoading(false);
      setReload(false);
    }
  };

  useEffect(fetch, [needsReload]);

  const currentAvailabilityLabel = (gdpr, chip) => {
    return gdpr.availability === chip ? 'primary' : 'default';
  }

  // const customRenderers = { // react-markdown accepts custom renderers
  //   image: ({ alt, src, title }) => (
  //     <img alt={alt} src={src} title={title} style={{ maxWidth: 475 }}  />
  //   )
  // };

  return (
    <>
      <MainHeader item={item} />
      <Box classes={{ root: classes.cont }}>
        <Box classes={{ root: classes.page }}>
          <Typography variant="h2">{t('view_gdpr')}</Typography>
          <br/>
          {item && (
            <Grid container spacing={6} justify="center">
              <Grid container item xs={12} justify="flex-start" alignItems="center" flexFlow="row">
                <div style={{width: '100%'}}>
                  <Typography variant="h1" component="h2">Category: {item.category}</Typography>
                  <Typography variant="h1" component="h2">ID: {item.id ? item.id : 'to be created'}</Typography>
                  <Typography variant="h1" component="h2">Author: {item.author}</Typography>
                  <Typography variant="h1" component="h2">Created: {'to be created'}</Typography>
                  <Typography variant="h1" component="h2">Updated: {'to be created'}</Typography>
                  <br/>
                  <div style={{display:'flex', flexFlow:'row'}}>
                    <div style={{minWidth: '40%', marginRight: '8px'}}>
                      <div style={{display: 'flex', flexFlow: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '8px 0'}}>
                        <Typography variant="h1" component="h2">Raw view: </Typography>
                        <Chip className={classes.chip} color={currentAvailabilityLabel(item, 'hidden')} label="hidden" onClick={() => setGdprState(item, 'hidden')}/>
                        <Chip className={classes.chip} color={currentAvailabilityLabel(item, 'public-internal')} label="public-internal" onClick={() => setGdprState(item, 'public-internal')}/>
                        <Chip className={classes.chip} color={currentAvailabilityLabel(item, 'public-external')} label="public-external" onClick={() => setGdprState(item, 'public-external')}/>
                      </div>
                      <TextareaAutosize
                        aria-label="maximum height"
                        defaultValue={item.content}
                        style={{minWidth: '100%', height: '700px'}}
                        readOnly={true}>
                      </TextareaAutosize>
                    </div>
                    <ReactMarkdown className="line-break" children={preview} style={{maxWidth: '60%', marginLeft: '8px'}}/>
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
          <Box m={24} />
        </Box>
      </Box>
    </>
  );
};

export default ViewGdprAdminPage;
