/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { ConnectForm } from './product/ConnectForm';
import { getFieldName } from '../hooks/utils';
import ComponentView from './ComponentView';
import Fiber from './Fiber';
import { useTranslation } from 'react-i18next';
import { useFieldArray } from 'react-hook-form';
import useProduct from '../hooks/useProduct';
import useObservableState from './useObservableState';
import { Observer, useLocalObservable } from 'mobx-react-lite';
import { constructEmptyFiber } from '../hooks/utils';
import clsx from 'clsx';
import {
  Grid,
  Typography,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Box,
} from '@material-ui/core';
import StepController from './StepController';
import ItemHeader from './ItemHeader';
import {
  useLineStyles,
  useAccordionStyles,
  useEditorRootStyles,
} from './editorUtils';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ModalDialog from './ModalDialog';
import useCompany from '../hooks/useCompany';
import ControllerHeader from './ControllerHeader';
import AssignToModalDialog from './AssignToModalDialog';

const FiberController = (props) => {
  const {
    control,
    prefix,
    companies,
    getCallbackObj,
    appendFibers,
    showHeader,
    watch,
  } = props;
  const ARRAY_KEY = 'fibers';
  const classes = useLineStyles();
  const { t } = useTranslation('fiber');
  const FIBER_KEY = getFieldName(prefix, ARRAY_KEY);
  const { fields, append, remove } = useFieldArray({
    control,
    name: FIBER_KEY,
  });

  const { getFibers, getCompleteFiber } = useProduct();
  const { state: fetchState } = useObservableState();

  const dialogState = useLocalObservable(() => ({
    open: false,
    setOpen(e) {
      this.open = e;
    },
    options: [],
    setOptions(e) {
      this.options = e;
    },
  }));

  const assignToDialogState = useLocalObservable(() => ({
    open: false,
    setOpen(e) {
      this.open = e;
    },
    dataObject: null,
    setDataObject(e) {
      this.dataObject = e;
    },
  }));

  const onDialogSubmit = async (payload) => {
    if (!payload) {
      createNew();
      dialogState.setOpen(false);
    } else {
      const id = payload && payload.options ? payload.options : null;
      fetchState.setLoading(true);
      fetchState.setError(null);
      try {
        const obj = await getCompleteFiber(id);
        append(obj);
        dialogState.setOpen(false);
      } catch (err) {
        fetchState.setError(err);
      } finally {
        fetchState.setLoading(false);
      }
    }
  };

  const onOpenDialog = () => {
    fetchState.setLoading(true);
    fetchState.setError(null);
    getFibers()
      .then((res) => {
        fetchState.setResults(res);
        const arrayValue = watch(FIBER_KEY) || [];
        const options = res.map((e) => {
          return {
            value: e.id,
            label: e.name,
            disabled: arrayValue.find((f) => f.fiberId === e.id) || false,
          };
        });
        dialogState.setOptions(options);
        dialogState.setOpen(true);
      })
      .catch((err) => {
        fetchState.setError(err);
      })
      .finally(() => {
        fetchState.setLoading(false);
      });
  };

  const createNew = () => {
    const obj = constructEmptyFiber();
    append(obj);
  };

  const onDelete = (payload, index) => {
    remove(index);
  };

  const init = () => {
    if (getCallbackObj) {
      getCallbackObj({
        onCreate: () => onOpenDialog(),
      });
    }
    if (appendFibers) {
      // try-catching because calling append() throws sometimes error even if it is working
      try {
        for (const obj of appendFibers) {
          append(obj);
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }
  };

  useEffect(init, []);

  const onAssignTo = (payload) => {
    assignToDialogState.setDataObject(payload);
    assignToDialogState.setOpen(true);
  };

  return (
    <>
      {showHeader && (
        <Box mb={2}>
          <ControllerHeader
            state={fetchState}
            onAddButtonClick={() => onOpenDialog()}
            title={t('fibers') + ' (' + fields.length + ')'}
          />
        </Box>
      )}
      {fields.map((e, index) => {
        const style = clsx({
          [classes.root]: true,
          [classes.lastItem]: index === fields.length - 1,
        });
        return (
          <>
            {showHeader ? (
              <Fiber
                key={e.id}
                {...e}
                index={index}
                onDelete={(e) => onDelete(e, index)}
                onAssignTo={(payload) => onAssignTo(payload)}
                prefix={`${FIBER_KEY}[${index}]`}
                companies={companies}
              />
            ) : (
              <Grid container key={e.id}>
                <Grid item classes={{ root: style }}></Grid>
                <Grid item xs={true}>
                  <Fiber
                    {...e}
                    index={index}
                    onDelete={(e) => onDelete(e, index)}
                    onAssignTo={(payload) => onAssignTo(payload)}
                    prefix={`${FIBER_KEY}[${index}]`}
                    companies={companies}
                  />
                </Grid>
              </Grid>
            )}
          </>
        );
      })}

      <Observer>
        {() => (
          <>
            {dialogState.open && (
              <ModalDialog
                options={dialogState.options}
                onClose={() => dialogState.setOpen(false)}
                onSubmit={onDialogSubmit}
                fetchState={fetchState}
                title="Add new fiber"
                addButtonLabel="Add fiber from list"
                createButtonLabel="Create a new fiber"
              />
            )}
          </>
        )}
      </Observer>
      <Observer>
        {() => (
          <>
            {assignToDialogState.open && (
              <AssignToModalDialog
                dataObject={assignToDialogState.dataObject}
                onClose={() => assignToDialogState.setOpen(false)}
                title="Assign material"
              />
            )}
          </>
        )}
      </Observer>
    </>
  );

}


const Component = (props) => {
  const {
    prefix,
    onDelete,
    onAssignTo,
    companies,
    accordionProps,
    appendFibers,
    fibers,
    disablePercentage,
    disableAccordion,
  } = props;
  
  const classes = useAccordionStyles();
  const rootClasses = useEditorRootStyles();
  const [locations, setLocations] = useState();
  const [callbackObj, setCallbackObj] = useState(); // WARNING: removing unused callbackObj breaks the software
  const { getCompanyById } = useCompany();

  const onCompanyIdChange = (payload) => {
    getCompanyById(payload).then((res) => {
      const { locations } = res;
      setLocations(locations);
    });
  };

  return (
    <>
      <ConnectForm>
        {(formProps) => {
          const { watch } = formProps;
          const componentName = watch(getFieldName(prefix, 'name'));
          return (
            <>
              {!disableAccordion ? (
                <>
                  <Accordion
                    {...accordionProps}
                    square
                    elevation={0}
                    classes={{ root: classes.root }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <ItemHeader
                        title={
                          componentName || (
                            <Typography variant="subtitle2">
                              <em>Unnamed component</em>
                            </Typography>
                          )
                        }
                        onDelete={onDelete ? () => onDelete(props) : null}
                        onAssignTo={onAssignTo ? () => onAssignTo(props) : null}
                      />
                    </AccordionSummary>
                    <AccordionDetails classes={{ root: classes.details }}>
                      <Grid container>
                        <Grid item xs={true}>
                          <ComponentView
                            {...formProps}
                            prefix={prefix}
                            valueProps={props}
                            disablePercentage={disablePercentage}
                            onCompanyIdChange={onCompanyIdChange}
                            companies={companies}
                            locations={locations}
                          />
                          <StepController
                            {...formProps}
                            prefix={prefix}
                            companies={companies}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <FiberController
                    key="fiber-controller-a"
                    {...formProps}
                    prefix={prefix}
                    companies={companies}
                    appendFibers={appendFibers ? fibers : null}
                    getCallbackObj={(payload) => setCallbackObj(payload)}
                  />
                </>
              ) : (
                <>
                  <Box px={2} mb={8} classes={rootClasses}>
                    <ComponentView
                      {...formProps}
                      prefix={prefix}
                      valueProps={props}
                      disablePercentage={disablePercentage}
                      companies={companies}
                      onCompanyIdChange={onCompanyIdChange}
                      locations={locations}
                    />
                    <StepController
                      {...formProps}
                      prefix={prefix}
                      companies={companies}
                    />
                  </Box>
                  <FiberController
                    key="fiber-controller-b"
                    {...formProps}
                    prefix={prefix}
                    companies={companies}
                    appendFibers={appendFibers ? fibers : null}
                    getCallbackObj={(payload) => setCallbackObj(payload)}
                    showHeader={true}
                  />
                </>
              )}
            </>
          );
        }}
      </ConnectForm>
    </>
  );
};

export default Component;
