import React from 'react';
import Dashboard from '../components/dashboard/Dashboard';
import { useHistory } from 'react-router-dom';
import { useRoutes } from '../hooks/useRoutes';

export default function (props) {
  const { PATH } = useRoutes();
  const history = useHistory();

  const onLogoutClick = (payload) => {
    history.push(PATH.logout);
  };
  return (
    <>
      <Dashboard onLogoutClick={onLogoutClick} />
    </>
  );
}
