/* eslint-disable react/display-name */
import React from 'react';
import { isAuthenticated, logout } from './AppContext';
import { Redirect } from 'react-router-dom';

import Login from '../pages/Login';
import Register from '../pages/Register';
import RegisterCompany from '../pages/RegisterCompany';
import ResetPassword from '../pages/ResetPassword';
import ForgotPassword from '../pages/ForgotPassword';
import Gdpr from '../pages/Gdpr';
import Dashboard from '../pages/Dashboard';
import UserListPage from '../pages/Users';

// products
import ProductList from '../pages/Products';
import CreateProductPage from '../pages/CreateProduct';
import EditProductPage from '../pages/EditProduct';
import PublicProductPage from '../pages/PublicProduct';

// assemblies
import AssembliesList from '../pages/Assemblies';
import CreateAssembly from '../pages/CreateAssembly';
import EditAssemblyPage from '../pages/EditAssembly';
import PublicAssemblyPage from '../pages/PublicAssembly';

// materials
import Materials from '../pages/Materials';
import CreateMaterialPage from '../pages/CreateMaterial';
import EditMaterialPage from '../pages/EditMaterial';
import PublicMaterialPage from '../pages/PublicMaterial';

// components
import Components from '../pages/Components';
import CreateComponentPage from '../pages/CreateComponent';
import EditComponentPage from '../pages/EditComponent';
import PublicComponentPage from '../pages/PublicComponent';

// fibers
import Fibers from '../pages/Fibers';
import CreateFiberPage from '../pages/CreateFiber';
import EditFiberPage from '../pages/EditFiber';
import PublicFiberPage from '../pages/PublicFiber';

// materials
import Companies from '../pages/Companies';
// import CreateMaterialPage from '../pages/CreateMaterial';
// import EditMaterialPage from '../pages/EditMaterial';

// admin
import MainAdminPage from '../pages/admin/Main';

// not-found
import NotFoundPage from '../pages/NotFound';
import ManualPage from '../pages/Manual';
import ManualsAdminPage from '../pages/admin/Manuals';
import CreateManualAdminPage from '../pages/admin/CreateManual';
import ViewManualAdminPage from '../pages/admin/ViewManual';
import CreateGdprAdminPage from '../pages/admin/CreateGdpr';
import GdprsAdminPage from '../pages/admin/Gdprs';
import ViewGdprAdminPage from '../pages/admin/ViewGdpr';


export const PATH = {
  root: '/',
  dashboard: '/dashboard',
  login: '/login',
  register: '/register',
  registerCompany: '/register-company',
  resetPassword: '/reset-password/:rpt',
  forgotPassword: '/forgot-password',
  gdpr: '/gdpr',
  logout: '/logout',
  admin: '/admin',
  userslist: '/userslist',
  products: '/products',
  publicAssembly: '/public/assembly/:lcimId',
  publicFiber: '/public/fiber/:lcimId',
  publicComponent: '/public/component/:lcimId',
  publicMaterial: '/public/material/:lcimId',
  publicProduct: '/public/product/:lcimId',
  productPublic: '/public/product/:lcimId',
  createProduct: '/create-product/',
  editProduct: '/edit-product/:productId',
  assemblies: '/assemblies',
  createAssembly: '/create-assembly/',
  editAssembly: '/edit-assembly/:assemblyId',
  materials: '/materials',
  createMaterial: '/create-material/',
  editMaterial: '/edit-material/:materialId',
  components: '/components',
  createComponent: '/create-component/',
  editComponent: '/edit-component/:componentId',
  fibers: '/fibers',
  createFiber: '/create-fiber/',
  editFiber: '/edit-fiber/:fiberId',
  companies: '/companies',
  manual: '/manual',

  // admin paths
  manuals: '/manuals', // admin path
  createManual: '/create-manual/',
  viewManual: '/view-manual/:viewId',
  gdprs: '/gdprs', // admin path
  createGdpr: '/create-gdpr/',
  viewGdpr: '/view-gdpr/:viewId'


  // this will be set when no other is found, don't uncomment
  // notFound: '/not-found' 
};

export const routes = [
  {
    path: PATH.root,
    exact: true,
    render: (props) => 
      isAuthenticated() ? (
        <Redirect to={PATH.products} />
      ) : (
        <>
          <Login {...props} />
        </>
      )
  },
  {
    path: PATH.login,
    render: (props) =>
      isAuthenticated() ? (
        <Redirect to={PATH.root} />
      ) : (
        <>
          <Login {...props} />
        </>
      ),
  },
  {
    path: PATH.registerCompany,
    render: (props) => {
      logout();
      return <RegisterCompany {...props} />;
    },
  },
  {
    path: PATH.register,
    render: (props) => {
      logout();
      return <Register {...props} />;
    },
  },
  {
    path: PATH.resetPassword,
    render: (props) => {
      logout();
      return <ResetPassword {...props} />;
    },
  },
  {
    path: PATH.forgotPassword,
    render: (props) => {
      logout();
      return <ForgotPassword {...props} />;
    },
  },
  {
    path: PATH.gdpr,
    render: (props) => {
      return <Gdpr {...props} />;
    },
  },
  {
    path: PATH.logout,
    render: () => {
      logout();
      return <Redirect to={PATH.root} />;
    },
  },
  // PUBLIC LCIM
  {
    path: PATH.publicAssembly,
    exact: true,
    render: (props) => <PublicAssemblyPage {...props} />
  },
  {
    path: PATH.publicFiber,
    exact: true,
    render: (props) => <PublicFiberPage {...props} />
  },
  {
    path: PATH.publicComponent,
    exact: true,
    render: (props) => <PublicComponentPage {...props} />
  },
  {
    path: PATH.publicMaterial,
    exact: true,
    render: (props) => <PublicMaterialPage {...props} />
  },
  {
    path: PATH.publicProduct,
    exact: true,
    render: (props) => <PublicProductPage {...props} />
  },
  {
    path: PATH.userslist,
    private: true,
    render: (props) => <UserListPage {...props} />
  },
  {
    path: PATH.dashboard,
    private: true,
    render: (props) => <Dashboard {...props} />
  },
  // PRODUCTS
  {
    path: PATH.products,
    private: true,
    exact: true,
    render: (props) => <ProductList {...props} />
  },
  {
    path: PATH.createProduct,
    private: true,
    render: (props) => <CreateProductPage {...props} />
  },
  {
    path: PATH.editProduct,
    private: true,
    render: (props) => <EditProductPage {...props} />
  },
  // ASSEMBLIES

  {
    path: PATH.assemblies,
    private: true,
    render: (props) => <AssembliesList {...props} />
  },
  {
    path: PATH.createAssembly,
    private: true,
    render: (props) => <CreateAssembly {...props} />
  },
  {
    path: PATH.editAssembly,
    private: true,
    render: (props) => <EditAssemblyPage {...props} />
  },

  // MATERIALS
  {
    path: PATH.materials,
    private: true,
    render: (props) => <Materials {...props} />
  },
  {
    path: PATH.createMaterial,
    private: true,
    render: (props) => <CreateMaterialPage {...props} />
  },
  {
    path: PATH.editMaterial,
    private: true,
    render: (props) => <EditMaterialPage {...props} />
  },

  // COMPONENTS
  {
    path: PATH.components,
    private: true,
    render: (props) => <Components {...props} />
  },
  {
    path: PATH.createComponent,
    private: true,
    render: (props) => <CreateComponentPage {...props} />
  },
  {
    path: PATH.editComponent,
    private: true,
    render: (props) => <EditComponentPage {...props} />
  },
  // FIBERS
  {
    path: PATH.fibers,
    private: true,
    render: (props) => <Fibers {...props} />
  },
  {
    path: PATH.createFiber,
    private: true,
    render: (props) => <CreateFiberPage {...props} />
  },
  {
    path: PATH.editFiber,
    private: true,
    render: (props) => <EditFiberPage {...props} />
  },

  

  // COMPANIES
  {
    path: PATH.companies,
    private: true,
    render: (props) => <Companies {...props} />
  },
  // MANUAL
  {
    path: PATH.manual,
    private: false,
    render: (props) => <ManualPage {...props} />
  },
  // ADMIN do not set below manual, breaks for no reason
  {
    path: PATH.admin,
    private: true,
    role: 'SuperAdmin',
    render: (props) => <MainAdminPage {...props} />
  },
  {
    path: PATH.manuals,
    private: true,
    role: 'SuperAdmin',
    render: (props) => <ManualsAdminPage {...props} />
  },
  {
    path: PATH.createManual,
    private: true,
    role: 'SuperAdmin',
    render: (props) => <CreateManualAdminPage {...props} />
  },
  {
    path: PATH.viewManual,
    private: true,
    role: 'SuperAdmin',
    render: (props) => <ViewManualAdminPage {...props} />
  },
  {
    path: PATH.gdprs,
    private: true,
    role: 'SuperAdmin',
    render: (props) => <GdprsAdminPage {...props} />
  },
  {
    path: PATH.createGdpr,
    private: true,
    role: 'SuperAdmin',
    render: (props) => <CreateGdprAdminPage {...props} />
  },
  {
    path: PATH.viewGdpr,
    private: true,
    role: 'SuperAdmin',
    render: (props) => <ViewGdprAdminPage {...props} />
  },
  // NOT-FOUND (last of index to work)
  {
    path: PATH.notFound,
    private: false,
    render: (props) => <NotFoundPage {...props} />
  }

  // {
  //   path: PATH.createComponent,
  //   private: true,
  //   render: (props) => <CreateComponentPage {...props} />,
  // },
  // {
  //   path: PATH.editComponent,
  //   private: true,
  //   render: (props) => <EditComponentPage {...props} />,
  // },
];

const useRoutes = () => ({
  PATH,
  routes,
});

export { useRoutes };
