/* eslint-disable react/prop-types */
import React from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  makeStyles,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    padding: 0,
  },
}));
const SelectMultipleComponent = (props) => {
  const { name, options, control, defaultValue, rules, isError } = props;
  const { t } = useTranslation('common');
  // const { t: optionT } = useTranslation('select_' + name);
  const classes = useStyles();

  const constructOptions = (optionArray) => {
    let arr = [];
    // arr.push({ value: null, label: null });
    arr = [
      ...arr,
      ...optionArray.sort((f, s) => f.label.localeCompare(s.label)),
    ];

    return arr;
  };

  const labelId = 'select_' + name;

  return (
    <Controller
      as={({ value, onChange }) => {
        const handleChange = (event) => {
          const newValue = event.target.value;
          // stringify array
          onChange(JSON.stringify(newValue));
        };

        // getValue must return an array
        const getValue = () => {
          if (value != null) {
            let parsedValue = null;
            try {
              // parse stringifyed array
              parsedValue = JSON.parse(value);
            } catch (err) {
              // value is string or number
              parsedValue = value;
            }
            if (Array.isArray(parsedValue)) {
              return parsedValue;
            } else {
              return [parsedValue];
            }
          } else {
            return [];
          }
        };
        return (
          <FormControl variant="outlined" fullWidth error={isError}>
            <InputLabel id={labelId}>{t('select_from_list')}</InputLabel>
            <Select
              value={getValue()}
              fullWidth
              multiple
              id={labelId}
              onChange={handleChange}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => {
                    if (value) {
                      const obj = options.find((e) => e.value === value);
                      const label = obj ? obj.label : value;
                      return (
                        <Chip
                          key={value}
                          label={label}
                          className={classes.chip}
                        />
                      );
                    } else return false;
                  })}
                </div>
              )}
            >
              {options &&
                constructOptions(options).map((e) => {
                  const key = 'key_' + JSON.stringify(e.value);
                  return (
                    <MenuItem key={key} value={e.value}>
                      {e.label != null ? (
                        e.label
                      ) : (
                        <em>{t('select_from_list')}</em>
                      )}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        );
      }}
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
    />
  );
};

export default SelectMultipleComponent;
