import React, { useEffect, useState } from 'react';
import LcimView from '../components/product/LcimView';
import useProduct from '../hooks/useProduct';
import useComponentState from '../hooks/useComponentState';
import { matchPath } from 'react-router';
import PropTypes from 'prop-types';

const PublicMaterialPage = (props) => {
  const { history } = props;

  const match = matchPath(history.location.pathname, {
    path: '/public/material/:lcimId',
    exact: true,
    strict: false
  });

  // console.log({history});
  const lcimId = match.params.lcimId;
  // console.log({lcimId, match});
  const { getItemByLcimId } = useProduct();
  const {
    // loading,
    setLoading,
    // error,
    setError
  } = useComponentState();
  const [materialObject, setMaterialObject] = useState(null);

  const fetch = () => {
    setLoading(true);
    setMaterialObject(null);
    setError(null);
    getItemByLcimId('material', lcimId)
      .then((res) => {
        console.log({ res });
        setMaterialObject(res);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(fetch, [lcimId]);

  return <>{materialObject && <LcimView content={materialObject} />}</>;
};
PublicMaterialPage.propTypes = {
  history: {
    location: {
      pathname: PropTypes.string
    }
  }
};

export default PublicMaterialPage;
