/* eslint-disable react/prop-types */
import { Box, CircularProgress, Grid, Fab } from '@material-ui/core';
import { Observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { Prompt } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

const Spinner = () => (
  <Grid container justify="center">
    <CircularProgress />
  </Grid>
);

const Error = (props) => {
  const getMessage = () => {
    if (props && props.error && props.error.message) {
      return props.error.message;
    } else if (props && props.error) {
      return JSON.stringify(props.error);
    } else if (props) {
      return JSON.stringify(props);
    } else {
      return 'Unknown error';
    }
  };

  return <Box color="error.main">{getMessage()}</Box>;
};

const Form = (props) => {
  const {
    dataObject,
    onFormInitialized,
    onSubmit,
    onError,
    children,
    debug,
  } = props;

  const methods = useForm({
    defaultValues: dataObject,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const [formId] = useState(uuid());
  const { t } = useTranslation('common');
  const { isDirty, isSubmitting } = methods.formState;
  const watchAllFields = debug ? methods.watch() : {};

  const onSaveButtonPress = () => {
    document
      .getElementById(formId)
      .dispatchEvent(new Event('submit', { cancelable: true }));
  };

  useEffect(() => {
    onFormInitialized({ formId });
  }, [formId, onFormInitialized]);

  const isBlocking = methods.formState && methods.formState.isDirty;

  return (
    <>
      <FormProvider {...methods}>
        <form id={formId} onSubmit={methods.handleSubmit(onSubmit, onError)}>
          {children}
        </form>
      </FormProvider>

      <Fab
        color="primary"
        variant="extended"
        size="large"
        style={{ position: 'fixed', right: '4em', bottom: '2em' }}
        disabled={!isDirty || isSubmitting}
        onClick={onSaveButtonPress}
      >
        {t('save')}
      </Fab>

      {debug && <pre>values = {JSON.stringify(watchAllFields, 0, 2)}</pre>}
      {debug && <pre>dataObject = {JSON.stringify(dataObject, 0, 2)}</pre>}
      {debug && (
        <pre>formState = {JSON.stringify(methods.formState, 0, 2)}</pre>
      )}
      <Prompt
        when={isBlocking}
        message={() =>
          `There are unsaved changes. Are you sure you want to leave from this page?`
        }
      />
    </>
  );
};

const Editor = (props) => {
  const {
    dataObject: dataObjectOriginal,
    onSubmit,
    onFormInitialized = () => {},
    state,
    debug,
    children,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('common');

  // make a copy of dataobject instead of using original
  const dataObject = dataObjectOriginal
    ? JSON.parse(JSON.stringify(dataObjectOriginal))
    : null;

  const onError = (payload) => {
    console.error('validation error', payload);
    enqueueSnackbar(t('validation_error'), { variant: 'error' });
  };

  return (
    <>
      <Observer>{() => <>{state.loading && <Spinner />}</>}</Observer>
      <Observer>
        {() => <>{state.error && <Error error={state.error} />}</>}
      </Observer>
      {dataObject && (
        <Form
          dataObject={dataObject}
          onSubmit={onSubmit}
          onError={onError}
          onFormInitialized={onFormInitialized}
          debug={debug}
        >
          {children}
        </Form>
      )}
    </>
  );
};

export default Editor;
