/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import useProduct from '../hooks/useProduct';
import useCompany from '../hooks/useCompany';
import Editor from './Editor';
import { constructEmptyFiber } from '../hooks/utils';
// import { handleFiberPromises } from './editorUtils';
import useObservableState from './useObservableState';
import Fiber from './Fiber';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const FiberEditor = (props) => {
  const { fiberId: fetchId, debug, onFormInitialized } = props;
  const {
    createFiber,
    getCompleteFiber,
    updateFiber,
  } = useProduct();
  const { getCompanies } = useCompany();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('common');

  const { state } = useObservableState();
  const [dataObject, setDataObject] = useState(null);
  const [companies, setCompanies] = useState(null);

  const fetch = (id) => {
    state.setLoading(true);
    state.setError(null);
    setDataObject(null);
    getCompleteFiber(id)
      .then((res) => {
        setDataObject(res);
      })
      .then(() => getCompanies())
      .then((res) => {
        setCompanies(res);
      })
      .catch((err) => {
        state.setError(err);
      })
      .finally(() => {
        state.setLoading(false);
      });
  };

  const initialFetch = () => {
    if (fetchId) {
      fetch(fetchId);
    } else {
      state.setLoading(true);
      state.setError(null);
      getCompanies()
        .then((res) => {
          setCompanies(res);
        })
        .catch((err) => {
          state.setError(err);
        })
        .finally(() => {
          setDataObject(constructEmptyFiber());
          state.setLoading(false);
        });
    }
  };

  useEffect(initialFetch, [fetchId]);

  const onSubmit = async (payload) => {
    console.log('onSubmit', payload);
    state.setLoading(true);
    try {
      let fiberId = payload.fiberId;
      if (!fiberId) {
        const res = await createFiber(payload);
        fiberId = res.id;
      }
      await updateFiber(fiberId, payload);
      fetch(fiberId);
      enqueueSnackbar(t('save_success'), { variant: 'success' });
    } catch (err) {
      state.setError(err);
      enqueueSnackbar(t('save_error'), { variant: 'error' });
    } finally {
      state.setLoading(false);
    }
  };

  return (
    <>
      <Editor
        dataObject={dataObject}
        onSubmit={onSubmit}
        onFormInitialized={onFormInitialized}
        debug={debug}
        state={state}
      >
        <Fiber
          companies={companies}
          accordionProps={{ defaultExpanded: true }}
          disablePercentage={true}
          disableAccordion={true}
        />
      </Editor>
    </>
  );
};

export default FiberEditor;
