import { useState } from 'react';

const useComponentState = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  return {
    error,
    setError,
    loading,
    setLoading,
  };
};

export default useComponentState;
