export const PRODUCT_SIZE = ['XS', 'S', 'M', 'L', 'XL', 'XXL'];
export const PRODUCT_SEASON = ['summer', 'autumn', 'winter', 'spring'];
export const PRODUCT_CATEGORY = [
  'clothing',
  'accessories',
  'footwear',
  'workwear',
];
export const PRODUCT_TYPE = [
  't-shirt',
  'shorts',
  'trousers',
  "men's shirt",
  "women's shirt",
  'dress',
  'shirt',
  'skirt',
  'socks',
  'underwear',
  'blouse',
  'hoodie',
  'jacket',
  'coat',
  'pullover',
  'women underwear',
  'men underwear',
  'children underwear',
  'men sports',
  'women sports',
];
export const PRODUCT_RECYCLING_TYPE = [
  'not known',
  'mechanical recycling',
  'chemical recycling',
  'thermal recycling',
  'pyrolysis',
  'biological',
];
export const PRODUCT_FINISHING = [
  'beading sequins',
  'prepared for dyeing',
  'ready to dye',
  'heared',
  'singeing',
  'washed',
  'calendered',
  'caseins',
  'coated',
  'desized',
  'embroidery',
  'fillers',
  'delustering agents',
  'mercerized',
  'natural',
  'oils',
  'raised',
  'roughened',
  'sanforized',
  'softening agents',
  'waxes',
  'flame-retardancy',
  'easy care',
  'anti wrinkle',
  'crosslinked',
  'water repellent',
  'soil repellent',
  'oil repellent',
  'antimicrobial',
  'UV protective',
  'insect repellent',
  'hand builder',
  'enzyme'
];

export const COMPANY_LIST = [{ name: 'Reason', id: 1 }];

/*
  name          text  yes    one
  brand          text  yes    one
  company        text  yes    one
  season        select    no    one
  product category  select    yes   one
  product type  select   yes    one
  product weightnumber  no    one
  assembly        ASSEMBLYyes    multiple
  step          STEP  yes    multiple
  finishing      select    listnomultiple
  instructions    text  no    one
  recyclable    select    listyesmultiple
  */

export const MATERIAL_CERTIFICATION = [
  'c2c bronce',
  'c2c silver',
  'c2c gold',
  'c2c platin',
  'gots',
  'eu ecolabel',
  'swan label (joutsenmerkki)',
  'bsci',
  'fairtrade',
  'reach',
  'sa8000',
  'grs global recycle standard',
  'gots global organic textile standard',
  'ilo',
  'global compact',
  'ecopelle',
  'bluesign',
  'icec',
  'oeko-tex step',
  'oeko-tex standard 100',
  'oeko-tex leather standard',
  'msds raw material origin',
  'msds spinning',
  'msds dyeing',
  'msds finishing',
  'unic',
  'naturleder',
  'ivn',
  'lwg gold',
  'lwg silver',
  'lwg bronze',
  'icec chemicals management system',
  'icec eco-leathers',
  'icec environmental management system',
  'icec leather traceability',
  'icec occupational health and safety management system',
  'icec product certification for finished leathers',
  'icec quality management system',
  'rcs',
  "higg's index",
  "no_certification"
];
 
 export const MATERIAL_CONSTRUCTION = [
  'basket weave',
  'beads',
  'biotextile',
  'birds eye',
  'bonded',
  'braid',
  'buckle',
  'buckram',
  'button',
  'cable',
  'cambric',
  'cardigan',
  'care label',
  'chiffon',
  'chintz',
  'collar stay',
  'corduroy',
  'crepe',
  'denim',
  'dobby',
  'drill',
  'elastic',
  'eyelet grommet',
  'flannel',
  'fleece',
  'french terry',
  'fusing material',
  'gabardine',
  'georgette',
  'hook and loop',
  'intarsia',
  'interfacing',
  'interlock',
  'jacquard jersey',
  'jacquard',
  'khadi',
  'lab grown',
  'label',
  'lace',
  'lacoste',
  'milano rib',
  'naturally occurring',
  'non woven',
  'non woven dry laid',
  'non woven meltblown',
  'non woven needle-punched',
  'non woven spunlaid',
  'non woven wet laid',
  'piping cord',
  'pique',
  'plain weave',
  'pointelle',
  'poplin',
  'purl knit',
  'rachel',
  'rib knit',
  'rivets',
  'sateen',
  'satin',
  'seam sealing tape',
  'sequins',
  'shoulder pad',
  'single jersey',
  'sliver knit',
  'string draw cord',
  'swivel hook',
  'taffeta',
  'tricot',
  'twill',
  'twill tape',
  'velcro tape',
  'velour',
  'zipper',
  'wadding'
];
export const MATERIAL_COLOR = [
  'beige',
  'black',
  'blue',
  'brown',
  'coral',
  'gold',
  'green',
  'grey',
  'khaki',
  'navy',
  'nude',
  'off-white',
  'orange',
  'pink',
  'purple',
  'red',
  'rose',
  'silver',
  'taupe',
  'teal',
  'turquoise',
  'untreated',
  'white',
  'yellow',
];
export const MATERIAL_PATTERN = [
  'abstract',
  'baby and kids',
  'black and white',
  'check',
  'checked',
  'chevron',
  'damask',
  'dotted',
  'floral',
  'geometric',
  'gingham',
  'heathered',
  'herringbone',
  'houndstooth',
  'marled',
  'melange',
  'mixed',
  'motifs for children',
  'ombre',
  'other pattern',
  'other',
  'paisley',
  'self striping',
  'stars',
  'striped',
  'stripes',
  'tartan',
  'unicoloured',
];
export const MATERIAL_DYEMETHOD = [
  'top dyed',
  'dyed through',
  'yarn dyed',
  'dope dyed',
  'aniline',
  'exhaust dyed',
  'fabric dyed',
  'pigmented',
  'top coat',
  'garment dyed',
];
export const MATERIAL_PRINTMETHOD = [
  'digital printed',
  'hand painted',
  'inkjet printed',
  'planographic printed',
  'roller printed',
  'screen printed',
];

export const COMPONENT_FIBER = [
  'abaca',
  'acetate',
  'acrylic',
  'alpaca',
  'aluminium',
  'bamboo',
  'bamboo rayon',
  'banana',
  'bee wax',
  'biodegradable polyester',
  'bio-PDO',
  'brass',
  'bronze',
  'casein',
  'cashmere',
  'cellulose acetate',
  'coconut',
  'copper',
  'cork',
  'corn',
  'corozo',
  'cotton',
  'cupro',
  'econyl',
  'elastane',
  'ecovero',
  'hemp',
  'jute',
  'kapok',
  'lyocell',
  'manila hemp',
  'merino wool',
  'modacrylic',
  'modal',
  'mohair',
  'mother of pearl',
  'natural latex',
  'natural rubber',
  'nettle',
  'nickel',
  'nylon',
  'orange fiber',
  'organic cotton',
  'organic hemp',
  'organic linen',
  'organic silk',
  'organic wool',
  'peace silk',
  'pineapple fiber',
  'PLA',
  'polyamide',
  'polyester',
  'polyethylene',
  'polylactic acid',
  'polyurethane',
  'polyether',
  'ramie',
  'rayon',
  'resin',
  'refibra',
  'silk',
  'sisal',
  'solvent-free adhesive',
  'solvent-free polyurethane',
  'soy',
  'stainless steel',
  'stone nut',
  'tencel',
  'titanium',
  'triacetate',
  'vegetable ivory',
  'viscose',
  'water-based adhesive',
  'water-based polyurethane',
  'wax',
  'wood',
  'wool',
  'yak',
  'zinc',
  'zinc alloy'
];

export const COMPONENT_TYPE = [
  'weave',
  'knit',
  'non-woven'
];

export const COMPONENT_CERTIFICATION = [
  'c2c bronce',
  'c2c silver',
  'c2c gold',
  'c2c platin',
  'gots',
  'eu ecolabel',
  'swan label (joutsenmerkki)',
  'bsci',
  'fairtrade',
  'reach',
  'sa8000',
  'grs global recycle standard',
  'gots global organic textile standard',
  'ilo',
  'global compact',
  'ecopelle',
  'bluesign',
  'icec',
  'oeko-tex step',
  'oeko-tex standard 100',
  'oeko-tex leather standard',
  'msds raw material origin',
  'msds spinning',
  'msds dyeing',
  'msds finishing',
  'unic',
  'naturleder',
  'ivn',
  'lwg gold',
  'lwg silver',
  'lwg bronze',
  'icec chemicals management system',
  'icec eco-leathers',
  'icec environmental management system',
  'icec leather traceability',
  'icec occupational health and safety management system',
  'icec product certification for finished leathers',
  'icec quality management system',
  'rcs',
  "higg's index",
  "no_certification"
];

export const STEP_TYPE = [
  'spinning',
  'sizing',
  'knitting',
  'dyeing',
  'printing',
  'finishing',
  'assembly',
  'yarn production',
  'fibre production',
  'fabric production',
  'rawhide',
  'tannery',
  'logistics',
];

export const USER_ROLE = ['CompanyAdmin'];
