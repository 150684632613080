/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import Step from './Step';
import { useFieldArray } from 'react-hook-form';
import { constructEmptyStep, getFieldName } from '../hooks/utils';
import { useAccordionStyles } from './editorUtils';
import ItemHeader from './ItemHeader';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
// import { useTranslation } from 'react-i18next';
// import { v4 as uuid } from 'uuid';

const useStepStyles = makeStyles((theme) => ({
  accordion: {
    borderTop: '2px solid #fafafa',
  },
}));

const StepController = (props) => {
  const { control, companies, prefix } = props;
  const classes = useAccordionStyles();
  const stepClasses = useStepStyles();

  // console.log('stepcontorller', props);
  const ARRAY_KEY = 'steps';
  const STEP_NAME = getFieldName(prefix, ARRAY_KEY);
  // console.log('step name', STEP_NAME);
  const [visibleStep, setVisibleStep] = useState('');
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: STEP_NAME,
    },
  );
  const onCreate = (index) => {
    const obj = constructEmptyStep();
    obj.name = 'Step ' + (fields.length + 1);
    setVisibleStep(`${STEP_NAME}[${index}]`);
    append(obj);
  };

  const onDelete = (payload, index) => {
    // console.log('onDelete', index, payload);
    remove(index);
  };

  return (
    <>
      <Box classes={{ root: stepClasses.accordion }}>
        <Accordion
          square
          elevation={0}
          classes={{ root: classes.root }}
          defaultExpanded={true}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <ItemHeader
              title={'Steps'}
              createLabel="Add new step"
              onCreate={() => onCreate(fields.length)}
              count={fields.length}
            />
          </AccordionSummary>
          <AccordionDetails>
            <Grid container justify="flex-end">
              <Grid item xs={12}>
                {fields.map((e, index) => {
                  return (
                    <>
                      <Grid item xs={12} key={e.id}>
                        <Step
                          prefix={`${STEP_NAME}[${index}]`}
                          onDelete={(e) => onDelete(e, index)}
                          {...e}
                          companies={companies}
                          visibleStep={visibleStep}
                          changeVisibleStep={setVisibleStep}
                        />
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};

export default StepController;
