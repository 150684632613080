/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import api from './api';
import _ from 'lodash';
import {
  constructEmptyAssembly,
  constructEmptyProduct,
  constructEmptyMaterial,
  constructEmptyComponent,
  constructEmptyFiber,
  PRODUCT_ACCEPTED_KEYS,
  ASSEMBLY_ACCEPTED_KEYS,
  MATERIAL_ACCEPTED_KEYS,
  COMPONENT_ACCEPTED_KEYS,
  FIBER_ACCEPTED_KEYS,
  constructEmptyStep,
  STEP_ACCEPTED_KEYS,
} from './utils';

const PRODUCT_ENDPOINT = '/product';
const ASSEMBLY_ENDPOINT = '/assembly';
const MATERIAL_ENDPOINT = '/material';
const COMPONENT_ENDPOINT = '/component';
const FIBER_ENDPOINT = '/fiber';

const DEBUG_LOG = true;

const cleanStepObj = (obj) => {
  console.log('cleanStepObj', obj);
  const merged = Object.assign({}, constructEmptyStep(), obj);
  const resObj = _.pickBy(merged, (v, key) => {
    return STEP_ACCEPTED_KEYS.includes(key);
  });
  console.log('resObj', resObj);
  return resObj;
};

const buildSteps = (steps) => {
  return (steps || []).map((e) => {
    const stepId = e.stepId;
    const companyId = e.companyId;
    const location = e.locationId;
    const cleanStep = cleanStepObj(e);
    cleanStep.id = stepId;
    cleanStep.company = { id: companyId.id || companyId };
    cleanStep.location =
      location && location.id ? { id: location.id } : location;
    return cleanStep;
  });
};

const cleanProductObj = (obj) => {
  const merged = Object.assign({}, constructEmptyProduct(), obj);
  const resObj = _.pickBy(merged, (v, key) => {
    return PRODUCT_ACCEPTED_KEYS.includes(key);
  });

  if (obj.steps) {
    resObj.steps = buildSteps(obj.steps);
  }

  if (obj.companyId) {
    resObj.company = { id: obj.companyId };
  }

  console.log('cleanProductObj res', resObj);
  return resObj;
};

const cleanAssemblyObj = (obj) => {
  const merged = Object.assign({}, constructEmptyAssembly(), obj);
  const resObj = _.pickBy(merged, (v, key) => {
    return ASSEMBLY_ACCEPTED_KEYS.includes(key);
  });

  if (obj.steps) {
    resObj.steps = buildSteps(obj.steps);
  }

  return resObj;
};

const cleanMaterialObj = (obj) => {
  const merged = Object.assign({}, constructEmptyMaterial(), obj);
  const resObj = _.pickBy(merged, (v, key) => {
    return MATERIAL_ACCEPTED_KEYS.includes(key);
  });
  if (obj.steps) {
    resObj.steps = buildSteps(obj.steps);
  }

  if (obj.companyId) {
    resObj.company = { id: obj.companyId };
  }

  return resObj;
};

const cleanComponentObj = (obj) => {
  const merged = Object.assign({}, constructEmptyComponent(), obj);
  const resObj = _.pickBy(merged, (v, key) => {
    return COMPONENT_ACCEPTED_KEYS.includes(key);
  });
  if (obj.companyId) {
    resObj.subcontractorCompany = { id: obj.companyId };
  }
  if (obj.locationId) {
    resObj.location = { id: obj.locationId };
  }

  if (obj.steps) {
    resObj.steps = buildSteps(obj.steps);
  }

  return resObj;
};

const cleanFiberObj = (obj) => {
  const merged = Object.assign({}, constructEmptyFiber(), obj);
  const resObj = _.pickBy(merged, (v, key) => {
    return FIBER_ACCEPTED_KEYS.includes(key);
  });
  if (obj.companyId) {
    resObj.subcontractorCompany = { id: obj.companyId };
  }
  if (obj.locationId) {
    resObj.location = { id: obj.locationId };
  }

  if (obj.steps) {
    resObj.steps = buildSteps(obj.steps);
  }

  return resObj;
};

const constructFullStep = (obj) => {
  return Object.assign({}, constructEmptyStep(), obj, {
    stepId: obj.id,
    companyId: obj.company ? obj.company.id : null,
    locationId: obj.location ? obj.location.id : null,
  });
};

const constructFullProduct = (obj) => {
  console.log({obj});
  return Object.assign({}, constructEmptyProduct(), obj, {
    productId: obj.id,
    companyId: obj.ownerCompany ? obj.ownerCompany.id : null,
    company: obj.ownerCompany ? obj.ownerCompany.name : null,
    steps: (obj.steps || []).map((e) => {
      return constructFullStep(e);
    }),
  });
};

const constructFullAssembly = (obj) => {
  return Object.assign({}, constructEmptyAssembly(), obj, {
    assemblyId: obj.id,
    company: obj.ownerCompany ? obj.ownerCompany.name : null,
    steps: (obj.steps || []).map((e) => {
      return constructFullStep(e);
    }),
  });
};

const constructFullMaterial = (obj) => {
  console.log({obj});
  return Object.assign({}, constructEmptyMaterial(), obj, {
    materialId: obj.id,
    companyId: obj.ownerCompany ? obj.ownerCompany.id : null,
    company: obj.ownerCompany ? obj.ownerCompany.name : null,
    locationId: obj.location ? obj.location.id : null,
    steps: (obj.steps || []).map((e) => {
      return constructFullStep(e);
    }),
  });
};

const constructFullComponent = (obj) => {
  console.log({obj});
  let fiber = null;
  try {
    if (obj.componentFibers.length === 1) {
      fiber = obj.componentFibers[0].fiberId.toString();
    } else {
      fiber = 'Multiple';
    }
  } catch (e) {
    fiber = null;
  }
  return Object.assign({}, constructEmptyComponent(), obj, {
    componentId: obj.id,
    companyId: obj.subcontractorCompany ? obj.subcontractorCompany.id : null,
    company: obj.subcontractorCompany ? obj.subcontractorCompany.name : null,
    locationId: obj.location ? obj.location.id : null,
    fiber,
    steps: (obj.steps || []).map((e) => {
      return constructFullStep(e);
    }),
  });
};

const constructFullFiber = (obj) => {
  return Object.assign({}, constructEmptyFiber(), obj, {
    fiberId: obj.id,
    companyId: obj.subcontractorCompany ? obj.subcontractorCompany.id : null,
    company: obj.subcontractorCompany ? obj.subcontractorCompany.name : null,
    locationId: obj.location ? obj.location.id : null,
    steps: (obj.steps || []).map((e) => {
      return constructFullStep(e);
    }),
  });
};

/**
 * Get by LCIM Id
 * @param {'fiber'|'component'|'material'|'assembly'|'product'} endpoint 
 * @param {string} lcimId
 */
 const getItemByLcimId = (endpoint, lcimId) => {
  // console.log({lcimId});
  const apiCallLcimId = (ENDPOINT, constructFullFunc) => api
    .get(ENDPOINT + '/lcim/' + lcimId)
    .then((res) => {
      if (!res.data.success) {
        throw res.data.errors;
      }
      return res.data.data;
    })
    .then((res) => {
      return constructFullFunc(res);
    });
  switch (endpoint) {
    case 'assembly':
      return apiCallLcimId(ASSEMBLY_ENDPOINT, constructFullAssembly);
    case 'component':
      return apiCallLcimId(COMPONENT_ENDPOINT, constructFullComponent);
    case 'fiber':
      return apiCallLcimId(FIBER_ENDPOINT, constructFullFiber);
    case 'material':
      return apiCallLcimId(MATERIAL_ENDPOINT, constructFullMaterial);
    case 'product':
      return apiCallLcimId(PRODUCT_ENDPOINT, constructFullProduct);
    default:
      return undefined;
  }
}

//  PRODUCT
/**
 * Get all products
 * @returns {Array} products
 */
export const getProducts = () => {
  return api
    .get(PRODUCT_ENDPOINT)
    .then((res) => {
      if (!res.data.success) {
        throw res.data.errors;
      }
      return res.data.data;
    })
    .then((res) => {
      return res.map((e) => constructFullProduct(e));
    });
};

const getProductById = (id) => {
  return api
    .get(PRODUCT_ENDPOINT + '/' + id)
    .then((res) => {
      if (!res.data.success) {
        throw res.data.errors;
      }
      return res.data.data;
    })
    .then((res) => {
      return constructFullProduct(res);
    });
};

const getProductByLcimId = (lcimId) => {
  return api
    .get(PRODUCT_ENDPOINT + '/lcim/' + lcimId)
    .then((res) => {
      if (!res.data.success) {
        throw res.data.errors;
      }
      return res.data.data;
    })
    .then((res) => {
      return constructFullProduct(res);
    });
};

const mapFibers = (componentObj, allFibers) => {
  return componentObj.componentFibers
    ? componentObj.componentFibers.map((componentFiber) => {
        const fiberObj = allFibers.find(
          (m) => m.fiberId === componentFiber.fiberId,
        );
        // get percentage value from material object
        fiberObj.percentage = componentFiber.percentage;
        return fiberObj;
      })
    : [];
};

const mapComponents = (materialObj, allComponents) => {
  return materialObj.materialComponents
    ? materialObj.materialComponents.map((materialComponent) => {
        const componentObj = allComponents.find(
          (m) => m.componentId === materialComponent.componentId,
        );
        // get percentage value from material object
        componentObj.percentage = materialComponent.percentage;
        return componentObj;
      })
    : [];
};

const mapMaterials = (assemblyObj, allMaterials, allComponents) => {
  return assemblyObj.assemblyMaterials
    ? assemblyObj.assemblyMaterials.map((assemblyMaterial) => {
        const materialObj = allMaterials.find(
          (m) => m.materialId === assemblyMaterial.materialId,
        );
        const components = mapComponents(materialObj, allComponents);
        materialObj.components = components;
        return materialObj;
      })
    : [];
};

const mapAssemblies = (
  productObj,
  allAssemblies,
  allMaterials,
  allComponents,
) => {
  return productObj.productsAssemblies
    ? productObj.productsAssemblies.map((e) => {
        const assemblyObj = allAssemblies.find(
          (a) => a.assemblyId === e.assemblyId,
        );
        // map through materials
        const materials = mapMaterials(
          assemblyObj,
          allMaterials,
          allComponents,
        );
        assemblyObj.materials = materials;
        return assemblyObj;
      })
    : [];
};

/**
 * Get complete product object
 *
 * @param {*} id
 * @returns {Object} product
 */
export const getCompleteProduct = (id) => {
  if (DEBUG_LOG) console.log('GET COMPLETE PRODUCT', id);
  let allAssemblies = null;
  let allMaterials = null;
  let allComponents = null;
  return getComponents()
    .then((res) => {
      allComponents = res;
      return getMaterials();
    })
    .then((res) => {
      allMaterials = res;
      return getAssemblies();
    })
    .then((res) => {
      allAssemblies = res;
      return getProductById(id);
    })
    .then((res) => {
      const productObj = res;
      const assemblies = mapAssemblies(
        productObj,
        allAssemblies,
        allMaterials,
        allComponents,
      );
      productObj.assemblies = assemblies;
      if (DEBUG_LOG) console.log('GET COMPLETE PRODUCT results', productObj);
      return productObj;
    });
};

export const createProduct = (productObj) => {
  if (DEBUG_LOG) console.log('CREATE product', productObj);
  const obj = {
    entity: cleanProductObj(productObj),
  };
  return api.post(PRODUCT_ENDPOINT, obj).then((res) => {
    if (!res.data.success) {
      throw res.data.errors;
    }
    return res.data.data;
  });
};

export const updateProduct = (productId, productObj) => {
  if (DEBUG_LOG) console.log('UPDATE product', productId, productObj);
  const obj = {
    entity: cleanProductObj(productObj),
  };
  return api.patch(PRODUCT_ENDPOINT + '/' + productId, obj).then((res) => {
    if (!res.data.success) {
      throw res.data.errors;
    }
    return res.data.data;
  });
};

// ASSEMBLY

/**
 * Get all assemblies
 * @returns {Array} assemblies
 */
export const getAssemblies = () => {
  return api
    .get(ASSEMBLY_ENDPOINT)
    .then((res) => {
      if (!res.data.success) {
        throw res.data.errors;
      }
      return res.data.data;
    })
    .then((res) => {
      return res.map((e) => constructFullAssembly(e));
    });
};

const getAssemblyById = (id) => {
  return api
    .get(ASSEMBLY_ENDPOINT + '/' + id)
    .then((res) => {
      if (!res.data.success) {
        throw res.data.errors;
      }
      return res.data.data;
    })
    .then((res) => {
      console.log('assembly data', res);
      const obj = constructFullAssembly(res);
      console.log('full assembly', obj);
      return obj;
    });
};

export const getCompleteAssembly = (id) => {
  let allMaterials = null;
  let allComponents = null;
  return getComponents()
    .then((res) => {
      allComponents = res;
      return getMaterials();
    })
    .then((res) => {
      allMaterials = res;
      return getAssemblyById(id);
    })
    .then((res) => {
      const assemblyObj = res;
      const materials = mapMaterials(assemblyObj, allMaterials, allComponents);
      assemblyObj.materials = materials;
      console.log('assemblyObj', assemblyObj);
      return assemblyObj;
    });
};

export const createAssembly = (assemblyObj) => {
  if (DEBUG_LOG) console.log('CREATE assembly', assemblyObj);
  const obj = {
    entity: cleanAssemblyObj(assemblyObj),
  };
  return api.post(ASSEMBLY_ENDPOINT, obj).then((res) => {
    // console.log('createAssembly res', res);
    if (!res.data.success) {
      throw res.data.errors;
    }
    return res.data.data;
  });
};

export const updateAssembly = (assemblyId, assemblyObj) => {
  if (DEBUG_LOG) console.log('UPDATE assembly', assemblyId, assemblyObj);
  const obj = {
    entity: cleanAssemblyObj(assemblyObj),
  };
  // console.log('updateAssembly', assemblyId, obj);
  return api.patch(ASSEMBLY_ENDPOINT + '/' + assemblyId, obj).then((res) => {
    // console.log('updateAssembly res', res);
    if (!res.data.success) {
      throw res.data.errors;
    }
    return res.data.data;
  });
};

export const linkAssemblyToProduct = (assemblyId, productId) => {
  if (DEBUG_LOG) console.log('LINK assemblyToProduct', assemblyId, productId);
  const url = PRODUCT_ENDPOINT + '/' + productId + '/assembly/' + assemblyId;
  return api({
    method: 'link',
    url,
  }).then((res) => {
    // console.log('linkAssemblyToProduct res', res);
    if (!res.data.success) {
      throw res.data.errors;
    }
    return res.data.data;
  });
};

export const unlinkAssemblyFromProduct = (assemblyId, productId) => {
  if (DEBUG_LOG)
    console.log('UNLINK assemblyFromProduct', assemblyId, productId);
  const url = PRODUCT_ENDPOINT + '/' + productId + '/assembly/' + assemblyId;
  return api({
    method: 'unlink',
    url,
  }).then((res) => {
    // console.log('unlinkAssemblyFromProduct res', res);
    if (!res.data.success) {
      throw res.data.errors;
    }
    return res.data.data;
  });
};

// MATERIAL

/**
 * Get all materials
 * @returns {Array} materials
 */
export const getMaterials = () => {
  return api
    .get(MATERIAL_ENDPOINT)
    .then((res) => {
      if (!res.data.success) {
        throw res.data.errors;
      }
      return res.data.data;
    })
    .then((res) => {
      return res.map((e) => constructFullMaterial(e));
    });
};

const getMaterialById = (id) => {
  if (DEBUG_LOG) console.log('getMaterialById', id);
  return api
    .get(MATERIAL_ENDPOINT + '/' + id)
    .then((res) => {
      if (!res.data.success) {
        throw res.data.errors;
      }
      return res.data.data;
    })
    .then((res) => {
      return constructFullMaterial(res);
    });
};

export const getCompleteMaterial = (id) => {
  if (DEBUG_LOG) console.log('getCompleteMaterial', id);
  let allComponents = null;
  return getComponents()
    .then((res) => {
      allComponents = res;
      return getMaterialById(id);
    })
    .then((res) => {
      const materialObj = res;
      const components = mapComponents(materialObj, allComponents);
      materialObj.components = components;
      return materialObj;
    });
};

export const createMaterial = (materialObj) => {
  if (DEBUG_LOG) console.log('CREATE material', materialObj);
  const obj = {
    entity: cleanMaterialObj(materialObj),
  };
  console.log({obj});
  return api.post(MATERIAL_ENDPOINT, obj).then((res) => {
    // console.log('createAssembly res', res);
    if (!res.data.success) {
      throw res.data.errors;
    }
    return res.data.data;
  });
};

export const updateMaterial = (materialId, materialObj) => {
  if (DEBUG_LOG) console.log('UPDATE material', materialId, materialObj);
  const obj = {
    entity: cleanMaterialObj(materialObj),
  };
  return api.patch(MATERIAL_ENDPOINT + '/' + materialId, obj).then((res) => {
    if (!res.data.success) {
      throw res.data.errors;
    }
    return res.data.data;
  });
};

export const linkMaterialToAssembly = (materialId, assemblyId) => {
  if (DEBUG_LOG) console.log('LINK materialToAssembly', materialId, assemblyId);
  const url = ASSEMBLY_ENDPOINT + '/' + assemblyId + '/material/' + materialId;
  return api({
    method: 'link',
    url,
  }).then((res) => {
    if (!res.data.success) {
      throw res.data.errors;
    }
    return res.data.data;
  });
};

export const unlinkMaterialFromAssembly = (materialId, assemblyId) => {
  if (DEBUG_LOG)
    console.log('UNLINK materialFromAssembly', materialId, assemblyId);
  const url = ASSEMBLY_ENDPOINT + '/' + assemblyId + '/material/' + materialId;
  return api({
    method: 'unlink',
    url,
  }).then((res) => {
    if (!res.data.success) {
      throw res.data.errors;
    }
    return res.data.data;
  });
};

// COMPONENT

export const getComponents = () => {
  return api
    .get(COMPONENT_ENDPOINT)
    .then((res) => {
      if (!res.data.success) {
        throw res.data.errors;
      }
      return res.data.data;
    })
    .then((res) => {
      return res.map((e) => constructFullComponent(e));
    });
};

const getComponentById = (id) => {
  return api
    .get(COMPONENT_ENDPOINT + '/' + id)
    .then((res) => {
      if (!res.data.success) {
        throw res.data.errors;
      }
      return res.data.data;
    })
    .then((res) => {
      return constructFullComponent(res);
    });
};

export const getCompleteComponent = (id) => {
  if (DEBUG_LOG) console.log('getCompleteComponent', id);
  let allFibers = null;
  return getFibers()
    .then((res) => {
      allFibers = res;
      return getComponentById(id);
    })
    .then((res) => {
      const componentObj = res;
      const fibers = mapFibers(componentObj, allFibers);
      componentObj.fibers = fibers;
      return componentObj;
    });
};

export const createComponent = (componentObj) => {
  if (DEBUG_LOG) console.log('CREATE component', componentObj);
  const obj = {
    entity: cleanComponentObj(componentObj),
  };
  return api.post(COMPONENT_ENDPOINT, obj).then((res) => {
    if (!res.data.success) {
      throw res.data.errors;
    }
    return res.data.data;
  });
};

export const updateComponent = (componentId, componentObj) => {
  if (DEBUG_LOG) console.log('UPDATE component', componentId, componentObj);
  const obj = {
    entity: cleanComponentObj(componentObj),
  };
  return api.patch(COMPONENT_ENDPOINT + '/' + componentId, obj).then((res) => {
    if (!res.data.success) {
      throw res.data.errors;
    }
    return res.data.data;
  });
};

export const linkComponentToMaterial = (
  componentId,
  materialId,
  percentage,
) => {
  if (DEBUG_LOG)
    console.log('LINK componentToMaterial', componentId, materialId);
  const url =
    MATERIAL_ENDPOINT + '/' + materialId + '/component/' + componentId;
  return api({
    method: 'link',
    url,
    data: {
      percentage,
    },
  }).then((res) => {
    if (DEBUG_LOG) console.log('linkComponentToMaterial results', res);
    if (!res.data.success) {
      throw res.data.errors;
    }
    return res.data.data;
  });
};

export const unlinkComponentFromMaterial = (componentId, materialId) => {
  if (DEBUG_LOG)
    console.log('UNLINK componentFromMaterial', componentId, materialId);
  const url =
    MATERIAL_ENDPOINT + '/' + materialId + '/component/' + componentId;
  return api({
    method: 'unlink',
    url,
  }).then((res) => {
    if (!res.data.success) {
      throw res.data.errors;
    }
    return res.data.data;
  });
};

// FIBER

export const getFibers = () => {
  return api
    .get(FIBER_ENDPOINT)
    .then((res) => {
      if (!res.data.success) {
        throw res.data.errors;
      }
      return res.data.data;
    })
    .then((res) => {
      return res.map((e) => constructFullFiber(e));
    });
};

const getFiberById = (id) => {
  return api
    .get(FIBER_ENDPOINT + '/' + id)
    .then((res) => {
      if (!res.data.success) {
        throw res.data.errors;
      }
      return res.data.data;
    })
    .then((res) => {
      return constructFullFiber(res);
    });
};

export const getCompleteFiber = (id) => {
  return getFiberById(id);
};

export const createFiber = (fiberObj) => {
  if (DEBUG_LOG) console.log('CREATE fiber', fiberObj);
  const obj = {
    entity: cleanFiberObj(fiberObj),
  };
  return api.post(FIBER_ENDPOINT, obj).then((res) => {
    if (!res.data.success) {
      throw res.data.errors;
    }
    return res.data.data;
  });
};

export const updateFiber = (fiberId, fiberObj) => {
  if (DEBUG_LOG) console.log('UPDATE fiber', fiberId, fiberObj);
  const obj = {
    entity: cleanFiberObj(fiberObj),
  };
  return api.patch(FIBER_ENDPOINT + '/' + fiberId, obj).then((res) => {
    if (!res.data.success) {
      throw res.data.errors;
    }
    return res.data.data;
  });
};

export const linkFiberToComponent = (
  fiberId,
  componentId,
  percentage,
) => {
  if (DEBUG_LOG)
    console.log('LINK fiberToComponent', fiberId, componentId);
  const url =
    COMPONENT_ENDPOINT + '/' + componentId + '/fiber/' + fiberId;
  return api({
    method: 'link',
    url,
    data: {
      percentage,
    },
  }).then((res) => {
    if (DEBUG_LOG) console.log('linkFiberToComponent results', res);
    if (!res.data.success) {
      throw res.data.errors;
    }
    return res.data.data;
  });
};

export const unlinkFiberFromComponent = (fiberId, componentId) => {
  if (DEBUG_LOG)
    console.log('UNLINK fiberFromComponent', fiberId, componentId);
  const url =
    COMPONENT_ENDPOINT + '/' + componentId + '/fiber/' + fiberId;
  return api({
    method: 'unlink',
    url,
  }).then((res) => {
    if (!res.data.success) {
      throw res.data.errors;
    }
    return res.data.data;
  });
};

// EXPORT

const useProduct = () => {
  return {
    // products
    getCompleteProduct,
    getProducts,
    getProductByLcimId,
    createProduct,
    updateProduct,

    // assemblies
    getAssemblies,
    getCompleteAssembly,
    createAssembly,
    updateAssembly,
    linkAssemblyToProduct,
    unlinkAssemblyFromProduct,

    // materials
    getMaterials,
    getCompleteMaterial,
    createMaterial,
    updateMaterial,
    linkMaterialToAssembly,
    unlinkMaterialFromAssembly,

    // components
    getComponents,
    getCompleteComponent,
    createComponent,
    updateComponent,
    linkComponentToMaterial,
    unlinkComponentFromMaterial,

    // fibers
    getFibers,
    getCompleteFiber,
    createFiber,
    updateFiber,

    // common
    getItemByLcimId
  };
};

export default useProduct;
