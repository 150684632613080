/* eslint-disable no-console */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Typography,
  TableCell,
  TableRow,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
  RadioGroup,
  Radio,
  InputAdornment,
  FormHelperText,
  Tooltip,
  Icon
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { ConnectForm } from './product/ConnectForm';
import { getFieldName } from '../hooks/utils';
import { ErrorMessage } from '@hookform/error-message';
import { get } from 'react-hook-form';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import InfoIcon from '@material-ui/icons/Info';
import DateFnsUtils from '@date-io/date-fns';
import SelectComponent from './SelectComponent';
import SelectMultipleComponent from './SelectMultipleComponent';
import TextComponent from './TextComponent';

const useStyles = makeStyles(() => ({
  theader: {
    width: '12em',
  },
  tinput: {
    width: '21em',
  },
  rg: {
    justifyContent: 'center',
  },
}));

const getDateFormat = () => {
  return 'dd.MM.yyyy';
};

// const TextComponent = (props) => {
//   const { control, name, defaultValue, isError, rules } = props;
//   return (
//     <Controller
//       control={control}
//       name={name}
//       defaultValue={defaultValue}
//       rules={rules}
//       render={({ onChange, value, name, ref }) => (
//         <TextField
//           onChange={(e) => {
//             onChange(e.target.value);
//           }}
//           variant="outlined"
//           fullWidth
//           value={value != null ? value : ''}
//           name={name}
//           inputRef={ref}
//           error={isError}
//         />
//       )}
//     />
//   );
// };

const NumberComponent = (props) => {
  const {
    control,
    name,
    defaultValue,
    inputaddon = '',
    isError,
    rules,
  } = props;
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ onChange, value, name, ref }) => (
        <TextField
          onChange={(e) => {
            let newValue = e.target.value;
            if (newValue === '') {
              newValue = null;
            }
            onChange(newValue);
          }}
          type="number"
          variant="outlined"
          fullWidth
          value={value != null ? value : ''}
          name={name}
          inputRef={ref}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{inputaddon}</InputAdornment>
            ),
          }}
          error={isError}
        />
      )}
    />
  );
};

const HiddenComponent = (props) => {
  const { control, name, defaultValue } = props;
  return (
    <Controller
      as={() => false}
      control={control}
      name={name}
      defaultValue={defaultValue}
    />
  );
};

const BooleanComponent = (props) => {
  const { name, control, defaultValue, rules, isError } = props;
  const { t } = useTranslation();
  return (
    <>
      <Controller
        as={(e) => (
          <FormGroup error={isError}>
            <FormControlLabel
              control={
                <>
                  <Switch
                    color="primary"
                    checked={e.value ? true : false}
                    onChange={(a) => {
                      e.onChange(a.target.checked);
                    }}
                  />
                  {e.value === true ? t('common:yes') : t('common:no')}
                </>
              }
            />
          </FormGroup>
        )}
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={rules}
      />
    </>
  );
};

const TriComponent = (props) => {
  // console.log('BOOLEAN ', props);
  const { name, control, defaultValue, rules, isError } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Controller
        as={(e) => (
          <>
            <RadioGroup
              error={isError}
              row
              classes={{ root: classes.rg }}
              onChange={(event) => {
                const newValue = event.target.value;
                if (newValue === '') {
                  e.onChange(null);
                } else if (newValue === 'no') {
                  e.onChange(false);
                } else if (newValue === 'yes') {
                  e.onChange(true);
                }
              }}
            >
              <FormControlLabel
                value=""
                control={<Radio checked={e.value === null} color="primary" />}
                label={t('common:dont_know')}
                labelPlacement="end"
              />
              <FormControlLabel
                value="no"
                control={<Radio checked={e.value === false} color="primary" />}
                label={t('common:no')}
                labelPlacement="end"
              />
              <FormControlLabel
                value="yes"
                color="primary"
                control={<Radio checked={e.value === true} color="primary" />}
                label={t('common:yes')}
                labelPlacement="end"
              />
            </RadioGroup>
          </>
        )}
        defaultValue={defaultValue}
        name={name}
        control={control}
        rules={rules}
      />
    </>
  );
};

const DateComponent = (props) => {
  const { control, name, defaultValue, isError, rules } = props;
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ onChange, value }) => (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            margin="normal"
            format={getDateFormat()}
            onChange={onChange}
            // inputVariant="outlined"
            TextFieldComponent={(e) => (
              <TextField {...e} variant="outlined" fullWidth error={isError} />
            )}
            fullWidth
            value={value}
          />
        </MuiPickersUtilsProvider>
      )}
    />
  );
};

const COMPONENT_MAP = {
  text: TextComponent,
  select: SelectComponent,
  selectmultiple: SelectMultipleComponent,
  boolean: BooleanComponent,
  trimode: TriComponent,
  number: NumberComponent,
  hidden: HiddenComponent,
  date: DateComponent,
};

const DataRow = (props) => {
  const {
    type,
    label,
    name,
    dataRowProps,
    langcontext,
    bigtext,
    rules,
    link,
    tooltip,
    excludeLabels,
  } = props;
  const { t } = useTranslation(langcontext || 'common');
  const { t: validatorT } = useTranslation('validators');
  const { prefix, valueProps } = dataRowProps;
  const classes = useStyles();

  let Component = COMPONENT_MAP[type];

  if (!Component) {
    Component = () => <span>component type {type} not implemented yet </span>;
  }
  const componentProps = Object.assign({}, props, {
    name: prefix ? getFieldName(prefix, name) : name,
    defaultValue: valueProps ? valueProps[name] : null,
    rules,
  });

  return (
    <>
      <ConnectForm>
        {(formProps) => {
          const { errors } = formProps;
          const errorObj = get(errors, componentProps.name);
          if (type !== 'hidden') {
            return (
              <>
                <TableRow>
                  <TableCell classes={{ root: classes.theader }}>
                    {tooltip ? (
                      <div style={{ display: "flex", flexFlow: "row", alignItems: "center" }}>
                        <Typography variant={bigtext ? 'body2' : 'body1'}>
                          {label}
                        </Typography>
                        <Tooltip title={tooltip} placement="top-end">
                          <InfoIcon
                            style={{ marginBottom: "16px"}}
                            fontSize="small"
                            color="inherit"
                          />
                        </Tooltip>
                      </div>
                    ):(
                      <Typography variant={bigtext ? 'body2' : 'body1'}>
                        {label}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell classes={{ root: classes.tinput }}>
                    <Component
                      {...componentProps}
                      {...formProps}
                      isError={errorObj && true}
                    />
                    <ErrorMessage
                      errors={errors}
                      name={componentProps.name}
                      render={({ message }) => {
                        // console.log('errors', errors);
                        return (
                          <FormHelperText error>
                            {validatorT(message)}
                          </FormHelperText>
                        );
                      }}
                    />
                  </TableCell>
                  {excludeLabels ? (
                    false
                  ) : (
                    <>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell align="right">
                        {rules && rules.required && (
                          <Typography variant="subtitle2">
                            {t('required')}
                          </Typography>
                        )}
                      </TableCell>
                    </>
                  )}
                </TableRow>
              </>
            );
          } else {
            return <Component {...componentProps} {...formProps} />;
          }
        }}
      </ConnectForm>
    </>
  );
};

export default DataRow;
