import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Divider,
  IconButton,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  CircularProgress,
  OutlinedInput,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
// import SelectClass from './SelectClass';
// import ErrorComponent from '../ErrorComponent';
import { useSnackbar } from 'notistack';
import useUser from '../../hooks/useUser';
import useCompany from '../../hooks/useCompany';
import { USER_ROLE } from '../constants';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    paddingTop: '1.6rem',
    paddingBottom: '1rem',
    paddingRight: '0.6rem',
    paddingLeft: '1.6rem',
  },
  divider: {
    width: '99%',
    marginTop: '16px',
    marginBottom: '16px',
  },
  image: {
    width: '92px',
  },
  icon: {
    padding: '0px',
    float: 'right',
    right: '0px',
  },
  header: {
    flex: 10,
    marginLeft: '0.3em',
  },
  close: {
    flex: 1,
  },
  circular: {
    float: 'left',
    position: 'absolute',
  },
  controls: {
    marginTop: '1em',
  },
}));

const UserEdit = (props) => {
  const { onChange, user } = props;
  const classes = useStyles();
  const { editUser } = useUser();
  const { getCompanies } = useCompany();
  const { t } = useTranslation(['userlist', 'login', 'common']);
  const { t: tOpts } = useTranslation('options');
  const [loading, setLoading] = useState(false);
  const [removing, setRemoving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [companies, setCompanies] = useState(null);

  const schema = yup.object().shape({
    name: yup.string().required(t('common:required')),
    email: yup
      .string()
      .email(t('err_email_invalid'))
      .required(t('err_email_req')),
    role: yup.string().required(t('err_role_req')),
    company: yup.string().required(t('err_company_req')),
  });

  const defVal = {
    name: user.name || '',
    email: user.email || '',
    role: user.role || '',
    company: user.company.id || '',
  };

  console.log('user', user);

  const {
    handleSubmit,
    register,
    errors,
    formState,
    // watch,
    control,
    // reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defVal,
  });

  const options = [
    { value: ' ', label: t('common:sel_list') },
    ...USER_ROLE.map((r) => {
      return { value: r || '', label: tOpts('user_role.' + r) };
    }),
  ];

  const fetch = () => {
    setLoading(true);
    getCompanies()
      .then((res) => {
        const _vals = [
          { value: ' ', label: t('common:sel_list') },
          ...res.map((a) => {
            return { label: a.name, value: a.id };
          }),
        ];
        setCompanies(_vals);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(fetch, []);

  const _final = (a) => {
    setLoading(false);
    if (Array.isArray(a) || a.message) {
      enqueueSnackbar(a.message || a.join(', ') || a.toString(), {
        variant: 'error',
      });
    } else {
      enqueueSnackbar(t('common:save_success') + ': ' + a.name, {
        variant: 'success',
      });
      onChange();
      // reset(defVal);
    }
  };

  const editUserHandler = (p) => {
    setLoading(true);
    // return new Promise((res, rej) =>
    //   rej({ message: 'User editing is not working yet <3' }),
    // )
    return editUser(user.id, p).then(_final).catch(_final);
  };

  const removeUser = () => {
    setRemoving(true);
    enqueueSnackbar('User deletion is not supported yet', {
      variant: 'error',
    });
    setTimeout(() => {
      setRemoving(false);
    }, 3000);
  };

  const _validate = (p) => {
    try {
      return editUserHandler(p);
    } catch (error) {
      _final([error.message]);
    }
  };

  return (
    <>
      <Paper square variant="outlined">
        <form name="edit_user" onSubmit={handleSubmit(_validate, _final)}>
          <Grid container classes={{ root: classes.root }} spacing={3}>
            <Grid container item xs={12}>
              <Grid item xs={8} sm={11}>
                <Typography variant="body2">{t('edit_user')}</Typography>
              </Grid>

              <Grid
                container
                item
                xs={4}
                sm={1}
                className={classes.close}
                alignItems="flex-end"
                justify="flex-end"
              >
                <IconButton
                  classes={{ root: classes.icon }}
                  onClick={onChange}
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid container item xs={12} className={classes.header}>
              &nbsp;
            </Grid>

            <Grid
              container
              xs={12}
              item
              justify="center"
              alignItems="center"
              classes={{ root: classes.controls }}
            >
              {/* Edit name control */}
              <Grid item xs={3}>
                <Typography variant="body1">{t('name')}</Typography>
              </Grid>
              <Grid item xs={9}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={errors.name && errors.name.message ? true : false}
                >
                  <OutlinedInput
                    id="name"
                    name="name"
                    aria-describedby="User name"
                    inputRef={register({})}
                    inputProps={{
                      disabled: formState.isSubmitting,
                      tabIndex: '1',
                      placeholder: t('name'),
                    }}
                  />
                  <FormHelperText id="add-user-email-helper">
                    {errors.name && errors.name.message}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Divider variant="middle" classes={{ root: classes.divider }} />
              <Grid item xs={3}>
                <Typography variant="body1">{t('email')}</Typography>
              </Grid>
              <Grid item xs={9}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={errors.email && errors.email.message ? true : false}
                >
                  <OutlinedInput
                    id="email"
                    name="email"
                    autoComplete="email"
                    aria-describedby="User email"
                    inputRef={register({})}
                    inputProps={{
                      disabled: formState.isSubmitting,
                      tabIndex: '2',
                      placeholder: t('email'),
                    }}
                  />
                  <FormHelperText id="add-user-email-helper">
                    {errors.email && errors.email.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Divider variant="middle" classes={{ root: classes.divider }} />
              {/* Select Role control */}
              <Grid item xs={3}>
                <Typography variant="body1">{t('role')}</Typography>
              </Grid>
              <Grid item xs={9}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={errors.role && errors.role.message ? true : false}
                >
                  <Controller
                    as={({ value, onChange }) => {
                      return (
                        <>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            error={errors.role}
                          >
                            <InputLabel id={'roleselect_label'}>
                              {t('common:sel_list')}
                            </InputLabel>
                            <Select
                              value={value}
                              fullWidth
                              tabIndex="3"
                              id={'roleselect'}
                              name={'roleselect'}
                              onChange={(e) => onChange(e.target.value)}
                            >
                              {options &&
                                options.map((e) => {
                                  const key = 'key_' + JSON.stringify(e.value);
                                  return (
                                    <MenuItem key={key} value={e.value}>
                                      {e.label > '' ? (
                                        e.label
                                      ) : (
                                        <em>{t('common:sel_list')}</em>
                                      )}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </>
                      );
                    }}
                    control={control}
                    name={'role'}
                  />
                  <FormHelperText id="add-user-email-helper">
                    {errors.role && errors.role.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Divider variant="middle" classes={{ root: classes.divider }} />
              {/* Select Company control */}
              <Grid item xs={3}>
                <Typography variant="body1">{t('company')}</Typography>
              </Grid>
              <Grid item xs={9}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={errors.company && errors.role.company ? true : false}
                >
                  <Controller
                    as={({ value, onChange }) => {
                      return (
                        <>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            error={errors.company}
                          >
                            <InputLabel id={'companyselectlabel'}>
                              {t('common:sel_list')}
                            </InputLabel>
                            <Select
                              value={value}
                              fullWidth
                              tabIndex="3"
                              id={'companyselect'}
                              onChange={(e) => onChange(e.target.value)}
                            >
                              {companies &&
                                companies.map((e) => {
                                  const key = 'key_' + JSON.stringify(e.value);
                                  return (
                                    <MenuItem key={key} value={e.value}>
                                      {e.label > '' ? (
                                        e.label
                                      ) : (
                                        <em>{t('common:sel_list')}</em>
                                      )}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </>
                      );
                    }}
                    control={control}
                    name={'company'}
                  />
                  <FormHelperText id="add-user-email-helper">
                    {errors.company && errors.company.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Divider variant="middle" classes={{ root: classes.divider }} />
              <Grid item xs={12}>
                <Button
                  type=""
                  onClick={(e) => {
                    e.preventDefault();
                    removeUser();
                  }}
                  tabIndex="4"
                  fullWidth
                  elevation={0}
                  disabled={removing}
                >
                  {removing && <CircularProgress size={20} />}
                  &nbsp;
                  <Typography color="error" variant="body1">
                    {t('remove_user')}
                  </Typography>
                </Button>
              </Grid>
            </Grid>

            <Grid
              container
              xs={12}
              item
              justify="center"
              classes={{ root: classes.controls }}
            >
              <Grid
                container
                item
                xs={6}
                justify="center"
                alignItems="flex-start"
              >
                <Typography color="error" variant="body1">
                  {errors.server && errors.server.message}
                </Typography>
              </Grid>

              <Grid item xs={12}></Grid>
              <Grid item xs={6}>
                <Button
                  color="primary"
                  type="submit"
                  tabIndex="5"
                  variant="contained"
                  fullWidth
                  elevation={0}
                  disabled={loading || !formState.isValid}
                >
                  {loading && <CircularProgress size={20} />}
                  &nbsp;
                  {t('common:save_changes')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default UserEdit;

UserEdit.propTypes = {
  user: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
