/* eslint-disable react/prop-types */
import React, {useRef} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  makeStyles,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
// import { makeStyles } from '@material-ui/core/styles';
import DataRow from '../DataRow';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { required } from '../editorRules';
import { useForm, Controller } from 'react-hook-form';
import useInvite from '../../hooks/useInvite';
import useComponentState from '../../hooks/useComponentState';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const emailRegex = /^\S+@\S+\.\S+$/;

const useStyles = makeStyles((theme) => ({
  text: {
    color: "#1E2D44",
    fontSize: "12px",
    fontFamily: '"Open Sans", Arial, Helvetica, sans-serif',
    fontWeight: 300,
    lineHeight: "1.5",
    letterSpacing: "-0.24px"
  },
  cont: {
    padding: "16px"
  },
  spacer: {
    padding: "16px",
    flexGrow: "1"
  }
}));

const CompanyLocation = (props) => {
  const { t } = useTranslation('company');
  const { watch, locationIndex, visibleLocation, setVisibleLocation } = props;
  const prefix = `locations[${locationIndex}]`;
  const locationName = watch(`${prefix}.name`);
  return (
    <>
      <Accordion
        key={`l_accordion_${locationIndex}`}
        square
        elevation={0}
        expanded={prefix === visibleLocation}
        onChange={(e) => {
          e.stopPropagation();
          setVisibleLocation(visibleLocation !== prefix ? prefix : '');
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {locationName ? (
            locationName
          ) : (
            <Typography variant="subtitle2">
              <em>Unnamed location</em>
            </Typography>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <DataRow
            type={'text'}
            name={`${prefix}.name`}
            label={t('location_name')}
            dataRowProps={{ ...props }}
            excludeLabels={true}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const CompanyEditView = (props) => {
  const {
    createNewLocation,
    locations,
    visibleLocation,
    setVisibleLocation,
  } = props;

  const { t } = useTranslation('company');

  return (
    <>
      <TableContainer>
        <Table>
          <TableBody>
            <DataRow type={'hidden'} name={'id'} dataRowProps={props} />
            <DataRow
              type={'text'}
              name={'name'}
              label={t('name')}
              rules={required}
              dataRowProps={props}
            />
            <TableRow>
              <TableCell>
                <Typography>{t('locations')}</Typography>
              </TableCell>
              <TableCell colSpan={3}>
                {locations
                  ? locations.map((l, i) => (
                      <CompanyLocation
                        key={`l_${i}`}
                        locationIndex={i}
                        {...props}
                        valueProps={l}
                        visibleLocation={visibleLocation}
                        setVisibleLocation={setVisibleLocation}
                      />
                    ))
                  : ''}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="right" colSpan={4}>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    createNewLocation(locations.length);
                  }}
                >
                  {t('add_location')}
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container fullWidth>
        <CompanyInviteView cid={props.valueProps.id}/>
      </Grid>
    </>
  );
};

export const CompanyInviteView = (props) => {
  const { cid } = props; // Company ID
  const { t } = useTranslation(['company','common']);
  const { invite } = useInvite();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { loading, setLoading, error, setError } = useComponentState();

  let emailInput = useRef();

  function handleEmailInput() {
    emailInput.current.focus();
  }

  const onSubmitInvite = (payload) => {
    if (!emailRegex.test(payload.current.value)) {
      setError('Set valid email');
      setLoading(false);
      return;
    }
    setLoading(true);
    setError(null);
    invite(payload.current.value, cid)
      .then(() => { // param res
        enqueueSnackbar(t('invite_sent'), { variant: 'success' });
      })
      .catch((err) => {
        console.error(err);
        let message = 'Unknown error';
        if (err && Array.isArray(err)) {
          message = t(err[0]);
        }
        setError(message);
        enqueueSnackbar(t('invite_failed'), { variant: 'error' });
      })
      .finally(() => {
        emailInput.current.value = '';
        setLoading(false);
      });
  };

  return (
    <>
      <Grid container fullWidth>
        <Grid container item direction="row" justify="flex-start" alignItems="center">
          <Grid item justify="flex-start" alignItems="center" className={classes.cont} style={{ width: "164.47px" }}>
            <Typography paragraph variant="body2" className={classes.text}>
              {t('invite')}
            </Typography>
          </Grid>
          <Grid item justify="flex-start" alignItems="center" className={classes.cont}>
            <FormControl variant="outlined" fullWidth>
              <OutlinedInput
                id="email"
                name="email"
                autoComplete="email"
                aria-describedby="User email"
                onClick={handleEmailInput}
                inputRef={emailInput}
                inputProps={{
                  placeholder: t('email'),
                }}
              />
              <FormHelperText id="login-user-helper">
                {error ? error : ''}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item justify="flex-start" alignItems="center" className={classes.spacer}>
            &nbsp;
          </Grid>
          <Grid item justify="flex-end" className={classes.cont}>
            <Button
              color="primary"
              key="invite"
              variant="contained"
              elevation={0}
              onClick={() => onSubmitInvite(emailInput)}
            >
              {loading && <CircularProgress size={20} />}
              &nbsp;
              {t('send')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CompanyEditView;
