/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Grid, AppBar, IconButton, Typography, Icon } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useRoutes } from '../../hooks/useRoutes';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { isRole } from '../../hooks/AppContext';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.light,
    fontSize: '0.8em',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  selected: {
    fontWeight: 600,
  },
}));

const MainHeader = () => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const { PATH } = useRoutes();
  const history = useHistory();
  const location = useLocation();

  const onLogoutClick = () => {
    history.push(PATH.logout);
  };

  const isAdmin = isRole('SuperAdmin');

  const LinkItem = ({ to, name, icon, emoji }) => {
    return (
      <Grid item style={{ display:'flex', flexFlow:'row', alignItems:'center' }}>
        {icon?(
          <Icon fontSize="small" style={{ marginRight: '4px' }} color="inherit">{icon}</Icon>
        ):(
          <></>
        )}
        {emoji?(<span style={{ marginRight: '4px' }}>{emoji}</span>):(<></>)}
        <Link
          to={to}
          className={clsx(
            classes.link,
            location.pathname === to ? classes.selected : {},
          )}
        >
          {t(name)}
        </Link>
      </Grid>
    );
  };

  return (
    <>
      <Box>
        <AppBar color="secondary" position="static">
          <Grid justify="space-between" container wrap="nowrap">
            <Grid item xs={4} container justify="center" alignItems="center">
              <Link to="/" className={classes.link}>
                <Typography variant="h1">FINIX</Typography>
              </Link>
            </Grid>
            <Grid item container alignItems="center" wrap="nowrap" spacing={4}>
              <LinkItem to="/fibers" name="fibers" icon="menu"/>
              <NavigateNextOutlinedIcon style={{ fontSize: 14 }} viewBox="0,0,24,16" />
              <LinkItem to="/components" name="components" icon="gesture"/>
              <NavigateNextOutlinedIcon style={{ fontSize: 14 }} viewBox="0,0,24,16" />
              <LinkItem to="/materials" name="materials" icon="grid_on"/>
              <NavigateNextOutlinedIcon style={{ fontSize: 14 }} viewBox="0,0,24,16" />
              <LinkItem to="/assemblies" name="assemblies" icon="extension"/>
              <NavigateNextOutlinedIcon style={{ fontSize: 14 }} viewBox="0,0,24,16" />
              <LinkItem to="/products" name="products" icon="more"/>
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              justify="flex-end"
              wrap="nowrap"
              spacing={4}
            >
              <LinkItem to="/gdpr" name="gdpr" icon="gavel"/>
              <LinkItem to="/manual" name="manual" icon="menu_book"/>
              <LinkItem to="/companies" name="companies" icon="business"/>
              <LinkItem to="/userslist" name="users" icon="account_box"/>
              {isAdmin && (<LinkItem to="/admin" name="admin" icon="supervisor_account" />)}
            </Grid>
            <Grid
              item
              xs={2}
              container
              justify="flex-end"
              alignItems="center"
              wrap="nowrap"
            >
              <Box mx={2} />
              <IconButton onClick={onLogoutClick} color="primary">
                <ExitToAppIcon />
              </IconButton>
            </Grid>
          </Grid>
        </AppBar>
      </Box>
    </>
  );
};

export default MainHeader;
