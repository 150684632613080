/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import { Box } from '@material-ui/core';
import React from 'react';
import { ConnectForm } from './product/ConnectForm';
import { useFieldArray } from 'react-hook-form';
import Assembly from './Assembly';
import { constructEmptyAssembly } from '../hooks/utils';
import ProductView from './ProductView';
import { useTranslation } from 'react-i18next';
import StepController from './StepController';
import ModalDialog from './ModalDialog';
import { Observer, useLocalObservable } from 'mobx-react-lite';
import useProduct from '../hooks/useProduct';
import useObservableState from './useObservableState';
import { useEditorRootStyles } from './editorUtils';
import AssignToModalDialog from './AssignToModalDialog';
import ControllerHeader from './ControllerHeader';

const AssemblyController = (props) => {
  const { control, companies, watch } = props;
  const { t } = useTranslation('product');
  const ARRAY_KEY = 'assemblies';
  const { fields, append, remove } = useFieldArray({
    control,
    name: ARRAY_KEY,
  });
  const { getAssemblies, getCompleteAssembly } = useProduct();
  const { state: fetchState } = useObservableState();

  const dialogState = useLocalObservable(() => ({
    open: false,
    setOpen(e) {
      this.open = e;
    },
    options: [],
    setOptions(e) {
      this.options = e;
    },
  }));

  const assignToDialogState = useLocalObservable(() => ({
    open: false,
    setOpen(e) {
      this.open = e;
    },
    dataObject: null,
    setDataObject(e) {
      this.dataObject = e;
    },
  }));

  const onDialogSubmit = async (payload) => {
    if (!payload) {
      createNew();
      dialogState.setOpen(false);
    } else {
      const id = payload && payload.options ? payload.options : null;
      fetchState.setLoading(true);
      fetchState.setError(null);
      try {
        const obj = await getCompleteAssembly(id);
        if (obj.materials && obj.materials.length > 0) {
          obj.appendMaterials = true;
        }
        append(obj);
        dialogState.setOpen(false);
      } catch (err) {
        fetchState.setError(err);
      } finally {
        fetchState.setLoading(false);
      }
    }
  };

  const onOpenDialog = () => {
    dialogState.setOpen(true);
    fetchState.setLoading(true);
    fetchState.setError(null);
    getAssemblies()
      .then((res) => {
        fetchState.setResults(res);
        const arrayValue = watch(ARRAY_KEY) || [];
        const options = res.map((e) => {
          return {
            value: e.id,
            label: e.name,
            disabled: arrayValue.find((f) => f.assemblyId === e.id) || false,
          };
        });
        dialogState.setOptions(options);
      })
      .catch((err) => {
        fetchState.setError(err);
      })
      .finally(() => {
        fetchState.setLoading(false);
      });
  };

  const createNew = () => {
    const obj = constructEmptyAssembly();
    append(obj);
  };

  const onDelete = (payload, index) => {
    remove(index);
  };

  const onAssignTo = (payload) => {
    assignToDialogState.setDataObject(payload);
    assignToDialogState.setOpen(true);
  };

  return (
    <>
      <ControllerHeader
        state={fetchState}
        onAddButtonClick={() => onOpenDialog()}
        title={t('assemblies') + ' (' + fields.length + ')'}
      />
      <Box mt={2}>
        {fields.map((e, index) => {
          // console.log('asssembly e', e);
          return (
            <Assembly
              key={e.id}
              prefix={`${ARRAY_KEY}[${index}]`}
              onDelete={(e) => onDelete(e, index)}
              onAssignTo={(payload) => onAssignTo(payload)}
              companies={companies}
              {...e}
            />
          );
        })}
      </Box>
      <Observer>
        {() => (
          <>
            {dialogState.open && (
              <ModalDialog
                options={dialogState.options}
                onClose={() => dialogState.setOpen(false)}
                onSubmit={onDialogSubmit}
                fetchState={fetchState}
                title="Add new assembly"
                addButtonLabel="Add assembly from list"
                createButtonLabel="Create a new assembly"
              />
            )}
          </>
        )}
      </Observer>
      <Observer>
        {() => (
          <>
            {assignToDialogState.open && (
              <AssignToModalDialog
                dataObject={assignToDialogState.dataObject}
                onClose={() => assignToDialogState.setOpen(false)}
                title="Assign assembly"
              />
            )}
          </>
        )}
      </Observer>
    </>
  );
};

const Product = (props) => {
  const classes = useEditorRootStyles();
  const { companies } = props;
  return (
    <ConnectForm>
      {(formProps) => {
        return (
          <>
            <Box px={2} mb={8} classes={classes}>
              <ProductView
                {...formProps}
                valueProps={props}
                companies={companies}
              />
              <StepController {...formProps} companies={companies} />
            </Box>
            <AssemblyController {...formProps} companies={companies} />
          </>
        );
      }}
    </ConnectForm>
  );
};
export default Product;
