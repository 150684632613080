import React from 'react';
import pkg from '../../package.json';
import jwt_decode from 'jwt-decode';

const ENVIRONMENT = process.env.NODE_ENV;

const AppContext = React.createContext({
  name: 'Finix',
  version: pkg.version,
  environment: ENVIRONMENT,
});

const isAuthenticated = () => {
  const token = localStorage.getItem('token');
  if (token != null) {
    const decoded_token = jwt_decode(token);
    console.log({decoded_token});
    const now = new Date().getTime() / 1000;
    return now < decoded_token.exp ? true : false; // token expired
  } else {
    return false;
  }
};

const isRole = (rolename) => {
  const userJSON = localStorage.getItem('user');
  const user = JSON.parse(userJSON);
  console.log({isRole: true, user});
  if (user != null) {
    return rolename === user.role ? true : false;
  } else {
    return false;
  }
}

const logout = () => {
  localStorage.clear();
};
const login = (_props) => {
  // TODO
  return new Promise((res, rej) => {
    setTimeout(() => {
      if (_props.loginUser === 'asd@asd.fi') {
        return res(_props);
      } else return rej({ message: "Server doesn't know you" });
    }, 4500);
  });
};

const getSelectListOptions = (listId = '', filterVals = {}) => {
  // TODO: tehdäänpä tänne yhteinen haku listojen itemeille...
  return Promise.resolve([
    { name: 'ASD1', value: 'asd1' },
    { name: 'ASD2', value: 'asd2' },
    { name: 'ASD3', value: 'asd3' },
  ]);
};

const userDefaults = {
  userWeightUnit: 'g',
  userEnergyConsumptionUnit: 'kWh/kg',
  userWaterConsumptionUnit: 'l/kg',
  userEmissionsUnit: 'CO2/kg',
};

export {
  AppContext,
  isAuthenticated,
  isRole,
  logout,
  login,
  getSelectListOptions,
  userDefaults,
};
