/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Typography,
  Grid,
  Divider,
  IconButton,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  CircularProgress,
  OutlinedInput,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import CloseIcon from '@material-ui/icons/Close';
// import PropTypes from 'prop-types';
// import SelectClass from './SelectClass';
// import ErrorComponent from '../ErrorComponent';
import { useSnackbar } from 'notistack';
import { USER_ROLE } from '../constants';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
// import useUser from '../../hooks/useUser';
// import useCompany from '../../hooks/useCompany';
import useInvite from '../../hooks/useInvite';
import useComponentState from '../../hooks/useComponentState';

const emailRegex = /^\S+@\S+\.\S+$/;

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    paddingTop: '1.6rem',
    paddingBottom: '1rem',
    paddingRight: '0.6rem',
    paddingLeft: '1.6rem',
  },
  divider: {
    width: '99%',
    marginTop: '16px',
    marginBottom: '16px',
  },
  image: {
    width: '92px',
  },
  icon: {
    padding: '0px',
    float: 'right',
    right: '0px',
  },
  header: {
    flex: 10,
    marginLeft: '0.3em',
  },
  close: {
    flex: 1,
  },
  controls: {
    marginTop: '1em',
  },
}));

const UserAdd = (props) => {
  const { onChange, companies } = props;
  const classes = useStyles();
  // const { createNewUser, linkUserToCompany } = useUser();
  const { invite } = useInvite();
  const { t } = useTranslation(['userlist', 'login', 'common']);
  const { t: tOpts } = useTranslation('options');
  // const [loading, setLoading] = useState(false);
  const { loading, setLoading, error, setError } = useComponentState();
  const { enqueueSnackbar } = useSnackbar();

  const schema = yup.object().shape({
    // name: yup.string().required(t('common:required')),
    email: yup
      .string()
      .email(t('err_email_invalid'))
      .required(t('err_email_req')),
    role: yup.string().required(t('err_role_req')),
    company: yup.number().required(t('err_company_req')),
  });

  const defVal = {
    // name: '',
    email: '',
    role: '',
    company: null
  };

  const {
    handleSubmit,
    register,
    errors,
    formState,
    watch,
    control,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defVal,
  });

  const watchAll = watch();

  const canSubmit = () => {
    if (watchAll && watchAll.email && watchAll.role && watchAll.company) {
      // console.log({email: watchAll.email, role: watchAll.role, company: watchAll.company})
      return (!loading && watchAll.email.length > 6 && watchAll.role.length > 0 && watchAll.company >= 0);
    }
    return false;
  };

  const options = [
    { value: ' ', label: t('common:sel_list') },
    ...USER_ROLE.map((r) => {
      return { value: r || '', label: tOpts('user_role.' + r) };
    }),
  ];

  // const companies = [ { id: 2, name: 'Test Company' } ];

  // const _final = (a) => {
  //   console.log('UserAdd_final', a);
  //   if (Array.isArray(a)) {
  //     enqueueSnackbar(a.join(', '), {
  //       variant: 'error',
  //     });
  //   } else {
  //     enqueueSnackbar(t('common:save_success') + ': ' + a.name, {
  //       variant: 'success',
  //     });
  //     reset(defVal);
  //   }
  //   setLoading(false);
  // };

  // const createUserHandler = (a) => {

  // };

  // const _validate = (p) => {
  //   try {
  //     return createUserHandler(p);
  //   } catch (error) {
  //     _final([error.message]);
  //   }
  // };

  const devSubmit = (payload) => {
    console.log({payload});
  }

  const onSubmit = (payload) => {
    if (!emailRegex.test(payload.email)) {
      setError('Set valid email');
      setLoading(false);
      return;
    }
    setLoading(true);
    invite(payload.email, payload.company)
      .then(() => { // param res
        enqueueSnackbar(t('invite_sent'), { variant: 'success' });
        reset(defVal);
      })
      .catch((err) => {
        console.error(err);
        let message = 'Unknown error';
        if (err && Array.isArray(err)) {
          message = t(err[0]);
        } else {
          message = t('invite_failed');
        }
        setError(message);
        enqueueSnackbar(message, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // const onError = (payload) => {
  //   console.error(payload);
  // };

  return (
    <>
      <Paper square variant="outlined">
        <form name="add_user" onSubmit={handleSubmit(onSubmit)}>
          <Grid container classes={{ root: classes.root }} spacing={3}>
            <Grid container item xs={12}>
              <Grid item xs={8} sm={11}>
                <Typography variant="body2">{t('add_user')}</Typography>
              </Grid>

              <Grid
                container
                item
                xs={4}
                sm={1}
                className={classes.close}
                alignItems="flex-end"
                justify="flex-end"
              >
                <IconButton
                  classes={{ root: classes.icon }}
                  onClick={onChange}
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid container item xs={12} className={classes.header}>
              &nbsp;
            </Grid>

            <Grid
              container
              xs={12}
              item
              justify="center"
              alignItems="center"
              classes={{ root: classes.controls }}
            >
              <Grid item xs={2}>
                <Typography variant="body1">{t('email')}</Typography>
              </Grid>
              <Grid item xs={10}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={errors.email && errors.email.message ? true : false}
                >
                  <OutlinedInput
                    id="email"
                    name="email"
                    autoComplete="email"
                    aria-describedby="User email"
                    inputRef={register({})}
                    inputProps={{
                      disabled: formState.isSubmitting,
                      tabIndex: '2',
                      placeholder: t('email'),
                    }}
                  />
                  <FormHelperText id="add-user-email-helper">
                    {errors.email && errors.email.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Divider variant="middle" classes={{ root: classes.divider }} />
              <Grid item xs={2}>
                <Typography variant="body1">{t('role')}</Typography>
              </Grid>
              <Grid item xs={10}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={errors.role && errors.role.message ? true : false}
                >
                  <Controller
                    as={({ value, onChange }) => {
                      return (
                        <FormControl
                          variant="outlined"
                          fullWidth
                          error={errors.role}
                        >
                          <InputLabel id={'roleselect'}>
                            {t('common:sel_list')}
                          </InputLabel>
                          <Select
                            value={value}
                            fullWidth
                            tabIndex="3"
                            id={'roleselect'}
                            onChange={(e) => onChange(e.target.value)}
                          >
                            {options &&
                              options.map((e) => {
                                const key = 'key_' + JSON.stringify(e.value);
                                return (
                                  <MenuItem key={key} value={e.value}>
                                    {e.label > '' ? (
                                      e.label
                                    ) : (
                                      <em>{t('common:sel_list')}</em>
                                    )}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      );
                    }}
                    control={control}
                    name={'role'}
                  />
                  <FormHelperText id="add-user-email-helper">
                    {errors.role && errors.role.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Divider variant="middle" classes={{ root: classes.divider }} />
              <Grid item xs={2}>
                <Typography variant="body1">{t('_company')}</Typography>
              </Grid>
              <Grid item xs={10}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={errors.company && errors.company.message}
                >
                  <Controller
                    as={({ value, onChange }) => {
                      return (
                        <FormControl
                          variant="outlined"
                          fullWidth
                          error={errors.company}
                        >
                          <InputLabel id={'companyselect'}>
                            {t('common:sel_list')}
                          </InputLabel>
                          <Select
                            value={value}
                            fullWidth
                            tabIndex="3"
                            id={'companyselect'}
                            onChange={(e) => onChange(e.target.value)}
                          >
                            {companies &&
                              companies.map((c) => {
                                return (
                                  <MenuItem key={c.name} value={c.id}>
                                    {c.name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      );
                    }}
                    control={control}
                    name={'company'}
                  />
                  <FormHelperText id="add-user-company-helper">
                    {errors.company && errors.company.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              xs={12}
              item
              justify="center"
              classes={{ root: classes.controls }}
            >
              <Grid
                container
                item
                xs={6}
                justify="center"
                alignItems="flex-start"
              >
                {/*<Typography color="error" variant="body1">
                  {errors.server && errors.server.message}
                  </Typography>*/}
                <FormHelperText id="login-user-helper">
                  {error ? error : ''}
                </FormHelperText>
              </Grid>

              <Grid item xs={12}></Grid>
              <Grid item xs={6}>
                <Button
                  color="primary"
                  type="submit"
                  tabIndex="4"
                  variant="contained"
                  fullWidth
                  elevation={0}
                  disabled={!canSubmit()}
                >
                  {loading && <CircularProgress size={20} />}
                  &nbsp;
                  {t('create_user')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default UserAdd;
