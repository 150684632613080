/* eslint-disable react/prop-types */
import React from 'react';
import Box from '@material-ui/core/Box';

const ErrorBox = (props) => {
  const getMessage = () => {
    if (props && props.error && props.error.message) {
      return props.error.message;
    } else if (props && props.error) {
      return JSON.stringify(props.error);
    } else if (props) {
      return JSON.stringify(props);
    } else {
      return 'Unknown error';
    }
  };

  return <Box color="error.main">{getMessage()}</Box>;
};

export default ErrorBox;
