import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  Modal,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import useCompany from '../hooks/useCompany';
import MainHeader from '../components/dashboard/MainHeader';
import TableList from '../components/product/TableList';
import CompanyAdd from '../components/product/CompanyAdd';
import CompanyEdit from '../components/product/CompanyEdit';
import PropTypes from 'prop-types';
import { getLoggedInUser } from '../hooks/utils';
import ErrorComponent from '../components/ErrorComponent';
import useComponentState from '../hooks/useComponentState';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    maxWidth: '90%',
  },
  links: {
    // paddingTop: '3em',
  },
  page: {
    marginTop: '2.5em',
  },
  modalbase: {
    height: '100%',
    flexGrow: 1,
  },
  modalpaper: {
    minWidth: '340px',
    overflowY: 'scroll',
    maxHeight: '100vh',
  },
  cont: {
    paddingLeft: '4em',
    paddingTop: '0.55em',
    paddingBottom: '0.55em',
    marginRight: '4em',
  },
}));

const TableComponent = ({ items, addButton }) => {
  return (
    <>
      <TableList
        items={items}
        pagetitle="Companies"
        langcontext="company"
        rendercols={['img', 'name', 'id', 'function']}
        addButton={addButton}
      />
    </>
  );
};

const CompaniesList = () => {
  const user = getLoggedInUser();
  const { getCompanies, getCompanyById } = useCompany();
  const { t } = useTranslation(['company', 'common']);
  const classes = useStyles();
  const [items, setItems] = useState();
  const [needsReload, setReload] = useState(false);
  const { loading, setLoading, error, setError } = useComponentState();
  const createCompanyButton = {
    label: t('create_company'),
    callback: () => addCompanyFunc(),
  };
  const [editCompany, setEditCompany] = useState({
    item: {},
    visible: false,
  });
  const [addCompany, setAddCompany] = useState({
    item: {},
    visible: false,
  });

  const editCompanyFunc = (contentItem) => {
    if (editCompany.visible) {
      setReload(true);
      setEditCompany({ item: {}, visible: false });
      return;
    }
    getCompanyById(contentItem.id).then((company) => {
      setEditCompany({ visible: true, item: company });
    });
  };
  const addCompanyFunc = (contentItem) => {
    if (addCompany.visible) {
      setReload(true);
      setAddCompany({ item: {}, visible: false });
      return;
    }
    setAddCompany({ visible: true, item: contentItem });
  };

  const fetch = () => {
    console.log('needsReload', needsReload);
    setLoading(true);
    setError(null);
    getCompanies()
      .then((res) => {
        res.forEach((a) => {
          a.function = () => {
            editCompanyFunc(a);
          };
          a.icon = 'open';
        });
        setItems(res);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
        setReload(false);
      });
  };

  useEffect(fetch, [needsReload]);

  return (
    <>
      <MainHeader />
      <Box classes={{ root: classes.cont }}>
        <Box classes={{ root: classes.links }}>
          {/* <ArrowBack style={{ marginRight: '0.4em', fontSize: '1.0em' }} />
          <Typography variant="subtitle1">
            {t('products')} / {t('create_product')}
          </Typography> */}
          {/* <Breadcrumbs>
            <Link color="inherit">
              <span>{t('Companies')}</span>
            </Link>
          </Breadcrumbs> */}
        </Box>

        {user && user.company && user.company.name && (
          <>
            <Grid
              container
              spacing={3}
              alignItems="flex-end"
              classes={{ root: classes.page }}
            >
              <Grid item xs={12}>
                <Typography variant="body1">{t('main_company')}:</Typography>
                <Typography variant="h6">{user.company.name}</Typography>
              </Grid>
            </Grid>
          </>
        )}

        <Box classes={{ root: classes.page }}>
          {loading && (
            <Grid container justify="center">
              <CircularProgress />
            </Grid>
          )}
          {items && (
            <TableComponent items={items} addButton={createCompanyButton} />
          )}
          {error && <ErrorComponent error={error} />}
        </Box>
      </Box>
      <Modal open={addCompany && addCompany.visible}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          classes={{ root: classes.modalbase }}
        >
          <Grid item xs={8} sm={5} classes={{ root: classes.modalpaper }}>
            {addCompany && addCompany.visible ? (
              <>
                <CompanyAdd
                  user={addCompany.item}
                  langcontext="company"
                  onChange={addCompanyFunc}
                />
              </>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </Modal>
      <Modal open={editCompany && editCompany.visible}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          classes={{ root: classes.modalbase }}
        >
          <Grid item xs={8} sm={5} classes={{ root: classes.modalpaper }}>
            {editCompany && editCompany.visible ? (
              <>
                <CompanyEdit
                  item={editCompany.item}
                  langcontext="company"
                  onChange={editCompanyFunc}
                />
              </>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default CompaniesList;

// MaterialListPage.propTypes = {
//   item: PropTypes.object.isRequired,
// };

TableComponent.propTypes = {
  items: PropTypes.array,
};
