/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { ConnectForm } from './product/ConnectForm';
import { getFieldName } from '../hooks/utils';
import StepView from './StepView';
import {
  Grid,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ItemHeader from './ItemHeader';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useAccordionStyles } from './editorUtils';
import useCompany from '../hooks/useCompany';

const Step = (props) => {
  // console.log('ASSEMBLY', props);
  const { prefix, onDelete, companies, visibleStep, changeVisibleStep } = props;
  const classes = useAccordionStyles();
  const [locations, setLocations] = useState([]);
  const { getCompanyById } = useCompany();
  const { t: selectT } = useTranslation('options');

  const onCompanyIdChange = (payload) => {
    getCompanyById(payload).then((res) => {
      const { locations } = res;
      setLocations(locations);
    });
  };

  return (
    <>
      <ConnectForm>
        {(formProps) => {
          const { watch } = formProps;
          const stepType = watch(getFieldName(prefix, 'type'));
          const stepLabel = stepType ? selectT('step_type.' + stepType) : null;
          return (
            <Accordion
              square
              elevation={0}
              classes={{ root: classes.root }}
              expanded={prefix === visibleStep}
              onChange={() => {
                changeVisibleStep(visibleStep !== prefix ? prefix : '');
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <ItemHeader
                  title={
                    stepLabel || (
                      <Typography variant="subtitle2">
                        <em>Unknown step</em>
                      </Typography>
                    )
                  }
                  onDelete={onDelete ? () => onDelete(props) : null}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid item xs={12}>
                    <StepView
                      {...formProps}
                      prefix={prefix}
                      valueProps={props}
                      companies={companies}
                      locations={locations}
                      onCompanyIdChange={onCompanyIdChange}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          );
        }}
      </ConnectForm>
    </>
  );
};

export default Step;
