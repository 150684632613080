/* eslint-disable indent */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { TableContainer, Table, TableBody } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DataRow from './DataRow';
import { getFieldName } from '../hooks/utils';
import {
  // COMPONENT_FIBER,
  COMPONENT_CERTIFICATION,
  COMPONENT_TYPE
} from './constants';
import { required } from './editorRules';

const ComponentView = (props) => {
  const {
    prefix,
    watch,
    disablePercentage = false,
    companies,
    trigger,
    locations = [],
    onCompanyIdChange = () => false
  } = props;

  const { t } = useTranslation(['component', 'common']);
  const isRecycledValue = watch(getFieldName(prefix, 'isRecycled'));
  const companyIdValue = watch(getFieldName(prefix, 'companyId'));

  const { t: selectT } = useTranslation('options');

  const companiesOptions = companies
    ? companies.map((e) => {
        return {
          value: e.id,
          label: e.name,
        };
      })
    : [];

  useEffect(() => {
    onCompanyIdChange(companyIdValue);
    trigger('locationId');
  }, [companyIdValue]);

  const locationOptions = locations.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  // const fiberOptions = COMPONENT_FIBER.map((e) => ({
  //   value: e,
  //   label: selectT('component_fiber.' + e),
  // }));

  const certificationOptions = COMPONENT_CERTIFICATION.map((e) => ({
    value: e,
    label: selectT('component_certification.' + e),
  }));

  const typeOptions = COMPONENT_TYPE.map((e) => ({
    value: e,
    label: selectT('component_type.' + e)
  }))

  return (
    <>
      <TableContainer>
        <Table>
          <TableBody>
            <DataRow
              type={'hidden'}
              name={'componentId'}
              dataRowProps={props}
            />
            <DataRow
              type={'text'}
              name={'name'}
              label={t('name')}
              rules={required}
              dataRowProps={props}
            />
            {!disablePercentage ? (
              <DataRow
                type={'number'}
                name={'percentage'}
                label={t('percentage')}
                rules={required}
                dataRowProps={props}
                inputaddon={'%'}
              />
            ) : (
              false
            )}
            <DataRow
              type={'select'}
              name={'companyId'}
              label={t('company')}
              options={companiesOptions}
              dataRowProps={props}
              rules={required}
            />
            {locationOptions && locationOptions.length ? (
              <>
                <DataRow
                  type={'select'}
                  name={'locationId'}
                  label={t('location')}
                  options={locationOptions}
                  dataRowProps={props}
                  rules={required}
                />
              </>
            ) : (
              false
            )}
            <DataRow
              type={'selectmultiple'}
              name={'type'}
              label={t('type')}
              options={typeOptions}
              rules={required}
              dataRowProps={props}
            />
            {/*
            <DataRow
              type={'selectmultiple'}
              name={'fiber'}
              label={t('fiber')}
              options={fiberOptions}
              rules={required}
              dataRowProps={props}
            />
            */}
            <DataRow
              type={'trimode'}
              name={'isRecycled'}
              label={t('isRecycled')}
              dataRowProps={props}
              onBlur={() => {
                trigger('sourceOfRecycledInput');
              }}
            />
            {isRecycledValue === true ? (
              <>
                <DataRow
                  type={'text'}
                  name={'sourceOfRecycledInput'}
                  label={t('sourceOfRecycledInput')}
                  rules={required}
                  dataRowProps={props}
                />
              </>
            ) : (
              false
            )}
            <DataRow
              type={'text'}
              name={'sourceOfFiber'}
              label={t('sourceOfFiber')}
              rules={required}
              dataRowProps={props}
            />
            <DataRow
              type={'selectmultiple'}
              name={'certification'}
              label={t('certification')}
              options={certificationOptions}
              rules={required}
              dataRowProps={props}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ComponentView;
