import React, { useState } from 'react';
import {
  Typography,
  Grid,
  // Divider,
  IconButton,
  Button,
  Paper,
  CircularProgress,
  // OutlinedInput,
  // FormControl,
  // FormHelperText,
} from '@material-ui/core';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import CompanyEditView, { CompanyInviteView } from './CompanyEditView';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
// import { ConnectForm } from '../ConnectForm';
import { getFieldName } from '../../hooks/utils';
import {
  useForm,
  Controller,
  FormProvider,
  useFieldArray,
} from 'react-hook-form';
import { useSnackbar } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import useCompany from '../../hooks/useCompany';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    paddingTop: '1.6rem',
    paddingBottom: '1rem',
    paddingRight: '0.6rem',
    paddingLeft: '1.6rem',
  },
  divider: {
    width: '99%',
    marginTop: '16px',
    marginBottom: '16px',
  },
  image: {
    width: '92px',
  },
  icon: {
    padding: '0px',
    float: 'right',
    right: '0px',
  },
  header: {
    flex: 10,
    marginLeft: '0.3em',
  },
  close: {
    flex: 1,
  },
  userIcon: {
    fontSize: '6rem',
    opacity: 0.2,
  },
  controls: {
    marginTop: '0.5em',
  },
}));

const CompanyEdit = (props) => {
  const { onChange, item } = props;
  const classes = useStyles();
  const { updateCompany } = useCompany();
  const { t } = useTranslation(['company', 'login', 'common']);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [visibleLocation, setVisibleLocation] = useState('');
  const [visibleInvite, setVisibleInvite] = useState('');

  const schema = yup.object().shape({
    name: yup.string().required(t('common:required')),
    locations: yup.array(),
    id: yup.string(),
  });

  const defVal = item || {
    name: '',
    id: '',
    locations: [],
  };

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defVal,
  });

  const {
    handleSubmit,
    errors,
    setError,
    formState,
    watch,
    control,
    reset,
  } = methods;

  const ARRAY_KEY = 'locations';
  const LOCATION_NAME = getFieldName('', ARRAY_KEY);
  const INVITE_EMAIL = getFieldName('', 'invites');
  // console.log('step name', STEP_NAME);
  const { fields, append/*, prepend, remove, swap, move, insert*/ } = useFieldArray(
    {
      control,
      name: LOCATION_NAME,
    },
  );

  const newLocation = (i) => {
    const obj = { id: null, name: '' };
    setVisibleLocation(`${LOCATION_NAME}[${i}]`);
    append(obj);
  };

  const newInvite = (i) => {
    const obj = { id: null, email: '' };
    setVisibleInvite(`${INVITE_EMAIL}[${i}]`);
    append(obj);
  };

  const state = watch();

  const updateErr = (a) => {
    // setError('loginUser', { type: 'manual', message: t('err_pwd_invalid') });
    if (a.message) setError('server', { type: 'manual', message: a.message });
    setLoading(false);
  };

  const _final = (a) => {
    // console.log(a);
    if (Array.isArray(a) || a.message) {
      let _messages = { message: a.join(', ') };
      enqueueSnackbar(a.message || a.join(', ') || a.toString(), {
        variant: 'error',
      });
      updateErr(_messages);
    } else {
      enqueueSnackbar(t('common:save_success') + ': ' + a.name, {
        variant: 'success',
      });
      reset(defVal);
      onChange();
    }
    setLoading(false);
  };

  const editCompanyHandler = (a) => {
    setLoading(true);
    return updateCompany(a.id, a.name, a.locations).then(_final).catch(_final);
  };
  console.log('state', state);

  const _validate = () => {
    try {
      return editCompanyHandler(state);
    } catch (error) {
      updateErr(error);
    }
  };

  return (
    <>
      <Paper square variant="outlined">
        <FormProvider {...methods}>
          <form
            name="add_company"
            onSubmit={handleSubmit(_validate, updateErr)}
          >
            <Grid container classes={{ root: classes.root }} spacing={3}>
              <Grid container item sm={12}>
                <Grid item sm={11}>
                  <Typography variant="body2">{t('edit_company')}</Typography>
                </Grid>

                <Grid
                  container
                  item
                  sm={1}
                  className={classes.close}
                  alignItems="center"
                  justify="flex-end"
                >
                  <IconButton
                    classes={{ root: classes.icon }}
                    onClick={onChange}
                    disableTouchRipple
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid
                container
                item
                sm={12}
                alignItems="center"
                justify="flex-start"
              >
                <Grid container item xs={3} justify="center">
                  {state.img ? (
                    <img src={state.img} alt={'Not found'} width="92"></img>
                  ) : (
                    <AccountBoxIcon classes={{ root: classes.userIcon }} />
                  )}
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2">{state.name}</Typography>
                  <Typography variant="body1">{state.id}</Typography>
                  <Controller
                    as={() => false}
                    control={control}
                    name={'id'}
                    defaultValue={item.id}
                  />
                  <br></br>
                  {/* <Typography variant="body1">{state.company.name}</Typography> */}
                </Grid>
              </Grid>
              <CompanyEditView
                {...methods}
                prefix={''}
                valueProps={state}
                createNewLocation={(payload) => newLocation(payload)}
                locations={fields}
                visibleLocation={visibleLocation}
                setVisibleLocation={(payload) => setVisibleLocation(payload)}
              />
              <Grid
                container
                xs={12}
                item
                justify="center"
                classes={{ root: classes.controls }}
              >
                <Grid
                  container
                  item
                  xs={6}
                  justify="center"
                  alignItems="flex-start"
                >
                  <Typography color="error" variant="body1">
                    {errors.server && errors.server.message}
                  </Typography>
                </Grid>

                <Grid item xs={12}></Grid>
                <Grid item xs={6}>
                  <Button
                    color="primary"
                    type="submit"
                    tabIndex="4"
                    variant="contained"
                    fullWidth
                    elevation={0}
                    disabled={ loading || !formState.isValid || !formState.isDirty }
                  >
                    {loading && <CircularProgress size={20} />}
                    &nbsp;
                    {t('common:save_changes')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Paper>
    </>
  );
};

export default CompanyEdit;

CompanyEdit.propTypes = {
  onChange: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};
