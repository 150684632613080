import React, { useState } from 'react';
import {
  Typography,
  Grid,
  Divider,
  IconButton,
  Button,
  Paper,
  CircularProgress,
  OutlinedInput,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
// import SelectClass from './SelectClass';
import { useSnackbar } from 'notistack';
import useCompany from '../../hooks/useCompany';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    paddingTop: '1.6rem',
    paddingBottom: '1rem',
    paddingRight: '0.6rem',
    paddingLeft: '1.6rem',
  },
  divider: {
    width: '99%',
    marginTop: '16px',
    marginBottom: '16px',
  },
  image: {
    width: '92px',
  },
  icon: {
    padding: '0px',
    float: 'right',
    right: '0px',
  },
  header: {
    flex: 10,
    marginLeft: '0.3em',
  },
  close: {
    flex: 1,
  },
  controls: {
    marginTop: '1em',
  },
}));

const CompanyAdd = (props) => {
  const { onChange } = props;
  const classes = useStyles();
  const { createCompany } = useCompany();
  const { t } = useTranslation(['company', 'login', 'common']);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const schema = yup.object().shape({
    name: yup.string().required(t('common:required')),
  });

  const defVal = {
    name: '',
  };

  const {
    handleSubmit,
    register,
    errors,
    // setError,
    formState,
    // watch,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defVal,
  });

  const updateErr = () => {
    // setError('loginUser', { type: 'manual', message: t('err_pwd_invalid') });
    // if (a.message) setError('server', { type: 'manual', message: a.message });
    setLoading(false);
  };

  const _final = (a) => {
    if (a.message) {
      let _messages = a.response.data.errors.company || a.message;
      enqueueSnackbar(_messages, {
        variant: 'error',
      });

      updateErr(_messages);
    } else {
      enqueueSnackbar(t('common:save_success') + ': ' + a.name, {
        variant: 'success',
      });
      reset(defVal);
      onChange();
    }
    setLoading(false);
  };

  const createCompanyHandler = (a) => {
    setLoading(true);
    return createCompany(a.name).then(_final).catch(_final);
  };

  const _validate = (p) => {
    try {
      return createCompanyHandler(p);
    } catch (error) {
      updateErr(error);
    }
  };

  return (
    <>
      <Paper square variant="outlined">
        <form name="add_company" onSubmit={handleSubmit(_validate, updateErr)}>
          <Grid container classes={{ root: classes.root }} spacing={3}>
            <Grid container item xs={12}>
              <Grid item xs={8} sm={11}>
                <Typography variant="body2">{t('new_company')}</Typography>
              </Grid>

              <Grid
                item
                xs={4}
                sm={1}
                className={classes.close}
                alignItems="flex-end"
              >
                <IconButton
                  classes={{ root: classes.icon }}
                  onClick={onChange}
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid container item xs={12} className={classes.header}>
              &nbsp;
            </Grid>

            <Grid
              container
              xs={12}
              item
              justify="center"
              alignItems="center"
              classes={{ root: classes.controls }}
            >
              <Grid item xs={3}>
                <Typography variant="body1">{t('name')}</Typography>
              </Grid>
              <Grid item xs={9}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={errors.name && errors.name.message ? true : false}
                >
                  <OutlinedInput
                    id="name"
                    name="name"
                    aria-describedby="Company name"
                    inputRef={register({})}
                    inputProps={{
                      disabled: formState.isSubmitting,
                      tabIndex: '1',
                      placeholder: t('name'),
                    }}
                  />
                  <FormHelperText id="add-company-name-helper">
                    {errors.name && errors.name.message}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Divider variant="middle" classes={{ root: classes.divider }} />
            </Grid>

            <Grid
              container
              xs={12}
              item
              justify="center"
              classes={{ root: classes.controls }}
            >
              <Grid
                container
                item
                xs={6}
                justify="center"
                alignItems="flex-start"
              >
                <Typography color="error" variant="body1">
                  {errors.server && errors.server.message}
                </Typography>
              </Grid>

              <Grid item xs={12}></Grid>
              <Grid item xs={6}>
                <Button
                  color="primary"
                  type="submit"
                  tabIndex="4"
                  variant="contained"
                  fullWidth
                  elevation={0}
                  disabled={loading || !formState.isValid}
                >
                  {loading && <CircularProgress size={20} />}
                  &nbsp;
                  {t('create_company')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default CompanyAdd;

CompanyAdd.propTypes = {
  onChange: PropTypes.func.isRequired,
};
