/* eslint-disable react/prop-types */
import React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const SelectComponent = (props) => {
  const { name, options, control, defaultValue, rules, isError } = props;
  const { t } = useTranslation('common');
  // const { t: optionT } = useTranslation('select_' + name);

  const constructOptions = (optionArray) => {
    let arr = [];
    arr.push({ value: '', label: null });
    arr = [
      ...arr,
      ...optionArray.sort((f, s) => f.label.localeCompare(s.label)),
    ];

    return arr;
  };

  const labelId = 'select_' + name;

  return (
    <Controller
      as={({ value, onChange }) => {
        return (
          <FormControl variant="outlined" fullWidth error={isError}>
            <InputLabel id={labelId}>{t('select_from_list')}</InputLabel>
            <Select
              value={value != null ? value : ''}
              fullWidth
              id={labelId}
              onChange={(e) => onChange(e.target.value)}
            >
              {options &&
                constructOptions(options).map((e) => {
                  const key = 'key_' + JSON.stringify(e.value);
                  return (
                    <MenuItem
                      key={key}
                      value={e.value}
                      disabled={e.disabled && true}
                    >
                      {e.label != null ? (
                        e.label
                      ) : (
                        <em>{t('select_from_list')}</em>
                      )}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        );
      }}
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
    />
  );
};

export default SelectComponent;
