import React, { useEffect, useState } from 'react';
import LcimView from '../components/product/LcimView';
import useProduct from '../hooks/useProduct';
import useComponentState from '../hooks/useComponentState';
import { matchPath } from 'react-router';
import PropTypes from 'prop-types';

const PublicFiberPage = (props) => {
  const { history } = props;

  const match = matchPath(history.location.pathname, {
    path: '/public/fiber/:lcimId',
    exact: true,
    strict: false
  });
  // console.log({history});
  const lcimId = match.params.lcimId;
  // console.log({lcimId, match});
  const { getItemByLcimId } = useProduct();
  const {
    // loading,
    setLoading,
    // error,
    setError
  } = useComponentState();
  const [fiberObject, setFiberObject] = useState(null);

  const fetch = () => {
    setLoading(true);
    setFiberObject(null);
    setError(null);
    getItemByLcimId('fiber', lcimId)
      .then((res) => {
        setFiberObject(res);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(fetch, [lcimId]);

  return <>{fiberObject && <LcimView content={fiberObject} />}</>;
};
PublicFiberPage.propTypes = {
  history: {
    location: {
      pathname: PropTypes.string
    }
  }
};

export default PublicFiberPage;
