import api from './api';

const AUTH_ENDPOINT = '/auth';
const AUTH_CURRENT_ENDPOINT = '/auth/current';
const USER_ENDPOINT = '/user';

const useUser = () => {
  /**
   * Create a new user
   *
   * @returns {Promise}
   */
  const createNewUser = (name, email, password, role = null) => {
    const obj = {
      entity: {
        email,
        name,
        password,
        role,
      },
    };
    return api.post(AUTH_ENDPOINT, obj).then((res) => {
      if (!res.data.success) {
        throw res.data.errors;
      }
      return res.data.data;
    });
  };

  /**
   * Create a new company user
   *
   * @returns {Promise}
   */
   const createNewCompanyUser = (name, email, password, invite, role = null) => {
    const obj = {
      entity: {
        email,
        name,
        password,
        invite,
        role,
      },
    };
    return api.post(`${AUTH_ENDPOINT}/company-register`, obj).then((res) => {
      if (!res.data.success) {
        throw res.data.errors;
      }
      return res.data.data;
    });
  };

  /**
   * Get current authenticated user
   */
  const getAuthenticatedUser = () => {
    return api.get(AUTH_CURRENT_ENDPOINT).then((res) => {
      if (!res.data.success) {
        throw res.data.errors;
      }
      return res.data.data;
    });
  };

  /**
   * Update current authenticated user
   *
   * @param {*} name
   * @param {*} email
   */
  const updateAuthenticatedUser = (name, email) => {
    const obj = {
      entity: {
        name,
        email,
      },
    };
    return api.patch(AUTH_CURRENT_ENDPOINT, obj).then((res) => {
      if (!res.data.success) {
        throw res.data.errors;
      }
      return res.data.data;
    });
  };

  /**
   * Get all company users
   */
  const getUsers = () => {
    return api.get(USER_ENDPOINT).then((res) => {
      // console.log('users results', res);
      if (!res.data.success) {
        throw res.data.errors;
      }
      return res.data.data;
    });
  };
  const editUser = (id, payload) => {
    const obj = {
      entity: {
        email: payload.email,
        name: payload.name,
        company: !isNaN(payload.company)
          ? { id: Number(payload.company) }
          : { id: payload.company },
        role: payload.role,
      },
    };
    console.log('edituser', id, payload, obj);
    return api.patch(`${USER_ENDPOINT}/${id}`, obj).then((res) => {
      // console.log('users results', res);
      if (!res.data.success) {
        throw res.data.errors;
      }
      return res.data.data;
    });
  };

  /**
   *  Link a user to logged in user's company entity
   *
   * @param {*} email
   */
  const linkUserToCompany = (email) => {
    return api
      .request({
        url: USER_ENDPOINT + '/' + email + '/company',
        method: 'link',
      })
      .then((res) => {
        if (!res.data.success) {
          throw res.data.errors;
        }
        return res.data.data;
      });
  };

  return {
    getUsers,
    linkUserToCompany,
    createNewUser,
    createNewCompanyUser,
    getAuthenticatedUser,
    updateAuthenticatedUser,
    editUser,
  };
};

export default useUser;
