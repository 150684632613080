import api from './api';

const RESET_PASSWORD_ENDPOINT = '/forgotten-password/reset';

const useResetPassword = () => {
  const resetPassword = (rpt, password) => {
    const obj = {
      entity: {
        resetPasswordToken: {
          token: rpt
        },
        password
      },
    };
    return api.post(`${RESET_PASSWORD_ENDPOINT}`, obj)
      .then((res) => {
        if (!res.data.success) {
          throw res.data.errors;
        }
        return res.data.data;
      })
  }
  return {
    resetPassword
  };
}

export default useResetPassword;