import React from 'react'; // , { useState, useEffect }
// import { useTranslation } from 'react-i18next';
// import ReactMarkdown from 'react-markdown';
import { Container, Grid, Typography, Paper, Icon, Avatar } from '@material-ui/core'; // CircularProgress,
import { makeStyles } from '@material-ui/core/styles';

// import useComponentState from '../../hooks/useComponentState';
// import ErrorComponent from '../../components/ErrorComponent';

// import { isAuthenticated } from '../../hooks/AppContext';

import MainHeader from '../../components/dashboard/MainHeader';

// import useMarkdown from '../../hooks/useMarkdown';

import { useHistory } from 'react-router-dom';
import { useRoutes } from '../../hooks/useRoutes';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    '.line-break': {
      'white-space': 'pre-wrap'
    }
  },
  h1: {
    fontSize: '26px',
    letterSpacing: '1.56px',
  },
  headerBlue: {
    opacity: 1,
  },
  content: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: theme.spacing(0, 3),
  },
  paper: {
    maxWidth: 400,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
}));

export default function MainAdminPage() {
  // const { t } = useTranslation('userlist');

  const classes = useStyles();
  const { PATH } = useRoutes();

  // const { getMarkdown } = useMarkdown();

  // const [needsReload, setReload] = useState(false);
  // const [item, setItem] = useState();
  // const [loggedIn, setLoggedIn] = useState();

  // const { setLoading, setError } = useComponentState(); //  loading, 

  // const fetch = () => {
    // const auth = isAuthenticated();
    // setLoggedIn(auth);
    // setLoading(true);
    // setError(null);
    // getMarkdown("manual")
    //   .then((res) => {
    //     setItem(res);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     setError(err);
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //     setReload(false);
    //   });
  // };

  // useEffect(fetch, [needsReload]);
  const history = useHistory();

  const editManual = () => history.push(PATH.manuals);
  const editGdpr = () => history.push(PATH.gdprs);

  const message = `Truncation should be conditionally applicable on this long line of text as this is a much longer line than what the container can support. `;

  return (
    <div className={classes.root}>
      <MainHeader/>
      <Container className={classes.content}>
        <Paper className={classes.paper} style={{width: '250px'}} onClick={editGdpr}>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item>
              <Avatar>
                <Icon color="primary">{'gavel'}</Icon>
              </Avatar>
            </Grid>
            <Grid item xs style={{alignSelf:"center"}}>
              <Typography variant="h1" component="h2" style={{textAlign: 'center'}}>{'Edit gdpr'}</Typography>
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.paper} style={{width: '250px'}} onClick={editManual}>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item>
              <Avatar>
                <Icon color="primary">{'menu_book'}</Icon>
              </Avatar>
            </Grid>
            <Grid item xs style={{alignSelf:"center"}}>
              <Typography variant="h1" component="h2" style={{textAlign: 'center'}}>{'Edit manual'}</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
}
