/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable react/prop-types */
import React from 'react';
import { TableContainer, Table, TableBody } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DataRow from './DataRow';
import { getFieldName } from '../hooks/utils';
import {
  MATERIAL_COLOR,
  MATERIAL_PATTERN,
  MATERIAL_CERTIFICATION,
  MATERIAL_CONSTRUCTION,
  MATERIAL_DYEMETHOD,
  MATERIAL_PRINTMETHOD,
  PRODUCT_FINISHING,
} from './constants';
import { required, weight } from './editorRules';

const MaterialView = (props) => {
  const { prefix, watch, companies } = props;
  const { t } = useTranslation('material');
  const { t: selectT } = useTranslation('options');

  const companiesOptions = companies
    ? companies.map((e) => {
        return {
          value: e.id,
          label: e.name,
        };
      })
    : [];
  const colorOptions = MATERIAL_COLOR.map((e) => ({
    value: e,
    label: selectT('material_color.' + e),
  }));
  const constructionOptions = MATERIAL_CONSTRUCTION.map((e) => ({
    value: e,
    label: selectT('material_construction.' + e),
  }));
  const certificationOptions = MATERIAL_CERTIFICATION.map((e) => ({
    value: e,
    label: selectT('material_certification.' + e),
  }));
  const patternOptions = MATERIAL_PATTERN.map((e) => ({
    value: e,
    label: selectT('material_pattern.' + e),
  }));
  const dyeMethodOptions = MATERIAL_DYEMETHOD.map((e) => ({
    value: e,
    label: selectT('material_dyemethod.' + e),
  }));
  const printMethodOptions = MATERIAL_PRINTMETHOD.map((e) => ({
    value: e,
    label: selectT('material_printmethod.' + e),
  }));

  const finishingOptions = PRODUCT_FINISHING.map((e) => ({
    value: e,
    label: selectT('product_finishing.' + e),
  }));

  const isDyedSelected = watch(getFieldName(prefix, 'dyed'));
  const isPrintedSelected = watch(getFieldName(prefix, 'printed'));
  return (
    <>
      <DataRow type={'hidden'} name={'materialId'} dataRowProps={props} />
      <TableContainer>
        <Table>
          <TableBody>
            <DataRow
              type={'text'}
              name={'name'}
              label={t('name')}
              bigtext
              dataRowProps={props}
              rules={required}
            />
            <DataRow
              type={'text'}
              name={'tradename'}
              label={t('tradename')}
              dataRowProps={props}
            />
            <DataRow
              type={'select'}
              name={'companyId'}
              label={t('company')}
              options={companiesOptions}
              dataRowProps={props}
              rules={required}
            />
            <DataRow
              type={'number'}
              name={'weight'}
              label={t('weight')}
              dataRowProps={props}
              inputaddon={t('weight_unit')}
              rules={weight}
            />
            <DataRow
              type={'text'}
              name={'description'}
              label={t('description')}
              dataRowProps={props}
            />
            <DataRow
              type={'select'}
              name={'construction'}
              label={t('construction')}
              options={constructionOptions}
              dataRowProps={props}
            />
            <DataRow
              type={'selectmultiple'}
              name={'color'}
              label={t('color')}
              options={colorOptions}
              dataRowProps={props}
              rules={required}
            />
            <DataRow
              type={'text'}
              name={'colorCode'}
              label={t('colorCode')}
              dataRowProps={props}
              tooltip={'Pantone-code'}
            />
            <DataRow
              type={'select'}
              name={'pattern'}
              label={t('pattern')}
              options={patternOptions}
              dataRowProps={props}
            />
            <DataRow
              type={'boolean'}
              name={'dyed'}
              label={t('dyed')}
              dataRowProps={props}
            />
            {isDyedSelected && (
              <>
                <DataRow
                  type={'select'}
                  name={'dyeingMethod'}
                  label={t('dyeingMethod')}
                  options={dyeMethodOptions}
                  dataRowProps={props}
                />
                <DataRow
                  type={'text'}
                  name={'dyestuff'}
                  label={t('dyestuff')}
                  dataRowProps={props}
                />
              </>
            )}
            <DataRow
              type={'boolean'}
              name={'printed'}
              label={t('printed')}
              dataRowProps={props}
            />
            {isPrintedSelected === true ? (
              <>
                <DataRow
                  type={'select'}
                  name={'printingMethod'}
                  label={t('printingMethod')}
                  options={printMethodOptions}
                  dataRowProps={props}
                />
                <DataRow
                  type={'text'}
                  name={'printDyestuff'}
                  label={t('printDyestuff')}
                  dataRowProps={props}
                />
              </>
            ) : (
              false
            )}
            <DataRow
              type={'selectmultiple'}
              name={'finishing'}
              label={t('finishing')}
              options={finishingOptions}
              dataRowProps={props}
            />
            <DataRow
              type={'selectmultiple'}
              name={'certification'}
              label={t('certification')}
              options={certificationOptions}
              rules={required}
              dataRowProps={props}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default MaterialView;
