/* eslint-disable react/prop-types */
import React from 'react';
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { Observer } from 'mobx-react-lite';

const ControllerHeader = (props) => {
  const { onAddButtonClick, title, state = {} } = props;
  return (
    <Grid container alignItems="center">
      <Grid item xs={11}>
        <Typography variant="h2">{title}</Typography>
      </Grid>
      <Grid item xs={1}>
        <Button onClick={onAddButtonClick}>
          <Grid container alignItems="center" justify="center">
            <Typography variant="h2">+</Typography>
          </Grid>

          <Observer>
            {() => <>{state.loading && <CircularProgress />}</>}
          </Observer>
        </Button>
      </Grid>
    </Grid>
  );
};

export default ControllerHeader;
