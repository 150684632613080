import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Card, CardContent, CircularProgress, Container, Grid, Typography, Avatar, Icon, Paper, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import useComponentState from '../../hooks/useComponentState';
import ErrorComponent from '../../components/ErrorComponent';

import { isAuthenticated, isRole } from '../../hooks/AppContext';

import useMarkdown from '../../hooks/useMarkdown';
import MainHeader from '../../components/dashboard/MainHeader';

import { useHistory } from 'react-router-dom';
import { useRoutes } from '../../hooks/useRoutes';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    '.line-break': {
      'white-space': 'pre-wrap'
    }
  },
  container: {
    marginTop: '10em',
    top: '5em',
    width: '23vw',
    minWidth: '350px',
  },
  h1: {
    fontSize: '26px',
    letterSpacing: '1.56px',
  },
  headerBlue: {
    opacity: 1,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  content: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: theme.spacing(0, 3),
  },
  paper: {
    maxWidth: 400,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
}));

export default function ManualsAdminPage() {
  const { getMarkdown, updateMarkdownAvailability } = useMarkdown();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('manuals');

  const classes = useStyles();

  const [needsReload, setReload] = useState(false);
  const [item, setItem] = useState();
  const [loggedIn, setLoggedIn] = useState();

  const { setLoading, loading, setError, error } = useComponentState();

  const { PATH } = useRoutes();
  const history = useHistory();

  const createManual = () => {
    history.push(`/create-manual/`);
  };

  const viewManual = (payload) => {
    history.push(`/view-manual/${payload.id.toString()}`, payload);
  };

  const setManualState = (payload, availability) => {
    setLoading(true);
    updateMarkdownAvailability(payload.id, availability)
      .then(() => {
        enqueueSnackbar(t('update_success'), { variant: 'success' });
        if (availability === 'deleted') {
          setItem(item.filter((it) => it.id !== payload.id));
        } else {
          setItem(item.map((it) => it.id === payload.id ? { ...it, availability } : it));
        }
      }).catch(() => {
        enqueueSnackbar(t('update_failed'), { variant: 'error' });
      }).finally(() => setLoading(false))
  }

  const fetch = () => {
    setLoggedIn(isAuthenticated());
    setLoading(true);
    setError(null);
    getMarkdown("manual")
      .then((res) => {
        console.log({res});
        setItem(res);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
        setReload(false);
      });
  };

  useEffect(fetch, [needsReload]);

  const currentAvailabilityLabel = (manual, chip) => {
    return manual.availability === chip ? 'primary' : 'default';
  }

  const createList = (items) => {
    items.sort((a,b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    let arr = [];
    for (const item of items) {
      arr.push(
        <Paper key={item.id} className={classes.paper} variant="outlined" >
          <Grid container spacing={2}>
            <Grid item xs style={{alignSelf:"center"}}>
              <Typography variant="h1" component="h2" style={{textAlign: 'center'}}>{item.id + ': ' + 'Manual'}</Typography>
              <Typography style={{textAlign: 'center'}}>{'created: ' + item.createdAt}</Typography>
              <Typography style={{textAlign: 'center'}}>{'updated: ' + item.updatedAt}</Typography>
            </Grid>
            <Grid item>
              <Chip className={classes.chip} color={currentAvailabilityLabel(item, 'hidden')} label="hidden" onClick={() => setManualState(item, 'hidden')}/>
              <Chip className={classes.chip} color={currentAvailabilityLabel(item, 'public-internal')} label="public-internal" onClick={() => setManualState(item, 'public-internal')}/>
              <Chip className={classes.chip} color={currentAvailabilityLabel(item, 'public-external')} label="public-external" onClick={() => setManualState(item, 'public-external')}/>
              <Chip icon={<Icon>{'delete'}</Icon>} color={currentAvailabilityLabel(item, 'deleted')} className={classes.chip} label="delete" onClick={() => setManualState(item, 'deleted')}/>
              <Chip icon={<Icon>{'pageview'}</Icon>} className={classes.chip} label="view" onClick={() => viewManual(item)}/>
            </Grid>
          </Grid>
        </Paper>
      )
    }
    return arr;
  }

  return (
    <>
      {loggedIn && (<MainHeader/>)}
      <Container classes={{ root: classes.container }}>
        <Card elevation={0}>
          <CardContent>
            <Grid container spacing={6} justify="center">
              <Grid
                container
                item
                xs={12}
                justify="flex-start"
                alignItems="center">
                {loading && (
                  <>
                    <Grid container justify="center">
                      <CircularProgress />
                    </Grid>
                  </>
                )}
                {item && (
                  <>
                    <Chip icon={<Icon>{'edit'}</Icon>} className={classes.chip} label="Create new" onClick={createManual}/>
                    {createList(item)}
                    {/*<ReactMarkdown className="line-break">
                      {item.content}
                    </ReactMarkdown>*/}
                  </>
                )}
                {error && <ErrorComponent open={error} error={error.message} />}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}
