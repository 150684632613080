/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable react/prop-types */
import React, { useEffect/*, useState*/ } from 'react';
import { TableContainer, Table, TableBody } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DataRow from './DataRow';
// import useCompany from '../hooks/useCompany';
import { getFieldName } from '../hooks/utils';
import { STEP_TYPE } from './constants';
import { required } from './editorRules';

const StepView = (props) => {
  const {
    prefix,
    watch,
    companies,
    trigger,
    locations = [],
    onCompanyIdChange = () => false,
  } = props;
  const { t } = useTranslation('step');
  const { t: selectT } = useTranslation('options');

  // const { getCompanyById } = useCompany();
  const companyIdValue = watch(getFieldName(prefix, 'companyId'));

  const companiesOptions = companies
    ? companies.map((e) => {
        return {
          value: e.id,
          label: e.name,
        };
      })
    : [];

  useEffect(() => {
    onCompanyIdChange(companyIdValue);
    trigger('locationId');
  }, [companyIdValue, trigger]);

  const stepOptions = STEP_TYPE.map((e) => ({
    value: e,
    label: selectT('step_type.' + e),
  }));

  const locationOptions = locations.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  return (
    <>
      <TableContainer>
        <Table>
          <TableBody>
            <DataRow type={'hidden'} name={'stepId'} dataRowProps={props} />
            <DataRow
              type={'select'}
              name={'type'}
              label={t('type')}
              rules={required}
              options={stepOptions}
              dataRowProps={props}
            />
            <DataRow
              type={'select'}
              name={'companyId'}
              label={t('company')}
              options={companiesOptions}
              dataRowProps={props}
              rules={required}
            />
            {locationOptions && locationOptions.length ? (
              <>
                <DataRow
                  type={'select'}
                  name={'locationId'}
                  label={t('location')}
                  options={locationOptions}
                  dataRowProps={props}
                  rules={required}
                />
              </>
            ) : (
              false
            )}

            <DataRow
              type={'date'}
              name={'date'}
              label={t('date')}
              rules={required}
              dataRowProps={props}
            />
            <DataRow
              type={'text'}
              name={'meansOfProducedEnergy'}
              label={t('meansOfProducedEnergy')}
              dataRowProps={props}
            />
            <DataRow
              type={'number'}
              name={'energyConsumption'}
              tooltip={t('ttipPwrConsumption')}
              label={t('energyConsumption')}
              dataRowProps={props}
              inputaddon={t('energyUnit')}
            />
            <DataRow
              type={'number'}
              name={'waterConsumption'}
              tooltip={t('ttipWaterConsumption')}
              label={t('waterConsumption')}
              dataRowProps={props}
              inputaddon={t('waterUnit')}
            />
            <DataRow
              type={'text'}
              name={'wasteWaterTreatment'}
              label={t('wasteWaterTreatment')}
              dataRowProps={props}
            />
            <DataRow
              type={'number'}
              name={'co2Emissions'}
              label={t('co2Emissions')}
              dataRowProps={props}
              inputaddon={t('co2Unit')}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default StepView;
