import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { Card, CardContent, CircularProgress, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import useComponentState from '../hooks/useComponentState';
import ErrorComponent from '../components/ErrorComponent';

import { isAuthenticated, isRole } from '../hooks/AppContext';

import useMarkdown from '../hooks/useMarkdown';
import MainHeader from '../components/dashboard/MainHeader';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    '.line-break': {
      'white-space': 'pre-wrap'
    }
  },
  container: {
    marginTop: '10em',
    top: '5em',
    width: '23vw',
    minWidth: '350px',
  },
  h1: {
    fontSize: '26px',
    letterSpacing: '1.56px',
  },
  headerBlue: {
    opacity: 1,
  }
}));

export default function ManualPage() {
  const { t } = useTranslation('userlist');
  const { getMarkdown } = useMarkdown();

  const classes = useStyles();

  const [needsReload, setReload] = useState(false);
  const [item, setItem] = useState();
  const [loggedIn, setLoggedIn] = useState();

  const { setLoading, loading, setError, error } = useComponentState();

  const isAdmin = isRole('SuperAdmin');

  const fetch = () => {
    setLoggedIn(isAuthenticated());
    setLoading(true);
    setError(null);
    getMarkdown("manual")
      .then((res) => {
        if (isAdmin) {
          const filtered = res.filter((it) => it.availability === 'public-internal' || it.availability === 'public-external');
          const timestamp = new Date(Math.max(...filtered.map(e => new Date(e.updatedAt)))).toISOString();
          setItem(filtered.find((it) => it.updatedAt === timestamp));
        } else {
          setItem(res);
        }
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
        setReload(false);
      });
  };

  useEffect(fetch, [needsReload]);
  return (
    <>
      {loggedIn && (<MainHeader/>)}
      <Container classes={{ root: classes.container }}>
        <Card elevation={0}>
          <CardContent>
            <Grid container spacing={6} justify="center">
              <Grid
                container
                item
                xs={12}
                alignContent="center"
                justify="center">
                <Typography
                  variant="h1"
                  classes={{ root: classes.h1 }}
                  align="center"
                  display="block">
                  {t('FINIX')}
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={12}
                justify="flex-start"
                alignItems="center">
                {loading && (
                  <>
                    <Grid container justify="center">
                      <CircularProgress />
                    </Grid>
                  </>
                )}
                {item && (
                  <>
                    <ReactMarkdown className="line-break">
                      {item.content}
                    </ReactMarkdown>
                    {isAdmin && ('admin manual view missing')}
                  </>
                )}
                {error && <ErrorComponent open={error} error={error.message} />}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}
