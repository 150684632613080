import React, { Suspense } from 'react';
import { CssBaseline } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { BrowserRouter } from 'react-router-dom';
import I18n from './locales/i18n';
import { theme } from './hooks/theme/useAppTheme';
import { RenderRoutes } from './hooks/useAppRoutes';
import { routes } from './hooks/useRoutes';
import { SnackbarProvider } from 'notistack';

const Providers = ({ children }) => {
  // const classes = useStyles();
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </BrowserRouter>
  );
};

const App = () => {
  return (
    <Suspense fallback="loading">
      <Providers>
        <CssBaseline />
        <I18n />
        <SnackbarProvider maxSnack={3}>
          <RenderRoutes routes={routes} />
        </SnackbarProvider>
      </Providers>
    </Suspense>
  );
};

export default App;
