import React from 'react';
import { useHistory } from 'react-router-dom';

import { useRoutes } from '../hooks/useRoutes';
import ForgotPassword from '../components/login/ForgotPassword';

export default function ForgotPasswordPage() {
  const { PATH } = useRoutes();
  const history = useHistory();
  const onForgotPasswordSuccess = (payload) => {
    console.log({payload});
    history.push(PATH.login);
  };
  return (
    <>
      <ForgotPassword onForgotPasswordSuccess={onForgotPasswordSuccess} />
    </>
  );
}