import api from './api';

const MARKDOWN_ENDPOINT = '/markdown';

/**
 * @typedef MARKDOWN
 * @property {string} content
 * @property {string} category
 * @property {number} date
 */

const useMarkdown = () => {
  /**
   * Get latest markdown in category
   * @param {"en"} language
   * @returns {Promise}
   */
  const getMarkdown = async (category="", language="en") => {
    return api.get(
      `${MARKDOWN_ENDPOINT}/${category}`,
      {
        params: {
          lang: language,
          filter: "latest"
        }
      }).then((res) => {
        console.log({res});
        if (!res.data.success) {
          throw res.data.errors;
        }
        return res.data.data;
      });
  };

  /**
   * Post new markdown
   */
  const createMarkdown = async (category, content, availability='hidden') => {
    return api.post(
      `${MARKDOWN_ENDPOINT}/`,
      { category, content, availability }
    ).then((res) => {
      if (!res.data.success) {
        throw res.data.errors;
      } else {
        return res.data.data;
      }
    })
  }

  /**
   * PUT update availability
   * @param {number} id
   * @param {'hidden'|'public-external'|'public-internal'}
   */
  const updateMarkdownAvailability = async (id, availability) => {
    return api.put(
      `${MARKDOWN_ENDPOINT}/`,
      { id, availability }
    ).then((res) => !res.data.success ? new Error(res.data.errors) : res.data.data)
  }

  return {
    getMarkdown,
    createMarkdown,
    updateMarkdownAvailability
  };
};

export default useMarkdown;
