import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Chip,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
// import { unset } from 'lodash';
// import PropTypes from 'prop-types';

const MuiAccordion = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(Accordion);

const MuiAccordionSummary = withStyles({
  root: {
    justifyContent: "flex-start",
    minHeight: "unset",
    padding: "0",
    flexFlow: "row",
    '&$expanded': {
      minHeight: "unset",
    },
  },
  content: {
    flexGrow: "unset",
    margin: '0',
    '&$expanded': {
      margin: '0',
      minHeight: "unset",
      padding: "0"
    },
  },
  expandIcon: {
    padding: "0 16px",
    marginLeft: "-16px",
  },
  expanded: {},
})(AccordionSummary);

const MuiAccordionDetails = withStyles((theme) => ({
  root: {
    padding: "0 16px",
    // padding: theme.spacing(2),
  },
}))(AccordionDetails);

const useStyles = makeStyles(() => ({
  root: {
    // marginBottom: theme.spacing(1),
    // '&$expanded': {
    //   marginBottom: theme.spacing(1),
    //   '&:last-child': {
    //     marginBottom: theme.spacing(1),
    //   },
    // },
    margin: 'unset',
    '&$expanded': {
      margin: 'unset'
    }
  },
  summary: {
    margin: 'unset',
    '&$expanded': {
      margin: 'unset'
    }
  },
  expanded: {},
  // summary_content: {
  // 	margin: 'unset',
  // 	'&$expanded': {
  // 		margin: 'unset'
  // 	},
  // },
  details: {
    // border: '1px solid red',
    paddingBottom: 0
  },
  typo: {
    fontSize: 16
  }
}));

const Chips = (props) => {
  const { chips } = props;
  const renderChips = [];
  for (const chip of chips) {
    renderChips.push(<Chip size="small" label={chip}></Chip>)
  }
  return renderChips;
}

/**
 * Create column of items
 * @param {*} item
 * @param {string[]} order
 * @returns
 */
const LcimItemDisplay = (props) => {
  const { t } = useTranslation(['lcimview']);
  const { item, order = [] } = props;

  console.log({ item });
  let itemColumn = [];

  const classes = useStyles();

  if (item) {
    for (const [key, value] of Object.entries(item)) {
      itemColumn.push({ key, value });
    }
  }

  let orderedItems = [];
  // set name first
  orderedItems.push(
    itemColumn[itemColumn.findIndex((item) => item.key === 'name')]
  );
  // set order list here
  for (let i = 0; i < order.length; i++) {
    const itemIndex = itemColumn.findIndex((item) => item.key === order[i]);
    if (itemIndex >= 0) {
      orderedItems.push(itemColumn[itemIndex]);
    }
  }

  const isNastyArr = (_str) => {
    try {
      const _arr = JSON.parse(_str);
      return Array.isArray(_arr);
    } catch (e) {
      return false;
    }
  }

  let renderItems = [];
  for (const oneProp of itemColumn) {
    if (typeof oneProp.value === 'string') {
      if (isNastyArr(oneProp.value)) {
        const _arr = JSON.parse(oneProp.value).filter((a) => a !== "");
        renderItems.push(
          <>
            <MuiAccordion
              classes={{ root: classes.root }}
              defaultExpanded={false}
            >
              <MuiAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                classes={{
                  content: classes.summary,
                  expanded: classes.expanded
                }}>
                <Typography className={classes.typo}>
                  {t(oneProp.key)}:
                </Typography>
              </MuiAccordionSummary>
              
              <MuiAccordionDetails style={{ flexFlow: 'column'}}>
                {_arr.map((a) => {
                  return <Typography className={classes.typo}>- {a}</Typography>
                })}
              </MuiAccordionDetails>
            </MuiAccordion>
          </>
        )
      } else if (moment(oneProp.value, moment.ISO_8601, true).isValid()) {
        renderItems.push(<Typography className={classes.typo}>{t(oneProp.key)}: {moment(new Date(oneProp.value)).format('d.M.YYYY, HH:mm')}</Typography>)
      } else {
        renderItems.push(
          <Typography className={classes.typo}>
            {t(oneProp.key)}: {oneProp.value}
          </Typography>
        );
      }
    } else if (typeof oneProp.value === 'number') {
      renderItems.push(
        <Typography className={classes.typo}>
          {t(oneProp.key)}: {oneProp.value.toString()}
        </Typography>
      );
    } else if (typeof oneProp.value === 'boolean') {
      renderItems.push(
        <Typography className={classes.typo}>
          {t(oneProp.key)}: {oneProp.value ? "Yes" : "No"}
        </Typography>
      );
    } else if (Array.isArray(oneProp.value)) {
      // might have nested objects (recursive required)
      if (oneProp.value.every((_item) => typeof _item === 'object')) { // array of objects
        const arrRenderItems = [];
        for (const [i, subItem] of oneProp.value.entries()) {
          console.log({ subItem, type: typeof subItem, i });
          const subHTML = (<LcimItemDisplay item={subItem} />);
          arrRenderItems.push(
            <MuiAccordion square={true} elevation={0} classes={{ root: classes.root }} defaultExpanded={false}>
              <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} classes={{ content: classes.summary, expanded: classes.expanded }}>
                <Typography className={classes.typo}>- {subItem.name ? subItem.name : `${t(oneProp.key.slice(0, -1))} ${i + 1}`}</Typography>
              </MuiAccordionSummary>
              <MuiAccordionDetails>
                <Grid container justifyContent="flex-start">
                  <Grid item xs={12}>
                    {subHTML}
                  </Grid>
                </Grid>
              </MuiAccordionDetails>
            </MuiAccordion>
          );
        }
        renderItems.push(
          <MuiAccordion square={true} elevation={0} classes={{ root: classes.root }} defaultExpanded={false}>
            <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} classes={{ content: classes.summary, expanded: classes.expanded }}>
              <Typography className={classes.typo}>{t(oneProp.key)}: </Typography>
            </MuiAccordionSummary>
            <MuiAccordionDetails>
              <Grid container justifyContent="flex-start">
                <Grid item xs={12}>
                  {arrRenderItems}
                </Grid>
              </Grid>
            </MuiAccordionDetails>
          </MuiAccordion>
        );
      } else {
        renderItems.push(
          <p>
            {t(oneProp.key)}: [{oneProp.value.length}]
          </p>
        );
      }
    } else if (typeof oneProp.value === 'object') {
      if (oneProp.value === null) {
        renderItems.push(<Typography className={classes.typo}>{t(oneProp.key)}: empty</Typography>);
      } else {
        renderItems.push(
          <MuiAccordion square={true} elevation={0} classes={{ root: classes.root }} defaultExpanded={false}>
            <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} classes={{ content: classes.summary, expanded: classes.expanded }}>
              <Typography className={classes.typo}>{t(oneProp.key)}:</Typography>
            </MuiAccordionSummary>
            <MuiAccordionDetails>
              <Grid container justifyContent="flex-start">
                <Grid item xs={12}>
                  <LcimItemDisplay item={oneProp.value} />
                </Grid>
              </Grid>
            </MuiAccordionDetails>
          </MuiAccordion>
        );
      }
    } else if (typeof oneProp.value === 'undefined') {
      renderItems.push(<Typography className={classes.typo}>{t(oneProp.key)}: &apos;undefined&apos;</Typography>);
    } else {
      renderItems.push(<Typography className={classes.typo}>{t(oneProp.key)}: unknown type</Typography>);
    }
  }
  return renderItems;
};

export default LcimItemDisplay;
