/* eslint-disable react/prop-types */
import React from 'react';
import { TableContainer, Table, TableBody } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DataRow from './DataRow';
import { required } from './editorRules';

const AssemblyView = (props) => {
  const { t } = useTranslation('assembly');
  return (
    <>
      <TableContainer>
        <Table>
          <TableBody>
            <DataRow type={'hidden'} name={'assemblyId'} dataRowProps={props} />
            <DataRow
              type={'text'}
              name={'name'}
              label={t('name')}
              dataRowProps={props}
              rules={required}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AssemblyView;
