import React, { useState } from 'react';
import {
  Typography,
  Container,
  Grid,
  Card,
  Button,
  CardContent,
  FormControl,
  OutlinedInput,
  FormHelperText,
  CircularProgress,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import useUser from '../../hooks/useUser';
import ErrorComponent from '../ErrorComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  container: {
    marginTop: '10em',
    top: '5em',
    width: '23vw',
    minWidth: '350px',
  },
  h1: {
    fontSize: '26px',
    letterSpacing: '1.56px',
  },
  headerBlue: {
    opacity: 1,
  },
}));

const Register = (props) => {
  const { createNewUser } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['login', 'common']);
  const {
    handleSubmit,
    register,
    errors,
    getValues,
    formState,
    reset,
    watch,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const { onRegisterSuccess } = props;
  const classes = useStyles();
  const loginPwd = watch('loginPwd');
  const [serverError/*, setServerError*/] = useState(null);

  const updateErr = (a) => {
    console.log('e', a.message);
    // setError('loginPwd', { type: 'manual', message: t('err_pwd_invalid') });
    // setError('loginUser', { type: 'manual', message: t('err_pwd_invalid') });
  };

  const serverErrorHandler = (error) => {
    enqueueSnackbar(error.message, {
      variant: 'error',
    });
    let vals = getValues();
    reset(vals);
  };

  const _final = (a) => {
    if (Array.isArray(a) || a.message)
      return serverErrorHandler({ message: a.join(', ') || a.message });
    try {
      enqueueSnackbar(t('register_success') + `: ${a.email}`, {
        variant: 'success',
      });
      onRegisterSuccess(a);
    } catch (error) {
      console.log(error);
    }
  };

  const _validate = async (p) => {
    return createNewUser('', p.loginUser, p.loginPwd)
      .then(_final)
      .catch(_final);
  };

  // _validate(watch('loginUser',''));
  // update(watch('loginPwd',''));
  return (
    <>
      <Container classes={{ root: classes.container }}>
        <Card elevation={0}>
          <CardContent>
            <form
              name="loginForm"
              onSubmit={handleSubmit(_validate, updateErr)}
            >
              <Grid container spacing={6} justify="center">
                <Grid
                  container
                  item
                  xs={12}
                  alignContent="center"
                  justify="center"
                >
                  <Typography
                    variant="h1"
                    classes={{ root: classes.h1 }}
                    align="center"
                    display="block"
                  >
                    {'FINIX'}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  justify="flex-start"
                  alignItems="center"
                >
                  <Typography paragraph variant="body2">
                    {t('register')}
                  </Typography>
                  {/* <Grid item xs={1}>
                    <AccountCircle fontSize="large" />
                  </Grid>
                  <Grid item xs={11}> */}
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={
                      errors.loginUser && errors.loginUser.message
                        ? true
                        : false
                    }
                  >
                    <OutlinedInput
                      id="loginUser"
                      name="loginUser"
                      autoComplete="email"
                      aria-describedby="User email"
                      inputRef={register({
                        required: t('err_email_req'),
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: t('err_email_invalid'),
                        },
                      })}
                      inputProps={{
                        disabled: formState.isSubmitting,
                        tabIndex: '1',
                        placeholder: t('email'),
                      }}
                    />
                    <FormHelperText id="login-user-helper">
                      {errors.loginUser && errors.loginUser.message}
                    </FormHelperText>
                  </FormControl>
                  {/* </Grid> */}
                  <FormControl
                    fullWidth
                    variant="outlined"
                    error={
                      errors.loginPwd && errors.loginPwd.message ? true : false
                    }
                  >
                    <OutlinedInput
                      name="loginPwd"
                      type="password"
                      role="password"
                      autoComplete="current-password"
                      inputRef={register({
                        minLength: {
                          value: 8,
                          message: t('err_pwd_min_length'),
                        },
                        required: t('err_pwd_invalid'),
                      })}
                      inputProps={{
                        disabled: formState.isSubmitting,
                        tabIndex: '2',
                        placeholder: t('pwd'),
                        'aria-placeholder': 'Password',
                        'aria-required': 'true',
                      }}
                    />
                    <FormHelperText id="login-pwd-helper">
                      {errors.loginPwd && errors.loginPwd.message}
                    </FormHelperText>
                  </FormControl>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    error={
                      errors.loginPwd_repeat &&
                      errors.loginPwd_repeat.message &&
                      true
                    }
                  >
                    <OutlinedInput
                      name="loginPwd_repeat"
                      type="password"
                      role="password"
                      autoComplete="current-password"
                      inputRef={register({
                        validate: (value) =>
                          value === loginPwd || t('err_pwd_repeat_invalid'),
                      })}
                      inputProps={{
                        disabled: formState.isSubmitting,
                        tabIndex: '2',
                        placeholder: t('pwd_repeat'),
                        'aria-placeholder': 'Password',
                        'aria-required': 'true',
                      }}
                    />
                    <FormHelperText id="login-pwd-helper">
                      {errors.loginPwd_repeat && errors.loginPwd_repeat.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  justify="center"
                  alignItems="center"
                >
                  <Grid item xs={8}>
                    <Button
                      type="submit"
                      tabIndex="3"
                      variant="contained"
                      fullWidth
                      color="primary"
                      elevation={0}
                      disabled={
                        errors.loginUser ||
                        errors.loginPwd ||
                        errors.loginPwd_repeat ||
                        formState.isSubmitting
                          ? true
                          : false
                      }
                    >
                      {formState.isSubmitting && <CircularProgress size={20} />}
                      &nbsp;
                      {t('register')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
        {serverError && <ErrorComponent error={serverError}></ErrorComponent>}
      </Container>
    </>
  );
};

export default Register;

Register.propTypes = {
  onRegisterSuccess: PropTypes.func.isRequired,
};
