/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { Box, Typography, TextareaAutosize, Grid, Chip, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import MainHeader from '../../components/dashboard/MainHeader';
import useComponentState from '../../hooks/useComponentState';
import useMarkdown from '../../hooks/useMarkdown';
import { useHistory } from 'react-router-dom';
import { useRoutes } from '../../hooks/useRoutes';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    maxWidth: '90%',
    '.line-break': {
      'white-space': 'pre-wrap'
    }
  },
  container: {
    marginTop: '10em',
    top: '5em',
    width: '23vw',
    minWidth: '350px',
  },
  page: {
    width: '100%',
    marginTop: '2.5em',
  },
  cont: {
    paddingLeft: '4em',
    paddingTop: '0.55em',
    paddingBottom: '0.55em',
    marginRight: '4em',
  },
}));

const ViewManualAdminPage = (props) => {
  const { getMarkdown, updateMarkdownAvailability } = useMarkdown();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('view_manual');
  const classes = useStyles();
  const [needsReload, setReload] = useState(false);
  const [item, setItem ] = useState();
  const [loggedIn, setLoggedIn] = useState();
  const [preview, setPreview] = useState();

  const { match } = props;
  const manual = props.location.state;
  const manualId = match.params.manualId;

  const history = useHistory();

  const { setLoading, loading, setError, error } = useComponentState();

  const fetch = () => {
    setLoading(true);
    if (!manual) {
      setError(null);
      getMarkdown("manual")
      .then((res) => {
        console.log({res});
        setItem(res.find((one) => one.id === manualId));
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
        setReload(false);
      });
    } else { // Updates to GUI.
      if (item) {
        setPreview(item.content);
      } else {
        setItem(manual);
        setPreview(manual.content);
      }
      setLoading(false);
      setReload(false);
    }
  };

  const updateMdContent = (value) => {
    item.content = value;
    setItem(item);
    setPreview(item.content);
  }

  const saveManual = async (latest) => {
    console.log({latest});
    setLoading(true);
    updateMarkdownAvailability(latest.category, latest.content, latest.availabitily)
    .then(() => {
      enqueueSnackbar(t('save_success'), { variant: 'success' });
      history.push(`/manuals`);
    }).catch(() => {
      enqueueSnackbar(t('save_failed'), { variant: 'error' });
    }).finally(() => setLoading(false))
  };

  useEffect(fetch, [needsReload]);

  // const customRenderers = { // react-markdown accepts custom renderers
  //   image: ({ alt, src, title }) => (
  //     <img alt={alt} src={src} title={title} style={{ maxWidth: 475 }}  />
  //   )
  // };

  return (
    <>
      <MainHeader item={item} />
      <Box classes={{ root: classes.cont }}>
        <Box classes={{ root: classes.page }}>
          <Typography variant="h2">{t('view_manual')}</Typography>
          <br/>
          {item && (
            <Grid container spacing={6} justify="center">
              <Grid container item xs={12} justify="flex-start" alignItems="center" flexFlow="row">
                <div style={{width: '100%'}}>
                  <Typography variant="h1" component="h2">Category: {item.category}</Typography>
                  <Typography variant="h1" component="h2">ID: {item.id ? item.id : 'to be created'}</Typography>
                  <Typography variant="h1" component="h2">Author: {item.author}</Typography>
                  <Typography variant="h1" component="h2">Created: {'to be created'}</Typography>
                  <Typography variant="h1" component="h2">Updated: {'to be created'}</Typography>
                  <br/>
                  <div style={{display:'flex', flexFlow:'row'}}>
                    <div style={{minWidth: '40%', marginRight: '8px'}}>
                      <div style={{display: 'flex', flexFlow: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '8px 0'}}>
                        <Typography variant="h1" component="h2">Raw view: </Typography>
                      </div>
                      <TextareaAutosize
                        aria-label="maximum height"
                        defaultValue={item.content}
                        style={{minWidth: '100%', height: '700px'}}
                        onInput={event => updateMdContent(event.target.value)}>
                      </TextareaAutosize>
                    </div>
                    <ReactMarkdown className="line-break" children={preview} style={{maxWidth: '60%', marginLeft: '8px'}}/>
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
          <Box m={24} />
        </Box>
      </Box>
    </>
  );
};

export default ViewManualAdminPage;
