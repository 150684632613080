/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import { Button, Grid, Typography } from '@material-ui/core';
import React from 'react';

const ItemHeader = (props) => {
  const { title, onDelete, onCreate, createLabel, onAssignTo, count } = props;
  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={8}>
          <Typography
            variant="body2"
            // classes={{ root: classes.heading }}
          >
            {title}
            &nbsp;&nbsp;
            {count && `(${count})`}
          </Typography>
        </Grid>
        <Grid item xs={4} container justify="flex-end">
          {onAssignTo != null && (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                onAssignTo();
              }}
            >
              Assign to
            </Button>
          )}
          {onCreate != null && (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                onCreate();
              }}
            >
              {createLabel}
            </Button>
          )}
          {onDelete && <Button onClick={() => onDelete()}>Remove</Button>}
        </Grid>
      </Grid>
    </>
  );
};

export default ItemHeader;
