// validation rules

export const required = {
  required: { value: true, message: 'required' },
};

export const weight = {
  min: {
    value: 0,
    message: 'minimum_value_zero',
  },
  // TODO number validator
  // max: {
  //   value: 10,
  //   message: 'max 10 thx',
  // },
};
