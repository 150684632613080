import React, { useEffect, useState } from 'react';
import LcimView from '../components/product/LcimView';
import useProduct from '../hooks/useProduct';
import useComponentState from '../hooks/useComponentState';
import PropTypes from 'prop-types';

const PublicProductPage = (props) => {
  const { match } = props;
  const lcimId = match.params.lcimId;
  // console.log({lcimId, match});
  const { getItemByLcimId } = useProduct();
  const { setLoading, setError } = useComponentState();
  const [productObject, setProductObject] = useState(null);

  const fetch = () => {
    setLoading(true);
    setProductObject(null);
    setError(null);
    getItemByLcimId('product', lcimId)
      .then((res) => {
        setProductObject(res);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(fetch, [lcimId]);

  return <>{productObject && <LcimView content={productObject} />}</>;
};
PublicProductPage.propTypes = {
  match: {
    params: {
      lcimId: PropTypes.string
    }
  }
};

export default PublicProductPage;
