import React from 'react';
import {
  Typography,
  Container,
  Grid,
  Card,
  Button,
  CardContent,
  FormControl,
  OutlinedInput,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  CircularProgress,
  Link,
  Box,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import useLogin from '../../hooks/useLogin';
// import ErrorComponent from '../ErrorComponent';
import useComponentState from '../../hooks/useComponentState';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  container: {
    marginTop: '10em',
    top: '5em',
    width: '23vw',
    minWidth: '350px',
  },
  h1: {
    fontSize: '26px',
    letterSpacing: '1.56px',
  },
  headerBlue: {
    opacity: 1,
  },
}));

const Error = (props) => {
  // eslint-disable-next-line react/prop-types
  const { message } = props;
  return (
    <Box>
      <Typography color="error">{message}</Typography>
    </Box>
  );
};

const Login = (props) => {
  const { login } = useLogin();
  const { t } = useTranslation('login');
  const { onLoginSuccess } = props;
  const classes = useStyles();
  const { loading, setLoading, error, setError } = useComponentState();
  let user = null;

  const schema = yup.object().shape({
    email: yup.string().required(t('required')),
    password: yup.string().required(t('required')),
  });

  try {
    user = localStorage.user ? JSON.parse(localStorage.user) : {};
  } catch (error) {
    user = {};
    // eslint-disable-next-line no-console
    console.error(error);
  }

  const { handleSubmit, register, errors, formState, watch } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      email: user.email || null,
    },
  });

  const onFormSubmit = (payload) => {
    const { email, password } = payload;
    setLoading(true);
    setError(null);
    login(email, password)
      .then((res) => {
        // email and password ok
        onLoginSuccess(res);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        let message = 'unknown error';
        if (err && Array.isArray(err)) {
          message = err[0];
          if (message === 'invalid credentials') {
            message = t('invalid_credentials');
          }
        }
        setError(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const watchAll = watch();

  const canSubmit = () => {
    if (watchAll && watchAll.email && watchAll.password) {
      return (
        !loading && watchAll.email.length > 0 && watchAll.password.length > 0
      );
    }
    return false;
  };

  return (
    <>
      <Container classes={{ root: classes.container }}>
        <Card elevation={0}>
          <CardContent>
            <form name="loginForm" onSubmit={handleSubmit(onFormSubmit)}>
              <Grid container spacing={6} justify="center">
                <Grid
                  container
                  item
                  xs={12}
                  alignContent="center"
                  justify="center"
                >
                  <Typography
                    variant="h1"
                    classes={{ root: classes.h1 }}
                    align="center"
                    display="block"
                  >
                    {t('FINIX')}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  justify="flex-start"
                  alignItems="center"
                >
                  <Typography paragraph variant="body2">
                    {t('signin')}
                  </Typography>
                  {/* <Grid item xs={1}>
                    <AccountCircle fontSize="large" />
                  </Grid>
                  <Grid item xs={11}> */}
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={errors.email && errors.email.message ? true : false}
                  >
                    <OutlinedInput
                      id="email"
                      name="email"
                      autoComplete="email"
                      aria-describedby="User email"
                      inputRef={register()}
                      inputProps={{
                        disabled: formState.isSubmitting,
                        tabIndex: '1',
                        placeholder: t('email'),
                      }}
                    />
                    <FormHelperText id="login-user-helper">
                      {errors.email && errors.email.message}
                    </FormHelperText>
                  </FormControl>
                  {/* </Grid> */}
                  <FormControl
                    fullWidth
                    variant="outlined"
                    error={
                      errors.password && errors.password.message ? true : false
                    }
                  >
                    <OutlinedInput
                      name="password"
                      type="password"
                      role="password"
                      autoComplete="current-password"
                      inputRef={register()}
                      inputProps={{
                        disabled: formState.isSubmitting,
                        tabIndex: '2',
                        placeholder: t('pwd'),
                        'aria-placeholder': 'Password',
                        'aria-required': 'true',
                      }}
                    />
                    <FormHelperText id="login-pwd-helper">
                      {errors.password && errors.password.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {error && <Error message={error} />}
                <Grid
                  container
                  item
                  xs={12}
                  justify="center"
                  alignItems="center"
                >
                  <Grid item xs={6}>
                    <FormGroup row>
                      <FormControlLabel
                        value="end"
                        control={<Checkbox color="primary" name="remember" />}
                        labelPlacement="end"
                        label={
                          <Typography variant="subtitle1">
                            {t('remember_me')}
                          </Typography>
                        }
                      ></FormControlLabel>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={6}>
                    <Link
                      variant="subtitle1"
                      href={'/forgot-password'}
                      color="inherit"
                      display="block"
                      align="right">
                      {t('forgot_password')}
                    </Link>
                  </Grid>
                  <Grid item xs={8}>
                    <Button
                      color="primary"
                      type="submit"
                      tabIndex="3"
                      variant="contained"
                      fullWidth
                      elevation={0}
                      disabled={!canSubmit()}
                    >
                      {loading && <CircularProgress size={20} />}
                      &nbsp;
                      {t('login')}
                    </Button>
                  </Grid>
                  <Grid container justify="center" style={{ marginTop: '1em' }}>
                    <Typography variant="subtitle1">
                      {t('no_account')}
                      &nbsp;
                      <Link
                        variant="subtitle2"
                        href={'/register'}
                        color="inherit"
                      >
                        {t('sign_up')}
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid container justify="center" style={{ marginTop: '1em' }}>
                    <Typography variant="subtitle1">
                      {t('see')}
                      &nbsp;
                      <Link
                        variant="subtitle2"
                        href={'/gdpr'}
                        color="inherit"
                      >
                        {t('gdpr')}
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default Login;

Login.propTypes = {
  onLoginSuccess: PropTypes.func.isRequired,
};
