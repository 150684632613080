import { Typography, Button } from '@material-ui/core';
import React from 'react';

const Dashboard = (props) => {
  const { onLogoutClick } = props;

  return (
    <>
      <Typography variant="h1">Dashboard component - hello world</Typography>
      <Button onClick={onLogoutClick}>logout</Button>
    </>
  );
};

export default Dashboard;
