/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, CircularProgress, FormLabel } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import { Observer } from 'mobx-react-lite';
import ErrorBox from './ErrorBox';
import useObservableState from './useObservableState';
import TextComponent from './TextComponent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useCompany from '../hooks/useCompany';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { toJS } from 'mobx';

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: 400,
  },
}));

const AssignToModalDialog = (props) => {
  const { dataObject, title, onClose } = props;
  const classes = useStyles();
  const { state: fetchState } = useObservableState();
  const { createCompanyInvite } = useCompany();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('assignto');

  const methods = useForm({
    defaultValues: { email: null },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const values = methods.watch();

  const onSubmit = (payload) => {
    fetchState.setLoading(true);
    fetchState.setError(null);

    // console.log('onSubmit dataObject', toJS(dataObject));

    const email = payload.email;
    const entity = 'component'; // TODO set entity
    const entityId = 1; // TODO set entityId

    createCompanyInvite(email, entity, entityId)
      .then((res) => {
        // console.log('results', res);
        enqueueSnackbar(t('invite_created_success'), { variant: 'success' });
        onClose();
      })
      .catch((err) => {
        fetchState.setError(err);
        enqueueSnackbar(t('invite_created_error'), { variant: 'error' });
      })
      .finally(() => {
        fetchState.setLoading(false);
      });
  };

  const body = (
    <>
      <FormProvider {...methods}>
        <FormLabel>Email</FormLabel>
        <TextComponent {...methods} name="email" />
      </FormProvider>
      <Observer>{() => fetchState.loading && <CircularProgress />}</Observer>
      <Observer>
        {() => fetchState.error && <ErrorBox error={fetchState.error} />}
      </Observer>
    </>
  );

  return (
    <Dialog
      open={true}
      onClose={() => onClose()}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <Box className={classes.dialog}>
        <DialogContentText></DialogContentText>
      </Box>
      <DialogContent>{body}</DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onSubmit(values)} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignToModalDialog;
