import {
  createMaterial,
  updateMaterial,
  linkMaterialToAssembly,
  unlinkMaterialFromAssembly,
  createComponent,
  linkComponentToMaterial,
  updateComponent,
  unlinkComponentFromMaterial,
  linkFiberToComponent,
  createFiber,
  unlinkFiberFromComponent,
  updateFiber,
  linkAssemblyToProduct,
  createAssembly,
  updateAssembly,
  unlinkAssemblyFromProduct,
} from '../hooks/useProduct';
import { makeStyles } from '@material-ui/core/styles';

export const handleFiberPromises = async (
  componentId,
  newFibers,
  oldFibers,
) => {
  const newData = newFibers || [];
  const oldData = oldFibers || [];
  // create new
  const createArr = newData.filter((e) => e.fiberId === null);
  for (const e of createArr) {
    const res = await createFiber(e);
    const newId = res.id;
    // set percentage via link method
    const percentageValue = e.percentage;
    await linkFiberToComponent(newId, componentId, percentageValue);
  }
  // update
  const updateArr = newData.filter((e) => e.fiberId !== null);
  for (const e of updateArr) {
    await updateFiber(e.fiberId, e);
    // set percentage via link method
    const percentageValue = e.percentage;
    await linkFiberToComponent(e.fiberId, componentId, percentageValue);
  }
  // unlink
  for (const e of oldData) {
    const found = newData.find((a) => a.fiber === e.fiber);
    if (!found) {
      await unlinkFiberFromComponent(e.fiberId, componentId);
    }
  }
};

export const handleComponentPromises = async (
  materialId,
  newComponents,
  oldComponents,
) => {
  const newData = newComponents || [];
  const oldData = oldComponents || [];
  // create new
  const createArr = newData.filter((e) => e.componentId === null);
  for (const e of createArr) {
    const res = await createComponent(e);
    const newId = res.id;
    // set percentage via link method
    const percentageValue = e.percentage;
    await linkComponentToMaterial(newId, materialId, percentageValue);
  }
  // update
  const updateArr = newData.filter((e) => e.componentId !== null);
  for (const e of updateArr) {
    await updateComponent(e.componentId, e);
    // set percentage via link method
    const percentageValue = e.percentage;
    await linkComponentToMaterial(e.componentId, materialId, percentageValue);
  }
  // unlink
  for (const e of oldData) {
    const found = newData.find((a) => a.componentId === e.componentId);
    if (!found) {
      await unlinkComponentFromMaterial(e.componentId, materialId);
    }
  }
};

export const handleMaterialPromises = async (
  assemblyId,
  newMaterials,
  oldMaterials,
) => {
  const newData = newMaterials || [];
  const oldData = oldMaterials || [];
  // create new
  const createArr = newData.filter((e) => e.materialId === null);
  for (const e of createArr) {
    const res = await createMaterial(e);
    const newId = res.id;
    await linkMaterialToAssembly(newId, assemblyId);
    await handleComponentPromises(newId, e.components, null);
  }
  // update
  const updateArr = newData.filter((e) => e.materialId !== null);
  for (const e of updateArr) {
    await updateMaterial(e.materialId, e);
    await linkMaterialToAssembly(e.materialId, assemblyId);
    const obj = oldData.find((o) => o.materialId === e.materialId) || {};
    await handleComponentPromises(e.materialId, e.components, obj.components);
  }
  // unlink
  for (const e of oldData) {
    const found = newData.find((a) => a.materialId === e.materialId);
    if (!found) {
      await unlinkMaterialFromAssembly(e.materialId, assemblyId);
    }
  }
};

export const handleAssemblyPromises = async (
  productId,
  newAssemblies,
  oldAssemblies,
) => {
  const newData = newAssemblies || [];
  const oldData = oldAssemblies || [];
  // create new
  const createArr = newData.filter((e) => e.assemblyId === null);
  for (const e of createArr) {
    const res = await createAssembly(e);
    const newId = res.id;
    await linkAssemblyToProduct(newId, productId);
    await handleMaterialPromises(newId, e.materials, null);
  }
  // update
  const updateArr = newData.filter((e) => e.assemblyId !== null);
  for (const e of updateArr) {
    await updateAssembly(e.assemblyId, e);
    await linkAssemblyToProduct(e.assemblyId, productId);
    const oldAssembly =
      oldData.find((o) => o.assemblyId === e.assemblyId) || {};
    await handleMaterialPromises(
      e.assemblyId,
      e.materials,
      oldAssembly.materials,
    );
  }
  // unlink
  for (const e of oldData) {
    const found = newData.find((a) => a.assemblyId === e.assemblyId);
    if (!found) {
      await unlinkAssemblyFromProduct(e.assemblyId, productId);
    }
  }
};

export const useAccordionStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
    '&$expanded': {
      marginBottom: theme.spacing(1),
      '&:last-child': {
        marginBottom: theme.spacing(1),
      },
    },
  },
  details: {
    // border: '1px solid red',
    paddingBottom: 0,
  },
  expanded: {
    '&$expanded': {},
  },
}));

export const useLineStyles = makeStyles((theme) => ({
  root: {
    // TODO straight and curved lines, SVG/CSS ?
    // backgroundColor: 'red',
    width: theme.spacing(5),
  },
  lastItem: {
    // backgroundColor: 'green',
  },
}));

export const useEditorRootStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'white',
  },
}));
