import React, { useEffect, useState } from 'react';
import LcimView from '../components/product/LcimView';
import useProduct from '../hooks/useProduct';
import useComponentState from '../hooks/useComponentState';
import PropTypes from 'prop-types';

const PublicComponentPage = (props) => {
  const { match } = props;
  const lcimId = match.params.lcimId;
  // console.log({lcimId, match});
  const { getItemByLcimId } = useProduct();
  const {
    // loading,
    setLoading,
    // error,
    setError
  } = useComponentState();
  const [componentObject, setComponentObject] = useState(null);

  const fetch = () => {
    setLoading(true);
    setComponentObject(null);
    setError(null);
    getItemByLcimId('component', lcimId)
      .then((res) => {
        setComponentObject(res);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(fetch, [lcimId]);

  return <>{componentObject && <LcimView content={componentObject} />}</>;
};
PublicComponentPage.propTypes = {
  match: {
    params: {
      lcimId: PropTypes.string
    }
  }
};

export default PublicComponentPage;
