import React, { useState, Fragment, useEffect } from 'react';
import { Snackbar, SnackbarContent, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  root: {
    width: '25vw',
    minWidth: '300px',
    color: '#fff',
  },
}));

const ErrorComponent = (props) => {
  const { error } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [errorOb, setError] = useState(error);

  const styles = {
    error: {
      backgroundColor: 'rgba(220,100,100,0.9)',
    },
    success: {
      backgroundColor: 'rgba(100,220,100,0.9)',
    },
  };

  useEffect(() => {
    setError(error);
    if (error) setOpen(true);
  }, [error]);

  const handleClick = () => {
    setError(null);
    setOpen(false);
  };

  return (
    <>
      {errorOb && (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        >
          <SnackbarContent
            classes={classes}
            style={errorOb.type ? styles[errorOb.type] : styles.error}
            role="alert"
            message={errorOb.message || errorOb}
            action={
              <>
                <Fragment>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={handleClick}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Fragment>
              </>
            }
          />
        </Snackbar>
      )}
    </>
  );
};

export default ErrorComponent;

ErrorComponent.propTypes = {
  error: PropTypes.object.isRequired,
};
