/* eslint-disable react/display-name */
/* eslint-disable indent */
import React, { forwardRef } from 'react';
import {
  Grid,
  Typography,
  CardMedia,
  IconButton,
  Button,
} from '@material-ui/core';
import MaterialTable, {
  MTableCell,
  MTableBodyRow,
  MTableHeader,
  MTableToolbar,
} from 'material-table';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDropUpSharpIcon from '@material-ui/icons/ArrowDropUpSharp';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import SettingsIcon from '@material-ui/icons/Settings';
import PropTypes from 'prop-types';
import LaunchIcon from '@material-ui/icons/Launch';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDropUpSharpIcon {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const linkIcons = {
  open: LaunchIcon,
  settings: SettingsIcon,
};

const TableAddButton = ({ label, callback }) => {
  return (
    <>
      <Button
        fullWidth
        type="button"
        variant="contained"
        color="primary"
        onClick={() => {
          callback();
        }}
      >
        {label}
      </Button>
    </>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    '& .MuiTable-root': {
      borderSpacing: '0em 16px',
      borderCollapse: 'separate',
      // paddingRight: '8px',
      // paddingLeft: '24px',
    },
  },
  cell: {
    // '& > *': {
    //   margin: theme.spacing(1),
    //   width: '20ch',
    // },
    // textAlign: 'center',
    // width: '100%',
    borderTop: 'none',
    borderBottom: 'none',
    contentAlign: 'flex-end',
  },
  header: {
    backgroundColor: 'unset',
    borderTop: 'none',
    color: 'rgb(30,45, 68)',
    borderBottom: 'none',
    paddingTop: '0px',
    paddingBottom: '0px',
    marginBottom: '0px',
    fontSize: '12px',
  },
  toolbar: {
    paddingBottom: '0em',
    paddingLeft: '0em',
    paddingRight: '0em',
  },
  row: {
    borderTop: 'none',
    borderBottom: 'none',
    marginBottom: 'none',
    marginTop: 'none',
    backgroundColor: '#fff',
  },
}));

const TableList = (props) => {
  const { items, pagetitle, langcontext, rendercols, addButton } = props;
  const { t } = useTranslation([langcontext, 'common']);
  const [renderItems, setItems] = React.useState([]);
  const [columns] = React.useState(makeColumns(rendercols) || []);
  const classes = useStyles();
  const mainRef = React.createRef();

  const itemLength = () => {
    let _str = '';
    try {
      switch (items && items.length.toString()) {
        case '0':
          _str = t('common:no_item');
          break;
        case '1':
          _str = '1 ' + t('common:one_item');
          break;
        default:
          _str =
            ((items && items.length) || '0').toString() +
            ' ' +
            t('common:items');
          break;
      }
    } catch (error) {
      console.log('itemLength', error);
    }
    return _str;
  };

  const arrValueParser = (val) => {
    switch (true) {
      case !val:
        return '';
      case typeof val !== 'string':
        return val;
      case val.indexOf('[') === 0 &&
        val.indexOf(']') === val.length - 1 &&
        val.split('","').length > 0:
        return val
          .trim()
          .substr(2, val.length - 4)
          .split('","')
          .filter((v) => (v ? true : false))
          .join(', ');
      default:
        return val;
    }
  };

  function makeColumns(colItems) {
    /*let type = 'normal';
    if (typeof item === 'object') {
      type = item.type;
      item = item.value;
    } */
    let _arr = [];
    let _types = [];
    if (Array.isArray(colItems)) {
      for (let i = 0; i < colItems.length; i++) {
        if (typeof colItems[i] === 'object') {
          _types.push({type: colItems[i].type, category: colItems[i].category});
          _arr.push(colItems[i].value);
        } else {
          _types.push({type: 'normal'});
          _arr.push(colItems[i]);
        }
      }
    } else {
      _arr = Object.keys(colItems);
    }
    // let _arr = Array.isArray(colItems) ? colItems : Object.keys(colItems);
    return _arr.map((a, i) => {
      switch (a) {
        case 'img':
          return {
            filtering: false,
            sorting: false,
            title: '',
            width: 92,
            align: 'left',
            cellStyle: {
              padding: '0px',
              margin: '0px',
              height: '92px',
            },
            field: a,
            render: function tableImage(rowData) {
              return rowData.img ? (
                <>
                  <CardMedia
                    component="img"
                    alt="Item image"
                    width="92px"
                    image={rowData.img}
                    title="Item image"
                  />
                </>
              ) : null;
            },
          };

        case 'name':
          return {
            title: t(a),
            width: '20vw',
            colspan: 2,
            defaultSort: 'asc',
            customSort: (f, s) => {
              // Sorts selected columns' values f=first, s=second
              //  -> 1 if s goes before f and -1 if f goes before s
              return (f[a] || '')
                .toString()
                .localeCompare((s[a] || '').toString(), undefined, {
                  numeric: true,
                });
            },
            field: a,
            render: function nameCell(rowData) {
              return (
                <>
                  <Typography variant="body2">{rowData[a]}</Typography>
                </>
              );
            },
          };
        case 'function':
          return {
            title: t(a),
            colspan: 2,
            sorting: false,
            filtering: false,
            field: a,
            render: function tableFunc(rowData) {
              let TagName = linkIcons[rowData.icon];
              return (
                <>
                  <IconButton
                    onClick={() => {
                      rowData[a](rowData);
                    }}
                    disableTouchRipple
                    color="primary"
                  >
                    {TagName ? <TagName /> : <SettingsIcon />}
                  </IconButton>
                </>
              );
            },
          };
        // case 'company': why there is such case?
        //   return {
        //     title: t(a),
        //     width: '1',
        //     field: a,
        //     customSort: (f, s) =>
        //       // Sorts selected columns' values f=first, s=second
        //       //  -> 1 if s goes before f and -1 if f goes before s
        //       (f[a] || '')
        //         .toString()
        //         .localeCompare((s[a] || '').toString(), undefined, {
        //           numeric: true,
        //         }),
        //     cellStyle: {
        //       breakWord: 'all',
        //     },
        //     render: function defaultCell(rowData) {
        //       const name = rowData[a] && rowData[a].name ? rowData[a].name : '';
        //       return (
        //         <>
        //           <Typography variant="body1">{name}</Typography>
        //         </>
        //       );
        //     },
        //   };
        default:
          return {
            title: t(a),
            width: '1',
            field: a,
            customSort: (f, s) =>
              // Sorts selected columns' values f=first, s=second
              //  -> 1 if s goes before f and -1 if f goes before s
              (f[a] || '')
                .toString()
                .localeCompare((s[a] || '').toString(), undefined, {
                  numeric: true,
                }),
            cellStyle: {
              breakWord: 'all',
            },
            render: function defaultCell(rowData) {
              const _rowContent = arrValueParser(rowData[a]);
              // const _link = _types[i].type === 'link' ? `${process.env.REACT_APP_API_URL}/product/lcim/${_rowContent.toString()}` : undefined;
              const _link = _types[i].type === 'link' ? `${process.env.REACT_APP_PUBLIC_URL}/public/${_types[i].category}/${_rowContent.toString()}` : undefined;
              return (
                <>
                  <Typography variant="body1">
                    {_link ? (
                      <a href={_link}>{_rowContent.toString()}</a>
                    ) : _rowContent.toString()}
                  </Typography>
                </>
              );
            },
          };
      }
    });
  }

  React.useEffect(
    function ItemEffect() {
      setItems(items);
    },
    [items],
  );

  const components = {
    Container: function tableContainer(props) {
      return <Grid item {...props}></Grid>;
    },
    Cell: function tableCell(props) {
      const { value, ...rest } = props; // to prevent value prop to genereate react warning
      return (
        <>
          <MTableCell {...rest} classes={{ root: classes.cell }} />
        </>
      );
    },
    Row: function tableRow(props) {
      return (
        <>
          <MTableBodyRow {...props} classes={{ root: classes.row }} />
        </>
      );
    },
    Header: function tableHeaderRow(_props) {
      return (
        <>
          <MTableHeader
            {..._props}
            variant="outlined"
            classes={{ header: classes.header }}
          />
        </>
      );
    },
    Toolbar: function tableToolBar(props) {
      return (
        <>
          <Grid container spacing={2} justify={'center'} alignItems={'center'}>
            <Grid item sm={addButton ? 10 : 12}>
              <MTableToolbar {...props} classes={{ root: classes.toolbar }} />
            </Grid>
            {addButton && (
              <>
                <Grid item sm={2}>
                  {addButton ? <TableAddButton {...addButton} /> : ''}
                </Grid>
              </>
            )}
          </Grid>
        </>
      );
    },
  };

  const title = (
    <>
      <Typography variant="h2">{t(pagetitle)}</Typography>
      <Typography variant="body2">{itemLength()}</Typography>
    </>
  );

  let ret = (
    <>
      <Grid container classes={{ root: classes.root }}>
        <MaterialTable
          title={title}
          style={{ width: '100%' }}
          columns={columns}
          options={{
            // pageSize: items.length || 20,
            paging: false,
            thirdSortClick: false,
            draggable: false,
            tableLayout: 'auto',
            searchFieldVariant: 'outlined',
            grouping: false,
          }}
          localization={{
            toolbar: {
              searchPlaceholder: t('common:search'),
            },
          }}
          data={renderItems}
          icons={tableIcons}
          tableRef={mainRef}
          size="small"
          components={components}
        ></MaterialTable>
      </Grid>
    </>
  );

  return ret;
};

export default TableList;

TableList.propTypes = {
  pagetitle: PropTypes.string.isRequired,
  langcontext: PropTypes.string.isRequired,
  rendercols: PropTypes.array.isRequired,
};
