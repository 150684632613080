import React from 'react';
import ResetPassword from '../components/login/ResetPassword';
import {
  // BrowserRouter as Router,
  useHistory
  // Switch,
  // Route
} from 'react-router-dom';
import { useRoutes } from '../hooks/useRoutes';

export default function ResetPasswordPage() {
  const { PATH } = useRoutes();
  const history = useHistory();
  const onResetPasswordSuccess = (payload) => {
    history.push(PATH.login);
  };
  return (
    <>
      <ResetPassword onResetPasswordSuccess={onResetPasswordSuccess} />
    </>
  );
}
