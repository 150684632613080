import api from './api';

const INVITE_ENDPOINT = (id) => `/company/${id}/invite`;

const useInvite = () => {
  /**
   * @param {string} email
   * @returns {Promise}
   */
  const invite = (email, cid) => {
      return api.post(INVITE_ENDPOINT(cid), { email, cid }).then((res) => {
        console.log({res});
        if (res.data.errors.length > 0) {
          throw res.data.errors;
        }
        return res.data.data;
      });
    // return api.post(INVITE_ENDPOINT(cid), obj).then((res) => {
    //   if (!res.data.success) {
    //     throw res.data.errors;
    //   }
    //   return res.data.data;
    // });
  };

  return { invite };
};

export default useInvite;