/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import useProduct from '../hooks/useProduct';
import useCompany from '../hooks/useCompany';
import Editor from './Editor';
import { constructEmptyAssembly } from '../hooks/utils';
import { handleMaterialPromises } from './editorUtils';
import Assembly from './Assembly';
import useObservableState from './useObservableState';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const AssemblyEditor = (props) => {
  const { assemblyId: fetchId, debug, onFormInitialized } = props;
  const { createAssembly, getCompleteAssembly, updateAssembly } = useProduct();
  const { getCompanies } = useCompany();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('common');

  const { state } = useObservableState();

  const [dataObject, setDataObject] = useState(null);
  const [companies, setCompanies] = useState(null);

  const fetch = (id) => {
    state.setLoading(true);
    state.setError(null);
    setDataObject(null);
    getCompleteAssembly(id)
      .then((res) => {
        setDataObject(res);
      })
      .then(() => getCompanies())
      .then((res) => {
        setCompanies(res);
      })
      .catch((err) => {
        state.setError(err);
      })
      .finally(() => {
        state.setLoading(false);
      });
  };

  const initialFetch = () => {
    if (fetchId) {
      fetch(fetchId);
    } else {
      state.setLoading(true);
      state.setError(null);
      getCompanies()
        .then((res) => {
          setCompanies(res);
        })
        .catch((err) => {
          state.setError(err);
        })
        .finally(() => {
          setDataObject(constructEmptyAssembly());
          state.setLoading(false);
        });
    }
  };

  useEffect(initialFetch, [fetchId]);

  const onSubmit = async (payload) => {
    console.log('onSubmit', payload);
    state.setLoading(true);
    try {
      let assemblyId = payload.assemblyId;
      if (!assemblyId) {
        const res = await createAssembly(payload);
        assemblyId = res.id;
      }
      await handleMaterialPromises(
        assemblyId,
        payload.materials,
        dataObject.materials,
      );
      await updateAssembly(assemblyId, payload);
      fetch(assemblyId);
      enqueueSnackbar(t('save_success'), { variant: 'success' });
    } catch (err) {
      state.setError(err);
      enqueueSnackbar(t('save_error'), { variant: 'error' });
    } finally {
      state.setLoading(false);
    }
  };

  return (
    <>
      <Editor
        dataObject={dataObject}
        onSubmit={onSubmit}
        onFormInitialized={onFormInitialized}
        debug={debug}
        state={state}
      >
        <Assembly companies={companies} disableAccordion={true} />
      </Editor>
    </>
  );
};

export default AssemblyEditor;
