import api from './api';

const FORGOT_PASSWORD_ENDPOINT = '/forgotten-password';

const useForgotPassword = () => {
  const forgotPassword = async (email) => {
    const obj = {
      entity: {
        email
      }
    };
    return api.post(`${FORGOT_PASSWORD_ENDPOINT}`, obj)
      .then((res) => {
        if (!res.data.success) {
          throw res.data.errors;
        }
        return res.data.data;
      })
  }

  return { forgotPassword };
}

export default useForgotPassword;