import React, { useEffect } from 'react';
import { Box, CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import useProduct from '../hooks/useProduct';
import { useHistory } from 'react-router-dom';
// import { useRoutes } from '../hooks/useRoutes';
import MainHeader from '../components/dashboard/MainHeader';
import TableList from '../components/product/TableList';
import PropTypes from 'prop-types';
import ErrorComponent from '../components/ErrorComponent';
import useComponentState from '../hooks/useComponentState';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    maxWidth: '90%',
  },
  links: {
    // paddingTop: '3em',
  },
  page: {
    marginTop: '2.5em',
  },
  modalbase: {
    height: '100%',
    flexGrow: 1,
  },
  modalpaper: {
    minWidth: '340px',
  },
  cont: {
    paddingLeft: '4em',
    paddingTop: '0.55em',
    paddingBottom: '0.55em',
    marginRight: '4em',
  },
}));

const TableComponent = ({ items, addButton }) => {
  return (
    <>
      <TableList
        items={items}
        pagetitle="assemblies"
        langcontext="assembly"
        rendercols={[
          'img',
          'name',
          { value:'lcimId', type:'link', category: 'assembly' },
          'company',
          'function'
        ]}
        addButton={addButton}
      />
    </>
  );
};

const AssembliesPage = () => {
  const { getAssemblies } = useProduct();
  const { t } = useTranslation(['assembly', 'common']);
  // const { item } = props;
  // const { PATH } = useRoutes();
  const history = useHistory();
  const classes = useStyles();
  const createAssemblyButton = {
    label: t('create_assembly'),
    callback: () => history.push('/create-assembly/'),
  };
  const [items, setItems] = React.useState();
  const { loading, setLoading, error, setError } = useComponentState();

  // React.useEffect(() => {
  //   async function call() {
  //     const ret = await getProducts();
  //     setItems(
  //       ret.filter((a) => {
  //         return a.name ? true : false;
  //       }),
  //     );
  //   }
  //   if (!items) call();
  // });

  const fetch = () => {
    setLoading(true);
    setError(null);
    getAssemblies()
      .then((res) => {
        res.forEach((a) => {
          a.function = () => {
            history.push('/edit-assembly/' + a.id);
          };
          a.icon = 'open';
        });
        setItems(res);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(fetch, []);

  // const onLogoutClick = () => {
  //   history.push(PATH.logout);
  // };

  return (
    <>
      <MainHeader />
      <Box classes={{ root: classes.cont }}>
        <Box classes={{ root: classes.links }}>
          {/* <ArrowBack style={{ marginRight: '0.4em', fontSize: '1.0em' }} />
          <Typography variant="subtitle1">
            {t('products')} / {t('create_product')}
          </Typography> */}
          {/* <Breadcrumbs>
            <Link color="inherit">
              <span>{t('assemblies')}</span>
            </Link>
          </Breadcrumbs> */}
        </Box>
        <Box classes={{ root: classes.page }}>
          {loading && (
            <Grid container justify="center">
              <CircularProgress />
            </Grid>
          )}
          {items && (
            <>
              <TableComponent items={items} addButton={createAssemblyButton} />
            </>
          )}
          {error && (
            <>
              <ErrorComponent error={error} />
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default AssembliesPage;

// MaterialListPage.propTypes = {
//   item: PropTypes.object.isRequired,
// };

TableComponent.propTypes = {
  items: PropTypes.array,
  addButton: PropTypes.object,
};
