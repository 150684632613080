import React from 'react';
import Register from '../components/login/Register';
import { useHistory } from 'react-router-dom';
import { useRoutes } from '../hooks/useRoutes';

export default function RegisterPage() {
  const { PATH } = useRoutes();
  const history = useHistory();

  const onRegisterSuccess = (payload) => {
    const user = {
      name: payload.name,
      id: payload.id,
      email: payload.email,
    };
    localStorage.setItem('user', JSON.stringify(user));
    history.push(PATH.login);
  };
  return (
    <>
      <Register onRegisterSuccess={onRegisterSuccess} />
    </>
  );
}
