import api from './api';

const LOGIN_ENDPOINT = '/auth/login';

const useLogin = () => {
  /**
   * Login with email and password
   *
   * On successful login promise is resolved with object
   * {
   *    id,
   *    name,
   *    email,
   *    token,
   * }
   *
   * On failed login promise is rejected with an array of errors
   *
   * @param {*} email
   * @param {*} password
   * @returns {Promise}
   */
  const login = (email, password) => {
    const obj = {
      credentials: {
        email,
        password,
      },
    };
    return api.post(LOGIN_ENDPOINT, obj).then((res) => {
      // console.log('login results', res);
      if (!res.data.success) {
        throw res.data.errors;
      }
      return res.data.data;
    });
  };

  return { login };
};

export default useLogin;
