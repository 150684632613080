/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable react/prop-types */
import React from 'react';
import { TableContainer, Table, TableBody } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DataRow from './DataRow';
import {
  PRODUCT_CATEGORY,
  PRODUCT_SEASON,
  // PRODUCT_SIZE,
  PRODUCT_TYPE,
  PRODUCT_FINISHING,
  PRODUCT_RECYCLING_TYPE,
} from './constants';
import { required } from './editorRules';

const ProductView = (props) => {
  const { t } = useTranslation(['product', 'common']);
  const { t: selectT } = useTranslation('options');
  const { companies } = props;
  // const classes = useStyles();

  const companiesOptions = companies
    ? companies.map((e) => {
        return {
          value: e.id,
          label: e.name,
        };
      })
    : [];

  const seasonOptions = PRODUCT_SEASON.map((e) => ({
    value: e,
    label: selectT('product_season.' + e),
  }));

  const categoryOptions = PRODUCT_CATEGORY.map((e) => ({
    value: e,
    label: selectT('product_category.' + e),
  }));

  const typeOptions = PRODUCT_TYPE.map((e) => ({
    value: e,
    label: selectT('product_type.' + e),
  }));

  const finishingOptions = PRODUCT_FINISHING.map((e) => ({
    value: e,
    label: selectT('product_finishing.' + e),
  }));

  const recyclingOptions = PRODUCT_RECYCLING_TYPE.map((e) => ({
    value: e,
    label: selectT('product_recycling.' + e),
  }));
  return (
    <>
      <TableContainer>
        <Table>
          <TableBody>
            <DataRow type={'hidden'} name={'productId'} dataRowProps={props} />
            <DataRow
              type={'text'}
              name={'name'}
              label={t('name')}
              dataRowProps={props}
              bigtext
              rules={required}
            />
            <DataRow
              type={'text'}
              name={'brand'}
              label={t('brand')}
              dataRowProps={props}
              rules={required}
            />
            <DataRow
              type={'select'}
              name={'companyId'}
              label={t('company')}
              dataRowProps={props}
              options={companiesOptions}
              rules={required}
            />
            <DataRow
              type={'text'}
              name={'sku'}
              label={t('sku')}
              dataRowProps={props}
            />
            <DataRow
              type={'text'}
              name={'sgtin'}
              label={t('gtin')}
              dataRowProps={props}
            />
            {/* <DataRow
              type={'select'}
              name={'size'}
              label={t('size')}
              rules={required}
              options={PRODUCT_SIZE}
              dataRowProps={props}
            /> */}
            <DataRow
              type={'select'}
              name={'season'}
              label={t('season')}
              options={seasonOptions}
              dataRowProps={props}
            />
            <DataRow
              type={'select'}
              name={'productCategory'}
              label={t('productCategory')}
              options={categoryOptions}
              dataRowProps={props}
              rules={required}
            />
            <DataRow
              type={'select'}
              name={'productType'}
              label={t('productType')}
              options={typeOptions}
              dataRowProps={props}
              rules={required}
            />
            <DataRow
              type={'number'}
              name={'productWeight'}
              label={t('productWeight')}
              dataRowProps={props}
              inputaddon="g"
            />
            <DataRow
              type={'selectmultiple'}
              name={'finishing'}
              label={t('finishing')}
              options={finishingOptions}
              dataRowProps={props}
            />
            <DataRow
              type={'selectmultiple'}
              name={'recyclable'}
              label={t('recyclable')}
              options={recyclingOptions}
              dataRowProps={props}
              rules={required}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ProductView;
