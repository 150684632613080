import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import useProduct from '../hooks/useProduct';
import { useHistory } from 'react-router-dom';
import { PATH } from '../hooks/useRoutes';
import MainHeader from '../components/dashboard/MainHeader';
import TableList from '../components/product/TableList';
// import PropTypes from 'prop-types';
import ErrorComponent from '../components/ErrorComponent';
import useComponentState from '../hooks/useComponentState';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    maxWidth: '90%',
  },
  links: {
    // paddingTop: '3em',
  },
  page: {
    marginTop: '2.5em',
  },
  modalbase: {
    height: '100%',
    flexGrow: 1,
  },
  modalpaper: {
    minWidth: '340px',
  },
  cont: {
    paddingLeft: '4em',
    paddingTop: '0.55em',
    paddingBottom: '0.55em',
    marginRight: '4em',
  },
}));

// const TableComponent = ({ items }) => {
//   switch (items) {
//     case 0:
//       return (
//         <>
//           <Grid container justify="center">
//             <CircularProgress color="secondary" />
//           </Grid>
//         </>
//       );
//     default:
//       return (
//         <>
//           <TableList
//             items={items}
//             pagetitle="products"
//             langcontext="product"
//             rendercols={[
//               'img',
//               'name',
//               'sku',
//               'sgtin',
//               'gtin',
//               'brand',
//               'productCategory',
//               'productType',
//               'function',
//             ]}
//           />
//         </>
//       );
//   }
// };

const ProductsListPage = () => {
  const { getProducts } = useProduct();
  const { t } = useTranslation(['product', 'common']);
  // const { item } = props;
  // const { PATH } = useRoutes();
  const history = useHistory();
  const classes = useStyles();
  const [items, setItems] = useState();
  const { loading, setLoading, error, setError } = useComponentState();

  const fetch = () => {
    setLoading(true);
    setError(null);
    getProducts(false)
      .then((res) => {
        setItems(
          res.filter((a) => {
            a.function = () => {
              history.push('/edit-product/' + a.id);
            };
            a.icon = 'open';
            return a.name ? true : false;
          }),
        );
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(fetch, []);

  // const onLogoutClick = () => {
  //   history.push(PATH.logout);
  // };

  return (
    <>
      <MainHeader />
      <Box classes={{ root: classes.cont }}>
        <Box classes={{ root: classes.links }}>
          {/* <ArrowBack style={{ marginRight: '0.4em', fontSize: '1.0em' }} />
          <Typography variant="subtitle1">
            {t('products')} / {t('create_product')}
          </Typography> */}
          {/* <Breadcrumbs>
            <Link color="inherit">
              <span>{t('products')}</span>
            </Link>
          </Breadcrumbs> */}
        </Box>

        <Box classes={{ root: classes.page }}>
          {loading && (
            <Grid container justify="center">
              <CircularProgress />
            </Grid>
          )}
          {items && (
            <TableList
              items={items}
              pagetitle="products"
              langcontext="product"
              rendercols={[
                'img',
                'name',
                { value:'lcimId', type:'link', category: 'product' },
                'sku',
                'sgtin',
                'gtin',
                'brand',
                'company',
                'productCategory',
                'productType',
                'function',
              ]}
              addButton={{
                label: t('create_product'),
                callback: () => history.push(PATH.createProduct),
              }}
            />
          )}
          {error && <ErrorComponent open={error} error={error.message} />}
        </Box>
      </Box>
    </>
  );
};

export default ProductsListPage;

// ProductsListPage.propTypes = {
//   item: PropTypes.object.isRequired,
// };
