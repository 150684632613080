/* eslint-disable no-undef */
import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
});

// for Storybook usage, disable redirecting on 401 status code
// set REACT_APP_DISABLE_SESSION_EXPIRED_REDIRECT=true in .env file
if (!process.env.REACT_APP_DISABLE_SESSION_EXPIRED_REDIRECT) {
  api.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (401 === error.response.status) {
        sessionStorage.setItem('redirect', window.location.pathname);
        localStorage.removeItem('token');
        window.location = '/login';
      } else {
        return Promise.reject(error);
      }
    },
  );
}

export default api;
