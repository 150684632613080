/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, CircularProgress, Grid } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import { Observer } from 'mobx-react-lite';
import ErrorBox from './ErrorBox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import SelectComponent from './SelectComponent';

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: 400,
  },
}));

const ModalDialog = (props) => {
  const {
    onClose,
    title,
    createButtonLabel = 'Create new',
    addButtonLabel = 'Add item from list',
    onSubmit = () => {},
    options = [],
    fetchState,
  } = props;
  const classes = useStyles();
  const methods = useForm({
    defaultValues: { options: null },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const selectedId = methods.watch('options');
  const values = methods.watch();

  const body = (
    <>
      <Grid container direction="column" alignItems="center">
        <Grid item container direction="column">
          <Button
            color="primary"
            variant="contained"
            onClick={(e) => {
              e.preventDefault();
              onSubmit(null);
            }}
          >
            {createButtonLabel}
          </Button>
        </Grid>
        <Grid item>
          <Box my={4}>or</Box>
        </Grid>
        <Grid item container direction="column">
          <FormProvider {...methods}>
            <SelectComponent {...methods} options={options} name="options" />
          </FormProvider>
          <Box p={1}></Box>
          <Button
            color="primary"
            variant="contained"
            onClick={(e) => {
              e.preventDefault();
              onSubmit(values);
            }}
            disabled={!selectedId}
          >
            {addButtonLabel}
          </Button>
        </Grid>
      </Grid>
      <Observer>{() => fetchState.loading && <CircularProgress />}</Observer>
      <Observer>
        {() => fetchState.error && <ErrorBox error={fetchState.error} />}
      </Observer>
    </>
  );

  return (
    <Dialog
      open={true}
      onClose={() => onClose()}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent className={classes.dialog}>{body}</DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalDialog;
