import React from 'react';
import { isAuthenticated, isRole } from './AppContext';
// import { Footer } from '../components/Footer/Footer';
// import Header from '../components/Header/Header';
// import { PageNotFound } from '../components/PageNotFound/PageNotFound';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PATH } from './useRoutes';

const RouteWithSubroutes = (route) => {
  let provider = null;
  if (route.provider) {
    provider = route.provider;
  }
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => (
        <route.render {...props} provider={provider} routes={route.routes} />
      )}
    />
  );
};

const PrivateRouteWithSubroutes = (props) => {
  console.log('authValid', isAuthenticated());
  return isAuthenticated() ? (
    <RouteWithSubroutes {...props} />
  ) : (
    <Redirect to={PATH.login} />
  );
};

const RoleRouteWithSubroutes = (props) => {
  console.log(`role: ${props.role}`, isRole(props.role));
  return isRole(props.role) ? (
    <PrivateRouteWithSubroutes {...props} />
  ) : (
    <Redirect to={PATH.products} />
  );
}

export const RenderRoutes = ({ routes }) => {
  console.log({routes});
  return (
    <Switch>
      {routes.map((route, i) => {
        if (route.private === true && typeof route.role === 'string') {
          return <RoleRouteWithSubroutes key={`${route.path}_${i}`} {...route} />
        } else if (route.private === true && typeof route.role !== 'string') {
          return <PrivateRouteWithSubroutes key={`${route.path}_${i}`} {...route} />
        } else {
          return <RouteWithSubroutes key={`${route.path}_${i}`} {...route} />
        }
        // return route.private === true ? (
        //   <PrivateRouteWithSubroutes key={`${route.path}_${i}`} {...route} />
        // ) : (
        //   <RouteWithSubroutes key={`${route.path}_${i}`} {...route} />
        // );
      })}
      <Route 
        render={(props) => (
          <>
            {/* <Header {...props} />
                        <PageNotFound {...props} />
                        <Footer {...props} /> */}
          </>
        )}
      ></Route>
    </Switch>
  );
};

export const RenderPrivateRoutes = ({ routes }) => {
  console.log({privateRoutes: routes})
  return (
    <Switch>
      {routes.map((route, i) => {
        return <PrivateRouteWithSubroutes key={route.path} {...route} />;
      })}
      <Route
        render={(props) => (
          <>
            {/* <Header {...props} />
                        <PageNotFound {...props} />
                        <Footer {...props} /> */}
          </>
        )}
      ></Route>
    </Switch>
  );
};
