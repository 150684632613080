import api from './api';

const COMPANY_ENDPOINT = '/company';
const COMPANY_INVITE_ENDPOINT = '/company/company-invite';

const useCompany = () => {
  const getCompanies = () => {
    return api.get(COMPANY_ENDPOINT).then((res) => {
      if (!res.data.success) {
        throw res.data.errors;
      }
      return res.data.data;
    });
  };
  const getCompanyById = (id) => {
    return api.get(COMPANY_ENDPOINT + `/${id}`).then((res) => {
      if (!res.data.success) {
        throw res.data.errors;
      }
      return res.data.data;
    });
  };
  const createCompany = (name) => {
    const obj = {
      entity: {
        name,
      },
    };
    return api.post(COMPANY_ENDPOINT, obj).then((res) => {
      // console.log(res.data);
      if (!res.data.success) {
        throw new Error(res.data.errors);
      }
      return res.data.data;
    });
  };
  const updateCompany = (id, name, locations) => {
    if (!id) throw new Error('Company Id is not defined');
    const obj = {
      entity: {
        name,
        locations,
      },
    };
    return api.patch(COMPANY_ENDPOINT + '/' + id, obj).then((res) => {
      // console.log(res.data);
      if (!res.data.success) {
        throw new Error(res.data.errors);
      }
      return res.data.data;
    });
  };

  const getCompanyInvites = () => {
    return api.get(COMPANY_INVITE_ENDPOINT).then((res) => {
      if (!res.data.success) {
        throw res.data.errors;
      }
      return res.data.data;
    });
  };

  const createCompanyInvite = (email, entity, entityId) => {
    const obj = {
      entity: {
        email,
        entity,
        entityId,
      },
    };
    return api.post(COMPANY_INVITE_ENDPOINT, obj).then((res) => {
      if (!res.data.success) {
        throw res.data.errors;
      }
      return res.data.data;
    });
  };

  return {
    getCompanies,
    getCompanyById,
    createCompany,
    updateCompany,
    createCompanyInvite,
    getCompanyInvites,
  };
};

export default useCompany;
