import React, { useState } from 'react';
import {
  Typography,
  Container,
  Grid,
  Card,
  Button,
  CardContent,
  FormControl,
  OutlinedInput,
  FormHelperText,
  CircularProgress,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import ErrorComponent from '../ErrorComponent';
import useComponentState from '../../hooks/useComponentState';
import useForgotPassword from '../../hooks/useForgotPassword';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  container: {
    marginTop: '10em',
    top: '5em',
    width: '23vw',
    minWidth: '350px',
  },
  h1: {
    fontSize: '26px',
    letterSpacing: '1.56px',
  },
  headerBlue: {
    opacity: 1,
  },
}));

const ForgotPassword = (props) => {
  const { forgotPassword } = useForgotPassword();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['forgotPassword', 'common']);
  const {
    handleSubmit,
    register,
    errors,
    // setError,
    getValues,
    formState,
    reset,
    // watch,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const { onForgotPasswordSuccess } = props;
  const { setLoading } = useComponentState();
  const classes = useStyles();
  const [serverError/*, setServerError*/] = useState(null);

  const updateErr = (a) => {
    console.log('e', a.message);
  };

  const serverErrorHandler = (error) => {
    enqueueSnackbar(error.message, {
      variant: 'error',
    });
    let vals = getValues();
    reset(vals);
  };

  const _final = (a) => {
    setLoading(true);
    console.log({a});
    if (Array.isArray(a) || a.message) {
      if (Array.isArray(a) && a.length === 0) {
        return serverErrorHandler({ message: 'API error' });
      } else {
        return serverErrorHandler({ message: a.join(', ') || a.message });
      }
    }
    try {
      enqueueSnackbar(t('email_sent') + `: ${a.email}`, {
        variant: 'success',
      });
      onForgotPasswordSuccess(a);
    } catch (error) {
        console.log(error);
    }
    setLoading(false);
  };

  const _validate = async (p) => {
    return forgotPassword(p.email)
      .then(_final)
      .catch(_final);
  };

  return (
    <>
      <Container classes={{ root: classes.container }}>
        <Card elevation={0}>
          <CardContent>
            <form
              name="loginForm"
              onSubmit={handleSubmit(_validate, updateErr)}
            >
              <Grid container spacing={6} justify="center">
                <Grid
                  container
                  item
                  xs={12}
                  alignContent="center"
                  justify="center"
                >
                  <Typography
                    variant="h1"
                    classes={{ root: classes.h1 }}
                    align="center"
                    display="block"
                  >
                    {'FINIX'}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  justify="flex-start"
                  alignItems="center"
                >
                  <Typography paragraph variant="body2">
                    {t('forgot_password')}
                  </Typography>
                  {/* <Grid item xs={1}>
                    <AccountCircle fontSize="large" />
                  </Grid>
                  <Grid item xs={11}> */}
                  {/* </Grid> */}
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={errors.email && errors.email.message ? true : false}
                  >
                    <OutlinedInput
                      id="email"
                      name="email"
                      autoComplete="email"
                      aria-describedby="User email"
                      inputRef={register()}
                      inputProps={{
                        disabled: formState.isSubmitting,
                        tabIndex: '1',
                        placeholder: t('email'),
                      }}
                    />
                    <FormHelperText id="login-user-helper">
                      {errors.email && errors.email.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  justify="center"
                  alignItems="center"
                >
                  <Grid item xs={8}>
                    <Button
                      type="submit"
                      tabIndex="3"
                      variant="contained"
                      fullWidth
                      color="primary"
                      elevation={0}
                      disabled={
                        errors.email ||
                        formState.isSubmitting
                          ? true
                          : false
                      }
                    >
                      {formState.isSubmitting && <CircularProgress size={20} />}
                      &nbsp;
                      {t('forgot_password')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
        {serverError && <ErrorComponent error={serverError}></ErrorComponent>}
      </Container>
    </>
  );
};

export default ForgotPassword;

ForgotPassword.propTypes = {
  onForgotPasswordSuccess: PropTypes.func.isRequired,
};
