/* eslint-disable no-console */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from 'react';
import { TextField } from '@material-ui/core';
import { Controller } from 'react-hook-form';

const TextComponent = (props) => {
  const { control, name, defaultValue, isError, rules } = props;
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ onChange, value, name, ref }) => (
        <TextField
          onChange={(e) => {
            onChange(e.target.value);
          }}
          variant="outlined"
          fullWidth
          value={value != null ? value : ''}
          name={name}
          inputRef={ref}
          error={isError}
        />
      )}
    />
  );
};

export default TextComponent;
