import React from 'react';
import { Container, Card, CardContent, Typography, CardHeader } from '@material-ui/core';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import LcimItemDisplay from './LcimItemDisplay';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  card: {
    backgroundColor: "#f1f1f1"
  },
  container: {
    marginTop: '5em',
    top: '5em',
    width: '680px',
    minWidth: '350px'
  },
  h1: {
    marginTop: '3em',
    fontSize: '26px',
    letterSpacing: '1.56px'
  },
  headerBlue: {
    opacity: 1
  },
  title: {
    display: 'flex',
    justifyContent: "space-around",
    padding: "16px 0",
    fontSize: 24,
    backgroundColor: "#f1f1f1"
  }
}));

const LcimView = (props) => {
  const { content } = props;
  const { t } = useTranslation('lcimview');
  // console.log(content);
  const classes = useStyles();
  // console.log({content});
  // const itemColumn = createItemColumn(content, ['']);
  // console.log({itemColumn});

  return (
    <>
      <Typography
        variant="h1"
        classes={{ root: classes.h1 }}
        align="center"
        display="block"
      >
        {t('FINIX')}
      </Typography>
      <Container classes={{ root: classes.container }}>
        <Card elevation={2}>
          <CardContent classes={{ root: classes.card }}>
            <div style={{ border: "1px solid #f1f1f1"}}>
              <Typography className={classes.title}>{content.name}</Typography>
              <div style={{ padding: "16px", backgroundColor: "#fff" }}>
                <LcimItemDisplay item={content}></LcimItemDisplay>
              </div>
            </div>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

LcimView.propTypes = {
  content: PropTypes.any
};

export default LcimView;
