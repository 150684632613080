/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ConnectForm } from './product/ConnectForm';
import { useFieldArray } from 'react-hook-form';
// import { makeStyles } from '@material-ui/core/styles';
import Material from './Material';
import { constructEmptyMaterial, getFieldName } from '../hooks/utils';
import AssemblyView from './AssemblyView';
import { useTranslation } from 'react-i18next';
import StepController from './StepController';
import ItemHeader from './ItemHeader';
import useProduct from '../hooks/useProduct';
import useObservableState from './useObservableState';
import { Observer, useLocalObservable } from 'mobx-react-lite';
import ModalDialog from './ModalDialog';
import clsx from 'clsx';
import {
  useLineStyles,
  useAccordionStyles,
  useEditorRootStyles,
} from './editorUtils';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AssignToModalDialog from './AssignToModalDialog';
import ControllerHeader from './ControllerHeader';

const MaterialController = (props) => {
  const {
    control,
    prefix,
    companies,
    getCallbackObj,
    appendMaterials,
    showHeader,
    watch,
  } = props;
  const classes = useLineStyles();
  const { t } = useTranslation('material');
  const ARRAY_KEY = 'materials';
  const MATERIAL_KEY = getFieldName(prefix, ARRAY_KEY);
  const { fields, append, remove } = useFieldArray({
    control,
    name: MATERIAL_KEY,
  });

  const { getMaterials, getCompleteMaterial } = useProduct();
  const { state: fetchState } = useObservableState();

  const dialogState = useLocalObservable(() => ({
    open: false,
    setOpen(e) {
      this.open = e;
    },
    options: [],
    setOptions(e) {
      this.options = e;
    },
  }));

  const assignToDialogState = useLocalObservable(() => ({
    open: false,
    setOpen(e) {
      this.open = e;
    },
    dataObject: null,
    setDataObject(e) {
      this.dataObject = e;
    },
  }));

  const onDialogSubmit = async (payload) => {
    if (!payload) {
      createNew();
      dialogState.setOpen(false);
    } else {
      const id = payload && payload.options ? payload.options : null;
      fetchState.setLoading(true);
      fetchState.setError(null);
      try {
        const obj = await getCompleteMaterial(id);
        if (obj.components && obj.components.length > 0) {
          obj.appendComponents = true;
        }
        append(obj);
        dialogState.setOpen(false);
      } catch (err) {
        fetchState.setError(err);
      } finally {
        fetchState.setLoading(false);
      }
    }
  };

  const onOpenDialog = () => {
    dialogState.setOpen(true);
    fetchState.setLoading(true);
    fetchState.setError(null);
    getMaterials()
      .then((res) => {
        fetchState.setResults(res);
        const arrayValue = watch(MATERIAL_KEY) || [];
        const options = res.map((e) => {
          return {
            value: e.id,
            label: e.name,
            disabled: arrayValue.find((f) => f.materialId === e.id) || false,
          };
        });
        dialogState.setOptions(options);
      })
      .catch((err) => {
        fetchState.setError(err);
      })
      .finally(() => {
        fetchState.setLoading(false);
      });
  };

  const createNew = () => {
    const obj = constructEmptyMaterial();
    append(obj);
  };

  const init = () => {
    if (getCallbackObj) {
      getCallbackObj({
        onCreate: () => onOpenDialog(),
        append: append,
      });
    }
    if (appendMaterials) {
      // try-catching because calling append() throws sometimes error even if it is working
      try {
        for (const obj of appendMaterials) {
          if (obj.components && obj.components.length > 0) {
            obj.appendComponents = true;
          }
          append(obj);
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }
  };

  useEffect(init, []);

  const onDelete = (payload, index) => {
    // console.log('onDelete', index, payload);
    remove(index);
  };

  const onAssignTo = (payload) => {
    assignToDialogState.setDataObject(payload);
    assignToDialogState.setOpen(true);
  };

  return (
    <>
      {showHeader && (
        <Box mb={2}>
          <ControllerHeader
            state={fetchState}
            onAddButtonClick={() => onOpenDialog()}
            title={t('materials') + ' (' + fields.length + ')'}
          />
        </Box>
      )}
      {fields.map((e, index) => {
        const style = clsx({
          [classes.root]: true,
          [classes.lastItem]: index === fields.length - 1,
        });
        return (
          <>
            {showHeader ? (
              <Material
                key={e.id}
                {...e}
                index={index}
                onDelete={(e) => onDelete(e, index)}
                onAssignTo={(payload) => onAssignTo(payload)}
                prefix={`${MATERIAL_KEY}[${index}]`}
                companies={companies}
              />
            ) : (
              <Grid container key={e.id}>
                <Grid item classes={{ root: style }}></Grid>
                <Grid item xs={true}>
                  <Material
                    {...e}
                    index={index}
                    onDelete={(e) => onDelete(e, index)}
                    onAssignTo={(payload) => onAssignTo(payload)}
                    prefix={`${MATERIAL_KEY}[${index}]`}
                    companies={companies}
                  />
                </Grid>
              </Grid>
            )}
          </>
        );
      })}

      <Observer>
        {() => (
          <>
            {dialogState.open && (
              <ModalDialog
                options={dialogState.options}
                onClose={() => dialogState.setOpen(false)}
                onSubmit={onDialogSubmit}
                fetchState={fetchState}
                title="Add new material"
                addButtonLabel="Add material from list"
                createButtonLabel="Create a new material"
              />
            )}
          </>
        )}
      </Observer>
      <Observer>
        {() => (
          <>
            {assignToDialogState.open && (
              <AssignToModalDialog
                dataObject={assignToDialogState.dataObject}
                onClose={() => assignToDialogState.setOpen(false)}
                title="Assign material"
              />
            )}
          </>
        )}
      </Observer>
    </>
  );
};

const Assembly = (props) => {
  const [callbackObj, setCallbackObj] = useState();
  const classes = useAccordionStyles();
  const rootClasses = useEditorRootStyles();
  const { appendMaterials, materials } = props;
  const {
    prefix,
    onDelete,
    onAssignTo,
    accordionProps,
    companies,
    disableAccordion,
  } = props;
  return (
    <>
      <ConnectForm>
        {(formProps) => {
          const { watch } = formProps;
          const assemblyName = watch(getFieldName(prefix, 'name'));
          return (
            <>
              {!disableAccordion ? (
                <>
                  <Accordion
                    {...accordionProps}
                    square
                    elevation={0}
                    classes={{ root: classes.root }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <ItemHeader
                        title={
                          assemblyName || (
                            <Typography variant="subtitle2">
                              <em>Unnamed assembly</em>
                            </Typography>
                          )
                        }
                        onDelete={onDelete ? () => onDelete(props) : null}
                        createLabel="Add new material"
                        onAssignTo={onAssignTo ? () => onAssignTo(props) : null}
                        onCreate={() => callbackObj.onCreate('Material')}
                      />
                    </AccordionSummary>
                    <AccordionDetails classes={{ root: classes.details }}>
                      <Grid container>
                        <Grid item xs={true}>
                          <AssemblyView
                            {...formProps}
                            prefix={prefix}
                            valueProps={props}
                          />
                          <StepController
                            {...formProps}
                            prefix={prefix}
                            companies={companies}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Grid justify="flex-end" container>
                    <Grid item xs={12}>
                      <MaterialController
                        {...formProps}
                        prefix={prefix}
                        companies={companies}
                        appendMaterials={appendMaterials ? materials : null}
                        getCallbackObj={(payload) => setCallbackObj(payload)}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <>
                    <Box px={2} mb={8} classes={rootClasses}>
                      <AssemblyView
                        {...formProps}
                        prefix={prefix}
                        valueProps={props}
                      />
                      <StepController
                        {...formProps}
                        prefix={prefix}
                        companies={companies}
                      />
                    </Box>
                    <MaterialController
                      {...formProps}
                      prefix={prefix}
                      companies={companies}
                      appendMaterials={appendMaterials ? materials : null}
                      getCallbackObj={(payload) => setCallbackObj(payload)}
                      showHeader={true}
                    />
                  </>
                </>
              )}
            </>
          );
        }}
      </ConnectForm>
    </>
  );
};

export default Assembly;
