/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { ConnectForm } from './product/ConnectForm';
import { getFieldName } from '../hooks/utils';
import FiberView from './FiberView';
import {
  Grid,
  Typography,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Box,
} from '@material-ui/core';
import StepController from './StepController';
import ItemHeader from './ItemHeader';
import { useAccordionStyles, useEditorRootStyles } from './editorUtils';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useCompany from '../hooks/useCompany';
const Component = (props) => {
  const {
    prefix,
    onDelete,
    onAssignTo,
    companies,
    accordionProps,
    disablePercentage,
    disableAccordion,
  } = props;
  const classes = useAccordionStyles();
  const rootClasses = useEditorRootStyles();
  const [locations, setLocations] = useState([]);
  const { getCompanyById } = useCompany();

  const onCompanyIdChange = (payload) => {
    getCompanyById(payload).then((res) => {
      const { locations } = res;
      setLocations(locations);
    });
  };
  return (
    <>
      <ConnectForm>
        {(formProps) => {
          const { watch } = formProps;
          const componentName = watch(getFieldName(prefix, 'name'));
          return (
            <>
              {!disableAccordion ? (
                <Accordion
                  {...accordionProps}
                  square
                  elevation={0}
                  classes={{ root: classes.root }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <ItemHeader
                      title={
                        componentName || (
                          <Typography variant="subtitle2">
                            <em>Unnamed component</em>
                          </Typography>
                        )
                      }
                      onDelete={onDelete ? () => onDelete(props) : null}
                      onAssignTo={onAssignTo ? () => onAssignTo(props) : null}
                    />
                  </AccordionSummary>
                  <AccordionDetails classes={{ root: classes.details }}>
                    <Grid container>
                      <Grid item xs={true}>
                        <FiberView
                          {...formProps}
                          prefix={prefix}
                          valueProps={props}
                          disablePercentage={disablePercentage}
                          onCompanyIdChange={onCompanyIdChange}
                          companies={companies}
                          locations={locations}
                        />
                        <StepController
                          {...formProps}
                          prefix={prefix}
                          companies={companies}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ) : (
                <>
                  <Box px={2} mb={8} classes={rootClasses}>
                    <FiberView
                      {...formProps}
                      prefix={prefix}
                      valueProps={props}
                      disablePercentage={disablePercentage}
                      companies={companies}
                      onCompanyIdChange={onCompanyIdChange}
                      locations={locations}
                    />
                    <StepController
                      {...formProps}
                      prefix={prefix}
                      companies={companies}
                    />
                  </Box>
                </>
              )}
            </>
          );
        }}
      </ConnectForm>
    </>
  );
};

export default Component;
