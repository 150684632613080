import { useLocalObservable } from 'mobx-react-lite';

const useObservableState = () => {
  const state = useLocalObservable(() => ({
    loading: false,
    error: null,
    results: null,
    setError(e) {
      this.error = e;
    },
    setLoading(e) {
      this.loading = e;
    },
    setResults(e) {
      this.results = e;
    },
  }));

  return { state };
};

export default useObservableState;
