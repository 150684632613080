import React, { useState, useEffect } from 'react';
import { Box, Modal, Grid, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
// import { useHistory } from 'react-router-dom';
// import { useRoutes } from '../hooks/useRoutes';
import MainHeader from '../components/dashboard/MainHeader';
import TableList from '../components/product/TableList';
import UserEdit from '../components/product/UserEdit';
import ErrorComponent from '../components/ErrorComponent';
import UserAdd from '../components/product/UserAdd';
import PropTypes from 'prop-types';
import useComponentState from '../hooks/useComponentState';
import useUser from '../hooks/useUser';
import useCompany from '../hooks/useCompany';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    maxWidth: '90%',
  },
  links: {
    // paddingTop: '3em',
  },
  page: {
    marginTop: '2.5em',
  },
  modalbase: {
    height: '100%',
    flexGrow: 1,
  },
  modalpaper: {
    minWidth: '340px',
  },
  cont: {
    paddingLeft: '4em',
    paddingTop: '0.55em',
    paddingBottom: '0.55em',
    marginRight: '4em',
  },
}));

// const TableComponent = ({ items }) => {
//   switch (items) {
//     case 0:
//       return (
//         <>
//           <Grid container justify="center">
//             <CircularProgress />
//           </Grid>
//         </>
//       );
//     default:
//       return (
//         <>
//           <TableList
//             items={items}
//             pagetitle="Users"
//             langcontext="userlist"
//             rendercols={['img', 'name', 'user_name', 'company', 'function']}
//           />
//         </>
//       );
//   }
// };

const UserListPage = (props) => {
  const { t } = useTranslation('userlist');
  const { item } = props;
  // const { PATH } = useRoutes();
  // const history = useHistory();
  const { getUsers } = useUser();
  const { getCompanies } = useCompany();
  const classes = useStyles();
  const [needsReload, setReload] = useState(false);

  const [modalContent, setEditUserState] = useState({
    item: {},
    visible: false,
  });
  const [addUserContent, setAddUserState] = useState({
    item: {},
    visible: false,
  });
  const editUserStateFunc = (contentItem) => {
    if (modalContent.visible) {
      setReload(true);
      return setEditUserState({ item: {}, visible: false });
    }
    setEditUserState({ visible: true, item: contentItem });
  };
  const addUserStateFunc = (contentItem) => {
    if (addUserContent.visible) {
      setReload(true);
      return setAddUserState({ item: {}, visible: false });
    }
    console.log('item', contentItem);
    setAddUserState({ visible: true, item: contentItem });
  };

  const [items, setItems] = useState();
  const [companies, setCompanies] = useState();
  const { loading, setLoading, error, setError } = useComponentState();

  const fetch = () => {
    setLoading(true);
    setError(null);
    getUsers(false)
      .then((res) => {
        setItems(
          res.filter((a) => {
            a.function = () => editUserStateFunc({ ...a });
            a.icon = 'open';
            a.company = a.company.name;
            return a.name ? true : false;
          }),
        );
        getCompanies()
          .then((_companies) => {
            setCompanies(_companies);
          }).catch((_err) => {
            console.log(_err);
            setError(_err);
          });
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
        setReload(false);
      });
  };

  useEffect(fetch, [needsReload]);

  return (
    <>
      <MainHeader item={item} />
      <Box classes={{ root: classes.cont }}>
        <Box classes={{ root: classes.links }}>
          {/* <ArrowBack style={{ marginRight: '0.4em', fontSize: '1.0em' }} />
          <Typography variant="subtitle1">
            {t('products')} / {t('create_product')}
          </Typography> */}
          {/* <Breadcrumbs>
            <Link color="inherit">
              <span>{t('users')}</span>
            </Link>
          </Breadcrumbs> */}
        </Box>

        <Box classes={{ root: classes.page }}>
          <Modal open={modalContent && modalContent.visible}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              classes={{ root: classes.modalbase }}
            >
              <Grid item xs={8} sm={5} classes={{ root: classes.modalpaper }}>
                {modalContent && modalContent.visible ? (
                  <>
                    <UserEdit
                      user={modalContent.item}
                      langcontext="userlist"
                      onChange={editUserStateFunc}
                    ></UserEdit>
                  </>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
          </Modal>
          <Modal open={addUserContent && addUserContent.visible}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              classes={{ root: classes.modalbase }}
            >
              <Grid item xs={8} sm={5} classes={{ root: classes.modalpaper }}>
                {addUserContent && addUserContent.visible ? (
                  <>
                    <UserAdd
                      companies={companies}
                      user={addUserContent.item}
                      langcontext="userlist"
                      onChange={addUserStateFunc}
                    ></UserAdd>
                  </>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
          </Modal>
          <Modal open={modalContent && modalContent.useradd}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              classes={{ root: classes.modalbase }}
            >
              <Grid item xs={8} sm={5} classes={{ root: classes.modalpaper }}>
                {modalContent && modalContent.visible ? (
                  <>
                    <UserEdit
                      user={modalContent.item}
                      langcontext="userlist"
                      onChange={addUserStateFunc}
                    ></UserEdit>
                  </>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
          </Modal>
          {loading && (
            <>
              <Grid container justify="center">
                <CircularProgress />
              </Grid>
            </>
          )}
          {items && (
            <TableList
              items={items}
              pagetitle="Users"
              langcontext="userlist"
              rendercols={[
                'img',
                'name',
                'id',
                'email',
                'role',
                'company',
                'function',
              ]}
              addButton={{
                label: t('create_user'),
                callback: addUserStateFunc,
              }}
            />
          )}
          {error && <ErrorComponent open={error} error={error.message} />}
        </Box>
      </Box>
    </>
  );
};

export default UserListPage;

UserListPage.propTypes = {
  item: PropTypes.object,
};
