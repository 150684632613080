// import moment from 'moment';

// PRODUCT KEYS
export const constructEmptyProduct = () => {
  const obj = {
    productId: null,
    companyId: null,
    name: '',
    brand: '',
    productCategory: '',
    productType: '',
    productWeight: null,
    recyclable: '',
    sku: '',
    finishing: '',
    sgtin: '',
    gtin: '',
    season: '',
    instructions: '',
    // size: '', // TODO re-enable if needed
    steps: [],
  };
  return obj;
};

export const PRODUCT_ACCEPTED_KEYS = Object.keys(
  constructEmptyProduct(),
).filter((e) => !['productId', 'companyId'].includes(e));

// ASSEMBLY KEYS
export const constructEmptyAssembly = () => {
  const obj = {
    assemblyId: null,
    name: '',
  };
  return obj;
};
export const ASSEMBLY_ACCEPTED_KEYS = Object.keys(
  constructEmptyAssembly(),
).filter((e) => e !== 'assemblyId');

// MATERIAL KEYS
export const constructEmptyMaterial = () => {
  const obj = {
    materialId: null,
    companyId: null,
    name: '',
    tradename: '',
    dyed: false,
    dyeingMethod: '',
    dyestuff: '',
    color: '',
    printed: '',
    printingMethod: '',
    printDyestuff: '',
    finishing: '',
    company: '',
    weight: null,
    description: '',
    colorCode: '',
    pattern: '',
    construction: '',
    certification: ''
  };
  return obj;
};
export const MATERIAL_ACCEPTED_KEYS = Object.keys(
  constructEmptyMaterial(),
).filter((e) => !['materialId', 'companyId'].includes(e));

// COMPONENT KEYS
export const constructEmptyComponent = () => {
  const obj = {
    componentId: null,
    name: '',
    company: null,
    location: null,
    type: '',
    fiber: '',
    isRecycled: null,
    percentage: '',
    sourceOfFiber: '',
    certification: '',
    sourceOfRecycledInput: '',
  };
  return obj;
};
export const COMPONENT_ACCEPTED_KEYS = Object.keys(
  constructEmptyComponent(),
).filter((e) => e !== 'componentId');

// FIBER KEYS
export const constructEmptyFiber = () => {
  const obj = {
    fiberId: null,
    name: '',
    fiber: '',
    company: null,
    location: null
  };
  return obj;
};
export const FIBER_ACCEPTED_KEYS = Object.keys(
  constructEmptyFiber(),
).filter((e) => e !== 'fiberId');

// STEP KEYS
export const constructEmptyStep = () => {
  const obj = {
    stepId: null,
    type: '',
    location: null,
    company: '',
    date: null,
    meansOfProducedEnergy: null,
    energyConsumption: null,
    waterConsumption: null,
    wasteWaterTreatment: null,
    co2Emissions: null,
  };
  return obj;
};

export const STEP_ACCEPTED_KEYS = Object.keys(constructEmptyStep()).filter(
  (e) => !['stepId', 'companyId'].includes(e),
);

export const getFieldName = (prefix, name) => {
  if (prefix) {
    return prefix + '.' + name;
  } else {
    return name;
  }
};

export const getLoggedInUser = () => {
  let user = null;
  try {
    user = JSON.parse(localStorage.getItem('user'));
  } catch (error) {
    console.log('user-undef', error);
  }
  return user;
};
