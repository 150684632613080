/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import useProduct from '../hooks/useProduct';
import useCompany from '../hooks/useCompany';
import Editor from './Editor';
import { constructEmptyMaterial } from '../hooks/utils';
import { handleComponentPromises } from './editorUtils';
import useObservableState from './useObservableState';
import Material from './Material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const MaterialEditor = (props) => {
  const { materialId: fetchId, debug, onFormInitialized } = props;
  const { createMaterial, getCompleteMaterial, updateMaterial } = useProduct();
  const { getCompanies } = useCompany();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('common');

  const { state } = useObservableState();
  const [dataObject, setDataObject] = useState(null);
  const [companies, setCompanies] = useState(null);

  const fetch = (id) => {
    state.setLoading(true);
    state.setError(null);
    setDataObject(null);
    getCompleteMaterial(id)
      .then((res) => {
        setDataObject(res);
      })
      .then(() => getCompanies())
      .then((res) => {
        setCompanies(res);
      })
      .catch((err) => {
        console.error(err);
        state.setError(err);
      })
      .finally(() => {
        state.setLoading(false);
      });
  };

  const initialFetch = () => {
    if (fetchId) {
      fetch(fetchId);
    } else {
      state.setLoading(true);
      state.setError(null);
      getCompanies()
        .then((res) => {
          setCompanies(res);
        })
        .catch((err) => {
          console.error(err);
          state.setError(err);
        })
        .finally(() => {
          setDataObject(constructEmptyMaterial());
          state.setLoading(false);
        });
    }
  };

  useEffect(initialFetch, [fetchId]);

  const onSubmit = async (payload) => {
    console.log('onSubmit', payload);
    state.setLoading(true);
    try {
      let materialId = payload.materialId;
      if (!materialId) {
        const res = await createMaterial(payload);
        materialId = res.id;
      }
      await handleComponentPromises(
        materialId,
        payload.components,
        dataObject.components,
      );
      await updateMaterial(materialId, payload);
      fetch(materialId);
      enqueueSnackbar(t('save_success'), { variant: 'success' });
    } catch (err) {
      state.setError(err);
      enqueueSnackbar(t('save_error'), { variant: 'error' });
    } finally {
      state.setLoading(false);
    }
  };

  return (
    <>
      <Editor
        dataObject={dataObject}
        onSubmit={onSubmit}
        onFormInitialized={onFormInitialized}
        debug={debug}
        state={state}
      >
        <Material
          companies={companies}
          accordionProps={{ defaultExpanded: true }}
          disableAccordion={true}
        />
      </Editor>
    </>
  );
};

export default MaterialEditor;
