/* eslint-disable react/prop-types */
import React from 'react';
import ProductEditor from '../components/ProductEditor';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import MainHeader from '../components/dashboard/MainHeader';
import { useLocalObservable } from 'mobx-react-lite';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    maxWidth: '90%',
  },
  links: {
    // paddingTop: '3em',
  },
  page: {
    width: '100%',
    marginTop: '2.5em',
  },
  cont: {
    paddingLeft: '4em',
    paddingTop: '0.55em',
    paddingBottom: '0.55em',
    marginRight: '4em',
  },
}));

const EditProductPage = (props) => {
  const { t } = useTranslation('product');
  const { item, match } = props;
  const productId = match.params.productId;
  const classes = useStyles();

  const state = useLocalObservable(() => ({
    formId: null,
    setFormId(id) {
      this.formId = id;
    },
  }));

  const onSaveButtonPress = () => {
    document
      .getElementById(state.formId)
      .dispatchEvent(new Event('submit', { cancelable: true }));
  };

  const onFormInitialized = (payload) => {
    state.setFormId(payload.formId);
  };

  // console.log('params', params);

  return (
    <>
      <MainHeader item={item} onSaveButtonPress={onSaveButtonPress} />
      <Box classes={{ root: classes.cont }}>
        <Box classes={{ root: classes.links }}>
          {/* <ArrowBack style={{ marginRight: '0.4em', fontSize: '1.0em' }} />
          <Typography variant="subtitle1">
            {t('products')} / {t('create_product')}
          </Typography> */}
          {/* <Breadcrumbs>
            <Link color="inherit">
              <span>{t('products')}</span>
            </Link>
            <Link color="inherit">
              <span>{t('edit_product')}</span>
            </Link>
          </Breadcrumbs> */}
        </Box>
        <Box classes={{ root: classes.page }}>
          <Typography variant="h2">{t('edit_product')}</Typography>
          <ProductEditor
            productId={productId}
            onFormInitialized={onFormInitialized}
          />
          <Box m={24} />
        </Box>
      </Box>
    </>
  );
};

export default EditProductPage;
